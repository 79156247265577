import React, { useContext, useState } from 'react'
import { ModalContext } from '../Context';
import { editListoflanguage } from '../Utils/services';

const EditLanguage = ({ getLanguagedata, data }) => {
    const [language, setLanguage] = useState(data?.langName)
    const [languageId, setLanguageId] = useState(data?.languageID)
    const [loading, setLoading] = useState(false)

    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;


    const handleEditlanguage = async () => {
        setLoading(true)
        let result = await editListoflanguage({
            "languageID": languageId,
            "langName": language,
        });

        if (result.status) {
            getLanguagedata()
            setLoading(false)
            closeModal()
        }
    }




    return (
        <div className='add-collateral-type'>
            <h3 className='mb-4'>Edit Language</h3>
            <input type="text" placeholder='English' value={language} onChange={(e) => setLanguage(e.target.value)} />
            <div className='mt-3 d-flex justify-content-between'>
                <button className='concel-btn-modal' onClick={closeModal}>Cancel</button>
                <button className='save-btn-modal' onClick={handleEditlanguage} disabled={loading} >
                    {
                        loading ?
                            <>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </>
                            : "Save"
                    }
                </button>
            </div>
        </div>
    )
}

export default EditLanguage