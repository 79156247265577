import React, { useEffect } from 'react'

import CollateralApproved from '../../Pages/Collaterals/Approved'
import CollateralPanding from '../../Pages/Collaterals/Collateralspanding'
import CollateralDeactive from '../../Pages/Collaterals/Deactivated'
import CollateralDecline from '../../Pages/Collaterals/Declined'
import { Route, Routes } from 'react-router-dom'
import Home from '../../Pages/Home'
import Master from '../../Pages/Master/Deactive'
import CollateralType from '../../Pages/Master/CollateralType'
import Tags from '../../Pages/Master/Tags'
import Language from '../../Pages/Master/Languages'
import People from '../../Pages/People'
import Product from '../../Pages/Rbstore/Product/Collateralproduct'
import ProductMerchant from '../../Pages/Rbstore/Product/Collateralproduct'
import Merchant from '../../Pages/Rbstore/Product/Merchantproduct'
import ProductCollateral from '../../Pages/Rbstore/Product/Collateralproduct'
import Profile from '../../Pages/Profile/PersonalInformation'
import UpdateProfile from '../../Pages/Profile/PersonalInformation/UpdateProfile'
import ProfilePassword from '../../Pages/Profile/CurrentPassword'
import Updatepassword from '../../Pages/Profile/CurrentPassword/Updatepassword'
import RbstoreAnalitycs from '../../Pages/Rbstore/Analitycs/AnlatiycMarchent'
import AnalitycsCollateral from '../../Pages/Rbstore/Analitycs/AnlatyicsCollateral'
import ContentHub from '../../Pages/RenewVerse/Content Hub'
import AddCollateralImage from '../../Pages/Master/Add'
import AddArticleForm from '../../Pages/RenewVerse/Content Hub/AddArticleForm'
import Insurer from '../../Pages/RenewVerse/Insurer'
import AddBrochure from '../../Pages/RenewVerse/Insurer/AddBrochure'
import AddVideoFormHub from '../../Pages/RenewVerse/Content Hub/AddVideoFormHub'
import TagEdit from '../../Pages/RenewVerse/Content Hub/TagEdit'
import EditBrochure from '../../Pages/RenewVerse/Insurer/EditBrochure'
import EditContent from '../../Pages/RenewVerse/Content Hub/EditContent'
import EditBrochureCard from '../../Pages/RenewVerse/Insurer/EditBrochureCard'
import EditCollateralImage from '../../Pages/Master/EditCollateral'



const MainWrapper = () => {

    var refresh = window.localStorage.getItem('Windowrefresh');
    if (refresh === null) {
        window.location.reload();
        window.localStorage.setItem('Windowrefresh', "1");
    }

    return (
        <>
            <div className='mainWrapper pt-5'>

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/approval-pending" element={<CollateralPanding />} />
                    <Route path="/approved" element={<CollateralApproved />} />
                    <Route path="/declined" element={<CollateralDecline />} />
                    <Route path="/deactivated" element={<CollateralDeactive />} />
                    <Route path="/master" element={<Master />} />
                    <Route path="/add-new" element={<AddCollateralImage />} />
                    <Route path="/edit-collateral/:id" element={<EditCollateralImage />} />
                    <Route path="/collateral-type" element={<CollateralType />} />
                    <Route path="/tag" element={<Tags />} />
                    <Route path="/languages" element={<Language />} />
                    <Route path="/people" element={<People />} />
                    <Route path="/product" element={<Merchant />} />
                    <Route path="/product/collateral" element={<ProductCollateral />} />
                    <Route path="/rbstore" element={<RbstoreAnalitycs />} />
                    <Route path="/rbstore/collateral" element={<AnalitycsCollateral />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/profile/update" element={<UpdateProfile />} />
                    <Route path="/profile/changepassword" element={<ProfilePassword />} />
                    <Route path="/profile/updatepassword" element={<Updatepassword />} />
                    <Route path="/content-hub" element={<ContentHub />} />
                    <Route path="/content-hub/artical-form" element={<AddArticleForm />} />
                    <Route path="/content-hub/video-form" element={<AddVideoFormHub />} />
                    <Route path="/content-hub/edit" element={<TagEdit />} />
                    <Route path="/content-hub/edit-article/:id" element={<EditContent />} />

                    <Route path="/insurer-brochures" element={<Insurer />} />
                    <Route path="/insurer-add-brochures" element={<AddBrochure />} />
                    <Route path="/insurer-add-brochures/edit" element={<EditBrochure />} />
                    <Route path="/insurer-brochures/edit-brochure/:id" element={<EditBrochureCard />} />

                    <Route path="/*" element="not found" />
                </Routes>


            </div>
        </>
    )
}

export default MainWrapper