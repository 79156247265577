import React, { useContext, useEffect, useState } from 'react'
import { ModalContext } from '../../Context';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { getListOfTags } from '../../Utils/services';

const Button = ({ name, handleAdd, setSearchVal }) => {

  const [listTag, setListOfTags] = useState([])

  const getTagData = async () => {
    try {

      const result = await getListOfTags(0)
      if (result.data) {

        setListOfTags(result.data)

      }

    } catch (error) {

    }
  }

  useEffect(() => {
    getTagData()
  }, [])

  return (
    <>
      <button className='' id="dropdown-basic-button" onClick={handleAdd} >{name}</button>
      {/* <DropdownButton id="dropdown-basic-button" title="Sort">
        <Dropdown.Item href="#" onClick={() => setSearchVal("")}>All</Dropdown.Item>
        {listTag?.map((items) => (
          <Dropdown.Item href="#" onClick={() => setSearchVal(items?.tagsName)}>{items?.tagsName}</Dropdown.Item>
        ))}
      </DropdownButton> */}
    </>

  )
}

export default Button