import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import { useDropzone } from 'react-dropzone';
import dropzone from "../../../Assets/image/icons/dropzone.png"
import { addArticle, getGetArtcleTags } from '../../../Utils/services';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate } from 'react-router-dom';

const AddArticleForm = () => {
    const [title, setTitle] = useState("")
    const [category, setCategory] = useState([])
    const [categaryvalue, setCategoryValue] = useState("");
    const [preview, setPreview] = useState([]);
    const [file, setFiles] = useState([])
    const [article, setArticle] = useState();
    const [loading, setLoading] = useState(false)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    // react-draft-wysiwyg Start
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        let convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setArticle(convertedData)
    }
    const embedVideoCallBack = (link) => {
        link = link.replace("watch?v=", "embed/");
        link = link.replace("/watch/", "/embed/");
        link = link.replace("youtu.be/", "youtube.com/embed/");
        return link
    }


    // react-draft-wysiwyg End

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            setPreview(acceptedFiles[0])
            setFiles(

                <li key={acceptedFiles[0].path}>
                    {acceptedFiles[0].path}
                </li>
            );
        }
    });



    const getArticleApi = async () => {
        const result = await getGetArtcleTags()
        setCategory(result.data)
        console.log(result.data)
    }


    const handleFormSubmit = async () => {

        if (handleValidation()) {
            setLoading(true)
            console.log(title, article, categaryvalue, preview, "Check")
            const formData = new FormData();
            formData.append("articlesTitle", title);
            formData.append("articlesDescription", article);
            formData.append("tagID", categaryvalue);
            formData.append("files", preview);
            const result = await addArticle(formData);
            setLoading(false)
            if (result.status) {
                navigate("/content-hub")
            }
            setLoading(false)

        }

    }


    useEffect(() => {
        getArticleApi()
    }, [])



    const handleValidation = () => {
        let _errors = {};
        let formIsValid = true;
        if (!title) {
            formIsValid = false;
            _errors["titelName"] = "Please enter the title";
        }
        else if (!article) {
            formIsValid = false;
            _errors["description"] = "Please enter the description";
        }
        else if (!categaryvalue) {
            formIsValid = false;
            _errors["categaryvalue"] = "Please enter the categary value";
        }
        else if (preview.length === 0) {
            formIsValid = false;
            _errors["image"] = "Please upload the image";

        }

        setErrors(_errors)
        return formIsValid;

    }




    return (
        <div>
            <h3>Add Article</h3>

            <div className='add-collateral-input'>
                <p className='py-2'>Title</p>
                <TextField id="standard-basic" placeholder='type something...' variant="standard" className='addcollate' onChange={(e) => setTitle(e.target.value)} />
                {
                    errors.titelName && <p className="text-sm text-left text-danger pt-1">{errors.titelName}</p>
                }
            </div>

            <div className='collateral-category mt-4'>
                <p className='py-2'> Select Tags </p>
                <ul class="ux-btn-group" >

                    {
                        category?.map((items) => (
                            <li>
                                <input type="radio" id={`${items?.categoryName}`} className="ux-btn-group-item " value={items.categoryID} name="option1" onClick={(e) => setCategoryValue(e.target.value)} />
                                <label for={`${items?.categoryName}`} className='me-2 mt-0 ms-0 mb-0' >{items.categoryName}</label>
                            </li>

                        ))
                    }

                </ul>

                {
                    errors.categaryvalue && <p className="text-sm text-left text-danger pt-1">{errors.categaryvalue}</p>
                }

            </div>


            <div className="row mt-4">
                <div className="col-lg-4 col-md-6 col-sm-8 col-8 ">
                    <div className='collateral-description mt-4'>
                        <p className='py-2'>Upload Collateral </p>

                        <div>
                            <div {...getRootProps()} className="inputDrops mt-2">
                                <input {...getInputProps()} />
                                {isDragActive ? <p>Drop Image Here</p> : <><img src={dropzone} alt="" /></>}
                            </div>

                            <ul className='text-center p-1'>{file ? file : "PNG,JPEG,etc"}</ul>
                        </div>

                        {
                            errors.image && <p className="text-sm text-left text-danger pt-1">{errors.image}</p>
                        }

                    </div>

                </div>



            </div>
            <div className='collateral-description mt-4'>
                <p className='py-2'>Description </p>
                <div className='editorSectionStart'>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                        toolbar={{
                            embedded: {
                                embedCallback: embedVideoCallBack
                            }
                        }}
                        value={article}
                    />
                </div>



                {
                    errors.description && <p className="text-sm text-left text-danger pt-1">{errors.description}</p>
                }

            </div>




            <div className='mt-3 d-flex mb-3'>
                <button className='save-btn-modal me-4' onClick={handleFormSubmit}>
                    {loading ?
                        <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </>
                        : "Add"}
                </button>
                <button className='concel-btn-modal'>Cancel</button>

            </div>

        </div>
    )
}

export default AddArticleForm