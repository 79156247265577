import axios from 'axios'
import { PROFILE, UPDATEPROFILE } from '../../Utils/routes';

// export const BASE_URL = "https://renewbuy.techtonic.asia/api"; //devlopment///
export const BASE_URL = "https://collaterals.api.renewbuy.com/api"; //production//


axios.defaults.baseURL = BASE_URL;
// get people //

const getProfile = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.get(PROFILE, config)

    return response.data;
}

const editProfile = async (userData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.post(UPDATEPROFILE, userData, config)

    return response.data;
}


const profileService = {
    getProfile,
    editProfile

}

export default profileService