import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import dropzone from "../Assets/image/icons/dropzone.png"

const AddProduct = () => {
    const [preview, setPreview] = useState([]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            setPreview(
                acceptedFiles.map((upFile) =>
                    Object.assign(upFile, {
                        preview: URL.createObjectURL(upFile)
                    })
                )
            );
        }
    });


    console.log(preview)

    return (
        <div className="container">
            <div className="row">
                <div className="col-5">
                    <div {...getRootProps()} className="inputDrop">
                        <input {...getInputProps()} />
                        {isDragActive ? <p>Drop Image Here</p> : <><img src={dropzone} alt="" /></>}
                    </div>
                    <p className='text-center p-1'>PNG,JPEG,etc</p>

                    <div className="imagePreview">
                        {preview.map((upFile, index) => {
                            return (
                                <div className="previewImage" key={index}>
                                    <img src={upFile.preview} alt="preview" />
                                    <span className="removeImg">X</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="col-7">
                    <div className='Product-details'>
                        <div>

                            <p className='ps-2 pb-2'>Product Name</p>
                            <input type="text" placeholder='RenewBuy Diary' />

                        </div>
                        <div>
                            <p className='ps-2 pb-2 mt-2'>Price</p>
                            <input type="text" placeholder='999' />

                        </div>
                        <div>
                            <p className='ps-2 pb-2 mt-2'>Color</p>
                            <input type="text" placeholder='Brown' />

                        </div>
                        <div>
                            <p className='ps-2 pb-2 mt-2'>Size</p>
                            <input type="text" placeholder='One Size ' />

                        </div>
                        <div>
                            <p className='ps-2 pb-2 mt-2'>Description</p>
                            <textarea rows={8} cols={32} placeholder='Type something' />

                        </div>
                        <div className='mt-3 d-flex justify-content-between'>
                            <button className='concel-btn-modal-profile'>Cancel</button>
                            <button className='save-btn-modal-profile'>Add</button>
                        </div>


                    </div>



                </div>
            </div>
        </div>

    )
}

export default AddProduct