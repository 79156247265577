import React, { useState } from 'react';
import crossicon from "../../Assets/image/Collateral/cross.png"
import yesicon from "../../Assets/image/Collateral/yes.png"
import { deActiveColetralModal } from '../../Utils/services';

const ApprovedButton = ({ item, handleAprovedcolletarl }) => {
    const [show, setShow] = useState(true);
    const [showDeactiveDetails, setShowDeactiveDetails] = useState(false)

    const handleShow = () => {
        setShow(false)
        setShowDeactiveDetails(true)

    }

    const handleCross = () => {
        setShow(true)
        setShowDeactiveDetails(false)
    }

    const handleDeactiveCollateral = async () => {
        const result = await deActiveColetralModal({
            "collateralID": item.collatID,
        })
        handleAprovedcolletarl()
        setShow(true)
        setShowDeactiveDetails(false)
    }

    return (
        <React.Fragment>
            {
                show && <div className='d-flex'>

                    <button className='approved me-3 ' disabled={true} >Approved</button>
                    <button className='declinebtn ' onClick={handleShow} >Deactive</button>
                </div>
            }

            {showDeactiveDetails &&
                <div className='d-flex align-items-center decline-details'>
                    <span className='me-3'>Deactivate Collateral?</span>
                    <img src={crossicon} alt="" className='cursor-pointer me-3' onClick={handleCross} />
                    <img src={yesicon} alt="" className='cursor-pointer' onClick={handleDeactiveCollateral} />

                </div>
            }


        </React.Fragment>
    )
}

export default ApprovedButton