import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../Redux/auth/authSlice'
import masterReducer from "../Redux/master/masterSlice"
import profileSlice from '../Redux/Profile/profileSlice'
import homeSlice from '../Redux/home/homeSlice'





export const store = configureStore({
    reducer: {
        auth: authReducer,
        master: masterReducer,
        profile: profileSlice,
        home: homeSlice,

    },
})
