
import React, { useContext, useEffect, useMemo, useState } from 'react'
import Button from '../../../Components/commoncomponent/Button'
import ColletralTable from '../../../Components/commoncomponent/ColletralTable'
import InputSearch from '../../../Components/commoncomponent/InputSearch'
import SwitchButtonTwo from '../../../Components/commoncomponent/SwitchButtonTwo'
import data from '../../../Components/commoncomponent/data.json'
import delIcon from "../../../Assets/image/icons/delete.png"
import { ModalContext } from '../../../Context'
import EditTag from '../../../ModalTemplate/EditTag'
import Delete from '../../../ModalTemplate/Delete'
import AddTag from '../../../ModalTemplate/AddTag'
import { editTag, getTag, reset } from '../../../Redux/master/masterSlice'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../Components/commoncomponent/Spinner'
import { getListOfTags, setPriority } from '../../../Utils/services'
import DeleteTag from '../../../ModalTemplate/DeleteTag'
import Pagination from '../../../Components/commoncomponent/Pagination'
import Draganeble from '../../../Components/commoncomponent/Draganeble'



const Tags = () => {
    const [listTag, setListTag] = useState([])
    const [loading, setLoading] = useState(true)
    const { user } = useSelector((state) => state.auth)
    const [search, setSearchVal] = useState("")
    const [count, setCount] = useState(0)
    const [tagsList, setTagList] = useState([])


    const modalContext = useContext(ModalContext);
    const { handleModalData } = modalContext;

    const navigate = useNavigate()


    const getTagData = async () => {
        try {
            setLoading(true)
            const result = await getListOfTags(count)
            if (result.data) {
                setLoading(false)
                setListTag(result.data)

            }
            setLoading(false)
            console.log(result)

        } catch (error) {

        }
    }


    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Tags",
                accessor: data => {
                    return (
                        <>
                            <span>{data.tagsName}</span>
                        </>
                    )
                }
            },

            {
                Header: "Usage",
                accessor: data => {
                    return (
                        <>
                            <span>{data.usage}</span>
                        </>
                    )
                }
            },

            {
                Header: "Add on",
                accessor: data => {
                    return (
                        <>
                            <span>{data.addedOn}</span>
                        </>
                    )
                }
            },
            {
                Header: "Add by",
                accessor: data => {
                    return (
                        <>
                            <span>{data.addedBy}</span>
                        </>
                    )
                }
            },


            {
                Header: 'Action',
                accessor: (data) => {
                    return (
                        <>
                                   <div className='d-flex align-items-center justify-content-center'>
                            <span className="MuiButton-label editbtn" onClick={() => handleEdit(data)}>Edit</span>
                            <span className="MuiButton-label underline ms-3" onClick={() => handleDelete(data)}><img src={delIcon} alt="delete" className='cursor-pointer' /></span>
                            </div>
                        </>
                    )
                },
            }

        ], [])


    const handleEdit = (data) => {
        const addCollateral = <EditTag data={data} getTagData={getTagData} />
        handleModalData(addCollateral, "md")

    }
    const handleDelete = (data) => {
        const addCollateral = <DeleteTag data={data} getTagData={getTagData} />
        handleModalData(addCollateral, "md")
    }
    const handleAdd = () => {
        const addCollateral = <AddTag getTagData={getTagData} data={data} />
        handleModalData(addCollateral, "md")
    }



    useEffect(() => {
        getTagData()

    }, [])




    useEffect(() => {
        getTagData()

    }, [count])


    const setTableFilter = async () => {

        const result = await setPriority(
            {
                "tagsID": tagsList
            }
        )
        getTagData()
    }

    useEffect(() => {
        setTableFilter()

    }, [tagsList])





    return (
        <div className='container-fuild'>
            <div className="row">
                <div className='d-flex justify-content-between align-items-center'>
                    <InputSearch setSearchVal={setSearchVal} />
                    <div className='d-flex align-items-center '>

                        {/* <Pagination setCount={setCount} count={count} collateralList={listTag} /> */}
                        <Button name="Add +" handleAdd={handleAdd} />

                    </div>

                </div>

            </div>


            {loading ? <Spinner /> :


                <>
                    < div className="row mt-2" style={{overflowY:'scroll'}}>
                        <div className="col-12 mb-4">
                            <Draganeble data={listTag?.filter((val) => {
                                if (search == "") {
                                    return val;
                                }
                                else if (val && val.tagsName && val.tagsName.toLowerCase().includes(search.toLowerCase())) {
                                    return val
                                }

                            })} columns={FeedsColumns}
                                setListTag={setTagList}
                            />
                        </div>
                    </div>
                </>

            }


        </div >
    )
}

export default Tags;