import React, { useState } from 'react'
import crossicon from "../../Assets/image/Collateral/cross.png"
import yesicon from "../../Assets/image/Collateral/yes.png"
import { Reactived } from '../../Utils/services';

const ReactiveButton = ({ item, handleDeactiveList }) => {
    const [show, setShow] = useState(true);
    const [showReactiveDetails, setShowReactiveDetails] = useState(false)

    const handleShow = () => {
        setShow(false)
        setShowReactiveDetails(true)

    }

    const handleCross = () => {
        setShow(true)
        setShowReactiveDetails(false)
    }

    const handleReactiveCollateral = async () => {
        const result = await Reactived({
            "collateralID": item.collatID,
        })
        handleDeactiveList()
        setShow(true)
        setShowReactiveDetails(false)
    }
    return (
        <React.Fragment>
            {
                show &&
                <div className='d-flex'>
                    <button className='deactive me-3' disabled={true} >Deactivated</button>
                    <button className='reactivebtn' onClick={handleShow} >Reactivate</button>
                </div>
            }
            {showReactiveDetails &&
                <div className='d-flex align-items-center Reactive-details'>
                    <span className='me-3'>Reactivate Collateral?</span>
                    <img src={crossicon} alt="" className='cursor-pointer me-3' onClick={handleCross} />
                    <img src={yesicon} alt="" className='cursor-pointer' onClick={handleReactiveCollateral} />

                </div>
            }

        </React.Fragment>
    )
}

export default ReactiveButton