import React, { useContext } from 'react'
import doc from "../../Assets/image/Collateral/doc.svg"
import view from "../../Assets/image/Collateral/bi_view-list.svg"
import { ModalContext } from '../../Context';
import moment from 'moment';
import PendingButton from './PendingButton';
import playIcon from "../../Assets/image/icons/playicon.png"
import ViewVideoModal from '../../ModalTemplate/ViewVideoModal';
import TestVideoModal from '../../ModalTemplate/TestVideoModal';




const VideoPendingCard = ({ collertalimg, item, getPendingCollateralist }) => {

    const modalContext = useContext(ModalContext);
    const { handleModalData } = modalContext;

    const handleModal = (type) => {
        const tabsValue = type;
        const Test = <TestVideoModal item={item} getPendingCollateralist={getPendingCollateralist} />
        const View = <ViewVideoModal item={item} />

        switch (tabsValue) {
            case "TEST":
                handleModalData(Test, "cs");
                break;
            case "VIEW":
                handleModalData(View, "lg");
                break;

            default:
                handleModalData(Test, "lg");
                break;
        }
    }
    console.log(item?.collatData, "check")


    return (
        <>
            <div className="card collateral">
                <div className="card-body p-5 collateralbody">
                    <div className='d-flex collateraldata '>
                        <div className='max-hight overlayer'>
                            <img src={item?.collatData[0]?.thumbImage || collertalimg} alt="" />
                            <div className='playicon'>
                                <img src={playIcon} alt="" />
                            </div>

                        </div>

                        <div className='ps-5 pe-5'>
                            <p>{item.titleName}</p>
                            <p className='collateral-details mt-2'>Type : <span>{item.collateralType}</span></p>
                            <p className='collateral-details mt-2'>Category : <span>{item.categoryName}</span></p>
                            <p className='collateral-details mt-2'>Created By : <span>{item.addedBy}</span></p>
                            <p className='collateral-details mt-2'>Scheduled For : <span>{moment(item.scheduledTime).format('L')} , {moment(item.scheduledTime).format('LT')}</span></p>
                        </div>

                    </div>
                    <div className='mt-4 d-flex justify-content-between align-items-center'>
                        <div className='d-flex   '>
                            {
                                item?.collatData[0] !== null &&
                                <span className='testbtn me-4 cursor-pointer' onClick={() => handleModal("TEST")}> <img src={doc} alt="" /> test</span>
                            }
                            <span className='viewbtn cursor-pointer' onClick={() => handleModal("VIEW")}> <img src={view} alt="" />  view</span>
                        </div>
                        <PendingButton item={item} getPendingCollateralist={getPendingCollateralist} />
                    </div>


                </div>
            </div>
        </>
    )
}

export default VideoPendingCard;