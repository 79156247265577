import React from 'react'
import { useState } from 'react'

const SwitchCharts = ({ activeSwitchImage, SetActiveSwitchImage, activeSwitchVideo, SetActiveSwitchVideo, setCategory }) => {
    // const [activeSwitchImage, SetActiveSwitchImage] = useState(true)
    // const [activeSwitchVideo, SetActiveSwitchVideo] = useState(false)

    const [activAll, setActiveAll] = useState(true)
    const [activRenewbuy, setAcitveRenewBuy] = useState(false)
    const [activInsurur, setActiveInsurer] = useState(false)

    const handleSwitchImage = () => {
        SetActiveSwitchImage(true)
        SetActiveSwitchVideo(false)

    }

    const handleSwitchVideo = () => {
        SetActiveSwitchImage(false)
        SetActiveSwitchVideo(true)
    }

    const handleAcitveAll = () => {
        setActiveAll(true)
        setAcitveRenewBuy(false)
        setActiveInsurer(false)
        setCategory(null)

    }

    const handlerenewBuy = () => {
        setActiveAll(false)
        setAcitveRenewBuy(true)
        setActiveInsurer(false)
        setCategory("30")
    }

    const handleInsurer = () => {
        setActiveAll(false)
        setAcitveRenewBuy(false)
        setActiveInsurer(true)
        setCategory("31")
    }



    return (
        <div className='switch-chart d-flex justify-content-between align-items-center'>
            <div className='d-flex'>
                <div >
                    <p className={`px-4 mx-1 pt-3 pb-3 ${activeSwitchImage && "switch-active"} switch-image-btn`} onClick={() => handleSwitchImage()}> Image</p>
                </div>

                <div>
                    <p className={`px-4 mx-1 pt-3 pb-3 ${activeSwitchVideo && "switch-active"} switch-video-btn`} onClick={handleSwitchVideo}>  Video</p>
                </div>
            </div>

            <div className='switch-chart-btn d-flex mb-1'>
                <div >
                    <p className={`px-4 me-1 pt-3 pb-3 ${activAll && "switch-active-btn"} switch-image-btn`} onClick={() => handleAcitveAll()}> All</p>
                </div>

                <div>
                    <p className={`px-4 mx-1 pt-3 pb-3 ${activRenewbuy && "switch-active-btn"} switch-video-btn`} onClick={handlerenewBuy}>  RenewBuy</p>

                </div>
                <div>
                    <p className={`px-4 ms-1 pt-3 pb-3 ${activInsurur && "switch-active-btn"} switch-video-btn`} onClick={handleInsurer}>  Insurer</p>
                </div>
            </div>






        </div >
    )
}

export default SwitchCharts