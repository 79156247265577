import React, { useContext, useState } from 'react'
import { ModalContext } from '../Context';
import { addCompany } from '../Utils/services';

const AddCompany = () => {
    const [addCompanDetails, setAddCompanyDetails] = useState("");
    const [loading, setLoading] = useState(false)
    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;


    const addComapanyApi = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const result = await addCompany(
                {
                    "name": addCompanDetails
                }
            )
            if (result.status) {
                setLoading(false)
                closeModal()

            }

        } catch (error) {
            setLoading(false)
        }

    }



    return (
        <div className='add-collateral-type'>
            <h3 className='mb-4'>Add Company</h3>
            <input type="text" placeholder='Search...' onChange={(e) => setAddCompanyDetails(e.target.value)} />
            <div className='mt-3 d-flex justify-content-between'>
                <button className='concel-btn-modal' onClick={closeModal}>Cancel</button>
                <button className='save-btn-modal' onClick={addComapanyApi} >
                    {
                        loading ?
                            <>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </>
                            : "Add"
                    }</button>
            </div>
        </div>
    )
}

export default AddCompany