import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux';
import { ModalContext } from '../Context';
import { editTag } from '../Redux/master/masterSlice';
import { editListOfTags } from '../Utils/services';

const EditTag = ({ data, getTagData }) => {
    const [tagName, setEditVal] = useState(data?.tagsName)
    const [tagID, settagNameVal] = useState(data?.tagsID)

    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;

    const dispatch = useDispatch();

    console.log(data)


    const handleEdit = async () => {
        const result = await editListOfTags(
            {
                "tagID": tagID,
                "tagName": tagName
            }

        )

        getTagData()
        closeModal()

    }

    // console.log(data, "data of value edit")


    return (
        <div className='add-collateral-type'>
            <h3 className='mb-4'>Edit Tag</h3>
            <input type="text" placeholder='Tags' value={tagName} onChange={(e) => setEditVal(e.target.value)} />
            <div className='mt-3 d-flex justify-content-between'>
                <button className='concel-btn-modal'>Cancel</button>
                <button className='save-btn-modal' onClick={() => handleEdit()}>Save</button>
            </div>
        </div>
    )
}

export default EditTag