import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react'
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import avtar from "../../../Assets/image/Profile/avatar.png";
import view from "../../../Assets/image/Profile/view.png";
import Avatar from '../../../Components/commoncomponent/Avatar';
import SwitchProfiledetails from '../../../Components/commoncomponent/SwitchProfiledetails';
import { validationPasswordSchema } from '../../../Components/commoncomponent/validation';
import { passwordUpdate } from '../../../Utils/services';

const Updatepassword = ({ merchant, handleMarchant, collaterals, handleCollateral }) => {
    const [viewpassword, setPassword] = useState(true)
    const navigate = useNavigate()

    const formOptions = { resolver: yupResolver(validationPasswordSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;



    const handleChangePasword = () => {
        navigate('/profile')
    }


    const handlePasword = async (data) => {

        try {
            const result = await passwordUpdate({
                "oldPassword": data.oldpassword,
                "newPassword": data.password,
            });
            toast.success(result.res.data)
        } catch (error) {
            toast.error(error.data)
        }


    }

    // console.log(, "check error")


    return (
        <div className='profile'>

            {/* -----------------tab button -------------------------------- */}


            <SwitchProfiledetails Password="productactive" handleChangePassword={handleChangePasword} />
          

            {/* --------------- profile page ---------------------------------- */}


            <Avatar />


            {/* ---------------- avatar details---------------------------------- */}
            <form action="">


                <div className='profile_form_details mt-4 '>
                    <div className=''>
                        <div className="row ">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                                <div className=''  >
                                    <p>Current Password</p>
                                    <div className='passwordview'>
                                        <input type="password" placeholder='Old Password'  {...register('oldpassword')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                                    </div>
                                    {
                                        errors.oldpassword && <p className='ps-2 text-danger'>{errors.oldpassword.message}</p>
                                    }

                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                                <div className=''  >
                                    <p>New Password</p>
                                    <div className='passwordview'>
                                        <input type="password" placeholder="New Password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />

                                    </div>
                                    {
                                        errors.password && <p className='ps-2 text-danger'>{errors.password.message}</p>
                                    }



                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                                <div className=''  >
                                    <p>Re-Type New Password</p>
                                    <div className='passwordview'>
                                        <input type="password" placeholder="Re-Type New Password" {...register('confirmPassword')} className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`} />

                                    </div>
                                    {
                                        errors.confirmPassword && <p className='ps-2 text-danger'>{errors.confirmPassword.message}</p>
                                    }

                                </div>
                            </div>

                        </div>

                    </div>

                    <div className='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 profile_editbtn mt-4' onClick={handleSubmit(handlePasword)} >
                        <button className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4"  >Update Password</button>
                    </div>


                </div>
            </form>










        </div >
    )
}

export default Updatepassword;