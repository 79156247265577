import axios from 'axios'
import { TAGOVERVIEW } from '../../Utils/routes';
// export const BASE_URL = "https://renewbuy.techtonic.asia/api"; //devlopment//
export const BASE_URL = "https://collaterals.api.renewbuy.com/api"; //production//



export const getTagOverView = async (data, token) => {
    try {
        const response = await axios.post(TAGOVERVIEW, data, {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: "Bearer " + token,
            },
        });
        if (response.status === 200) {
            console.log(response.data);
            return { res: response.data };
        } else return response.data;
    } catch (err) {
        if (err.response) throw err.response.data;
        else throw err.message;
    }
};


const homeService = {
    getTagOverView,
}

export default homeService