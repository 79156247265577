import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { ModalContext } from '../Context';
import { toast } from 'react-toastify';
import { deletePeople } from '../Redux/master/masterSlice'
import { delBrochure, deleteCollateraltype } from '../Utils/services';

const BrochureDelete = ({ title, data, getBrochureApi, item, category }) => {
    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;
    console.log(item, "brochure")
    console.log(category, "categary")
    const handleDeleteApiData = async () => {
        const result = await delBrochure(
            {
                "brouchersID": item?.brouchersID,
            }
        )
        toast.success(result.apiMessage);
        getBrochureApi(category)
        closeModal()

    }
    console.log(data, "data")

    return (
        <div className='add-collateral-type'>
            <h3 className='mb-4 delete-heading'>Are you sure you want to delete
                this Brochure?</h3>
            <div className='mt-3 d-flex justify-content-between'>
                <button className='concel-btn-modal' onClick={closeModal}>Cancel</button>
                <button className='save-btn-modal' onClick={() => handleDeleteApiData()} >Confirm</button>
            </div>
        </div>
    )
}

export default BrochureDelete