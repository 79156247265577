import React, { useState } from 'react'
import { useEffect } from 'react'
import { getListCategoryType, getListOfCollateraltype, getListOflanguage, getListOFTagsApi, getVideoSize } from '../../../Utils/services'
import AddCollateralForm from '../Add/AddCollateralForm'
import AddCollateralVidoForm from '../Add/AddCollateralVidoForm'
import { useLocation } from 'react-router-dom';
import EditCollateralForm from './EditCollateralForm'


const EditCollateralImage = () => {
    const [activeImage, setActiveImage] = useState(true)
    const [activeVideo, setActiveVideo] = useState(false)
    const [tags, setTags] = useState([])
    const [collateraltype, setCollateraltype] = useState([])
    const [language, setLanguage] = useState([]);
    const [category, setCategory] = useState([]);
    const [videoSize, setVideoSize] = useState([])
    const location = useLocation();

    const handleActiveImage = () => {
        setActiveImage(true)
        setActiveVideo(false)
    }

    const handleActiveVideo = () => {
        setActiveImage(false)
        setActiveVideo(true)
    }

    const getListOfCollats = async () => {
        try {
            const result = await getListOfCollateraltype();
            setCollateraltype(result.data);
            console.log(result.data)
        } catch (error) {
            console.log(error);
        }
    };

    const getListOfLang = async () => {
        try {
            const result = await getListOflanguage(0);

            setLanguage(result.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getCategory = async () => {
        const results = await getListCategoryType();
        setCategory(results.data)
        console.log(results, "check data")

    }

    const getVideoSizeApi = async () => {
        const result = await getVideoSize()
        console.log(result.data, "Cehck")
        setVideoSize(result.data)
    }

    useEffect(() => {
        const handleGetApi = async () => {
            const result = await getListOFTagsApi()
            setTags(result.data)
        }
        handleGetApi()
        getListOfCollats()
        getListOfLang()
        getCategory()
        getVideoSizeApi()
    }, [])

    return (
        <div className='container'>
            <div className="row">
                <div className='d-flex justify-content-between align-items-center sizeaddcoletral'>
                    <h3>Edit Collateral</h3>
                    {/* <div className='addCollateralSwitch d-flex '>
                        <div className={`${activeImage && "activeCollateral"}`} onClick={handleActiveImage}>
                            <p className='py-3 px-4 paracollteral'>Images</p>
                        </div>
                        <div className={`${activeVideo && "activeCollateral"}`} onClick={handleActiveVideo}>
                            <p className='py-3 px-4 paracollteral'>Videos</p>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="row">

                <div className='mt-3'>
                    <EditCollateralForm data={location && location?.state} tags={tags} collateraltype={collateraltype} language={language} category={category} />
                </div>

                {/* {
                    activeVideo &&
                    <div className='mt-3'>
                        <AddCollateralVidoForm videoSize={videoSize} tags={tags} collateraltype={collateraltype} language={language} category={category} />
                    </div>
                } */}
            </div>
        </div>
    )
}
export default EditCollateralImage