import React,{useState}from 'react';


const AuthContext=React.createContext(
    {
        isSuperAdmin: false,
        token: '',
        refreshToken: '',
        isLoggedIn: false,
        login: (token, refreshToken) => { },
        logout: () => { }
    }
)


export const AuthContextProvider = (props) => {

    const initialToken = localStorage.getItem("token");
    const initialRefreshToken = localStorage.getItem("refreshToken");
    const [token, setToken] = useState(initialToken);
    const [refreshToken, setRefreshToken] = useState(initialRefreshToken);
    const userIsLoggedIn = !!token;
    

    const loginHandler = (token, refreshToken, isSuperAdmin) => {
        setToken(token);
        setRefreshToken(refreshToken);
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
       
    }
    const logoutHandler = () => {
        setToken(null);
        setRefreshToken(null);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshtoken');
        localStorage.removeItem("isSuperAdmin");
        
    }

    const contextValue = {
        token: token,
        refreshToken: refreshToken,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler
    }
    return (
    <AuthContext.Provider value={contextValue}>
        {props.children}
    </AuthContext.Provider>
    )
}


export default AuthContext;
