import React from 'react'
import { useTable } from 'react-table'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const Draganeble = ({ columns, data, setListTag }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const items = Array.from(rows)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    const filterArrayData = items?.map((item) => item?.original?.tagsID)
    setListTag(filterArrayData)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <table
        className="w-100 mt-5 table-theme-1"
        {...getTableProps()}
        cellSpacing={5}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <Droppable droppableId="table">
          {(provided) => (
            <tbody {...getTableBodyProps()} ref={provided.innerRef}>
              {rows.map((row, index) => {
                prepareRow(row)
                return (
                  <Draggable key={row.id} draggableId={row.id} index={index}>
                    {(provided) => (
                      <tr
                        {...row.getRowProps()}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className="hover-row draggable-row"
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className="drag-width">
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </table>
    </DragDropContext>
  )
}

export default Draganeble
