import React from 'react'
import SwitchButton from './SwitchButton'

const OverView = (setInitialDate) => {

  return (
    <>
      <div className='overview pt-4'>
        <h3>Overview</h3>
        <SwitchButton setInitialDate={setInitialDate} />
      </div>
    </>

  )
}

export default OverView