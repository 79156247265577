import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import avtar from "../../Assets/image/Profile/avatar.png";
import { getProfile, reset } from '../../Redux/Profile/profileSlice';
import jwt_decode from "jwt-decode";


const Avatar = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)
    const { profile, isLoading, isError, message } = useSelector(
        (state) => state.profile
    )






    useEffect(() => {
        if (isError) {
            console.log(message)
        }

        if (!user) {
            navigate('/login')
        }

        dispatch(getProfile())

        // return () => {
        //     dispatch(reset())
        // }
    }, [user, message, dispatch])



    useEffect(() => {
        dispatch(getProfile())
    }, [])


    // console.log(profile, "check profile")
    // console.log(decoded, "decode check")

    return (

        <div className='profilepage mt-4 d-flex align-items-center'>
            <div className='profile_Avatar me-4'>
                <img src={avtar} alt="" />

            </div>
            <div className='profile_avtar_details'>
                <h3>{profile?.firstName} {profile?.lastName} </h3>
                <p>{profile?.userDesignation}</p>

            </div>

        </div>

    )
}

export default Avatar