import React from 'react'
import filterIcon from "../../Assets/image/Analitycs/filter.png"

const AnalitycFilter = () => {
    return (
        <div className='row mt-4'>
            <div className='d-flex justify-content-between'>
                <div className='searchInput'>
                    <input type="text" placeholder='Search Collaterals' />
                </div>
                <button className='excle-export px-4 d-flex align-items-center '><span className='me-1'>Filter </span><img src={filterIcon} alt="" /> </button>
            </div>

        </div>
    )
}

export default AnalitycFilter