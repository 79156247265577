import React from 'react'
import Button from '../../../Components/commoncomponent/Button'
import InputSearch from '../../../Components/commoncomponent/InputSearch'
import SwitchButtonTwo from '../../../Components/commoncomponent/SwitchButtonTwo'
import logo from "../../../Assets/image/Collateral/collateral.png"
import CollateralDecativeCard from '../../../Components/commoncomponent/CollateralDecativeCard'
import { getDeactivatedCollateral, getDeactivatedVideoCollateral } from '../../../Utils/services'
import Spinner from "../../../Components/commoncomponent/Spinner"
import { useEffect } from 'react'
import { useState } from 'react'
import VideoDecative from '../../../Components/commoncomponent/VideoDecative'
import Sort from '../../../Components/commoncomponent/Sort'



const CollateralDeactive = () => {
    const [deactiveList, setDeactiveList] = useState([]);
    const [deactiveVideoList, setDeactiveVideoList] = useState([])
    const [loading, setLoading] = useState(true)
    const [allImage, setAllImage] = useState(true);
    const [allVideo, setAllVideo] = useState(false);
    const [search, setSearchVal] = useState("")

    const handleDeactiveList = async () => {
        const result = await getDeactivatedCollateral()

        setLoading(false)
        const _newData = result.data.filter((item) => item.collateralType !== "video")
        setDeactiveList(_newData)


    }

    const handleDeactiveVideoList = async () => {
        const resultData = await getDeactivatedVideoCollateral()
        setDeactiveVideoList(resultData.data)
        console.log(resultData, "videoData")

    }

    useEffect(() => {

        handleDeactiveList();
        handleDeactiveVideoList()

    }, [])

    return (
        <>
            <div className='container-fuild'>
                <div className="row">
                    <div className='d-flex justify-content-between align-items-center declineflex'>
                        <InputSearch setSearchVal={setSearchVal} />
                        <div className='d-flex align-items-center declinedpad' style={{justifyContent:'center'}}>
                            <div className='me-4'>
                                <SwitchButtonTwo custom={allVideo} setCustom={setAllVideo} all={allImage} setAll={setAllImage} />
                            </div>


                            <Sort setSearchVal={setSearchVal} />

                        </div>



                    </div>

                </div>

                {
                    allImage &&

                    <div className="row mt-5">

                        {loading ? < Spinner /> : <React.Fragment >

                            {
                                deactiveList?.filter((val) => {
                                    if (search == "") {
                                        return val;
                                    }
                                    else if (val && val.titleName && val.titleName.toLowerCase().includes(search.toLowerCase())) {
                                        return val
                                    }
                                    else if (val && val.collateralType && val.collateralType.toLowerCase().includes(search.toLowerCase())) {
                                        return val
                                    }
                                    else if (val && val?.tagName[0] && val.tagName[0].toLowerCase().includes(search.toLowerCase())) {
                                        return val
                                    }

                                }).map((item, index) => (
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4" key={index}>
                                        <CollateralDecativeCard collertalimg={logo} item={item} handleDeactiveList={handleDeactiveList} />

                                    </div>


                                ))
                            }

                        </React.Fragment>}

                    </div>

                }

                {
                    allVideo &&

                    <div className="row mt-5">

                        {loading ? < Spinner /> : <React.Fragment >

                            {
                                deactiveVideoList?.filter((val) => {
                                    if (search == "") {
                                        return val;
                                    }
                                    else if (val && val.titleName && val.titleName.toLowerCase().includes(search.toLowerCase())) {
                                        return val
                                    }
                                    else if (val && val.collateralType && val.collateralType.toLowerCase().includes(search.toLowerCase())) {
                                        return val
                                    }
                                    else if (val && val?.tagName[0] && val.tagName[0].toLowerCase().includes(search.toLowerCase())) {
                                        return val
                                    }

                                }).map((item, index) => (
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4" key={index}>
                                        <VideoDecative collertalimg={logo} item={item} handleDeactiveList={handleDeactiveVideoList} />
                                    </div>


                                ))
                            }

                        </React.Fragment>}

                    </div>

                }


            </div>
        </>
    )
}

export default CollateralDeactive;