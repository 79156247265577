import React, { useContext, useState } from 'react'
import { ModalContext } from '../Context'
import { CreateTagsType } from '../Utils/services'

const AddTag = ({ getTagData }) => {
    const [addTag, setAddTag] = useState("")
    const [loading, setLoading] = useState(false);
    const [iconFiles, setIconFile] = useState();
    const [bannerFiles, setBannerFile] = useState();
    const [errors, setErrors] = useState({});
    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;


    const handleAddTag = async (e) => {
        e.preventDefault()
        try {
            if (handleValidation()) {
                const formData = new FormData();
                formData.append("name", addTag);
                formData.append("iconFile", iconFiles);
                formData.append("bannerFile", bannerFiles);
                setLoading(true)
                const result = await CreateTagsType(formData);
                if (result.status) {
                    setLoading(false)
                    getTagData()
                    closeModal()
                }
            }

        } catch (error) {
            setLoading(false)
        }
    }


    const handleValidation = () => {
        let _errors = {};
        let formIsValid = true;
        if (!addTag) {
            formIsValid = false;
            _errors["titelName"] = "Please enter the tag name";
        }
        else if (!iconFiles) {
            formIsValid = false;
            _errors["fileIcon"] = "Please insert icon";
        }
        else if (!bannerFiles) {
            formIsValid = false;
            _errors["filebanner"] = "Please insert banner";
        }


        setErrors(_errors)
        return formIsValid;

    }
    // console.log(iconFiles, "check")
    return (
        <div className='add-collateral-type'>
            <h3 className='mb-2'>Add Tags</h3>
            <form action="" onSubmit={handleAddTag}>
                <input type="text" placeholder='Tags' onChange={(e) => setAddTag(e.target.value)} />
                {
                    errors.titelName && <p className="text-sm text-left text-danger pt-1">{errors.titelName}</p>
                }
                <h3 className='mb-2 mt-3'>Add Icon</h3>
                <input type="file" onChange={(e) => setIconFile(e.target.files[0])} />
                {
                    errors.fileIcon && <p className="text-sm text-left text-danger pt-1">{errors.fileIcon}</p>
                }

                <h3 className='mb-2 mt-3'>Add Background Theme</h3>
                <input type="file" onChange={(e) => setBannerFile(e.target.files[0])} />
                {
                    errors.filebanner && <p className="text-sm text-left text-danger pt-1">{errors.filebanner}</p>
                }
                <div className='mt-4 d-flex justify-content-between'>
                    <button className='concel-btn-modal' onClick={closeModal}>Cancel</button>
                    <button type='submit' className='save-btn-modal' >
                        {
                            loading ?
                                <>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </>
                                : "Save"
                        }
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AddTag