import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { ModalContext } from '../../Context';
import CustomHomeModal from '../../ModalTemplate/CustomHomeModal';
import { getOverViewdata } from '../../Redux/home/homeSlice';



const SwitchButton = ({ setInitialDate }) => {

  const [all, setAll] = useState(true);
  const [custom, setCustom] = useState(false);
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const dispatch = useDispatch()




  const handleAll = () => {
    setAll(true)
    setCustom(false);
    dispatch(getOverViewdata())


  }

  const handleCustom = () => {

    const cutom_Data = <CustomHomeModal setAll={setAll} setCustom={setCustom} setInitialDate={setInitialDate} />
    handleModalData(cutom_Data, "md")
    setAll(false)
    setCustom(true)

  }








  return (
    <>
      <div className="switchbuttons">
        <button className={`${all && 'switchactive'}`} onClick={handleAll}  >All</button>

        <button className={`${custom && 'switchactive'}`} onClick={handleCustom}>Custom</button>

      </div>
    </>
  )
}

export default SwitchButton