import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from '../Context';

import { getListCategoryType, getListOfCollateraltype, getListOFTagsApi } from '../Utils/services';

const Filter = ({ handleUserAnalytic, setActiveFiter }) => {
    const [category, setCategory] = useState([]);
    const [tags, setTags] = useState([]);
    const [collateraltype, setCollateraltype] = useState([])

    const [categaryvalue, setCategoryValue] = useState("");
    const [selectedcourse, setselectedCourse] = useState([]);
    const [collateraltypeValue, setCollateraltypeValue] = useState("")
    const [daterangeInitial, setDateRangeInitial] = useState()
    const [daterangeFinal, setDateFinal] = useState();
    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;






    const getCategory = async () => {
        const results = await getListCategoryType();
        setCategory(results.data)
        console.log(results, "check data")
    }

    const handleGetTag = async () => {
        const result = await getListOFTagsApi()
        setTags(result.data)
    }

    const getListOfCollats = async () => {
        try {
            const result = await getListOfCollateraltype();
            setCollateraltype(result.data);

        } catch (error) {
            console.log(error);
        }
    };



    const handlSelecttype = (event) => {
        setCollateraltypeValue(event.target.value)

    };



    const handlecheckbox = (e) => {
        var temp = selectedcourse;
        if (e.target.checked === false) {
            temp = temp.filter((a) => {
                return a != e.target.value;
            });
        }
        e.target.checked
            ? setselectedCourse([...selectedcourse, e.target.value])
            : setselectedCourse([...temp]);

    };


    useEffect(() => {
        getCategory()
        handleGetTag()
        getListOfCollats()

    }, [])


    const handleSubmit = () => {
        const data = {
            "tagID": selectedcourse,
            "collattypeID": collateraltypeValue,
            "startDate": daterangeInitial,
            "endDate": daterangeFinal,
            "categoryID": categaryvalue
        }


        handleUserAnalytic(data)
        setActiveFiter(true)
        closeModal()
    }


    return (
        <div className='filter-modal'>
            <h3 className='text-center'> Test Collaterals</h3>

            <div className='mt-3 '>
                <p className='ps-2 pb-2'>Category</p>
                <div className='d-flex'>
                    <ul class="ux-btn-group" >

                        {
                            category?.map((item) => (
                                <li>
                                    <input type="radio" id={`${item?.categoryName}`} className="ux-btn-group-item " value={item.categoryID} name="option" onChange={(e) => setCategoryValue(e.target.value)} />
                                    <label for={`${item?.categoryName}`} className='me-2 mt-0 ms-0 mb-0' >{item.categoryName}</label>
                                </li>

                            ))
                        }



                    </ul>
                </div>
                <div className='mt-3 border-bottom-filter'>

                </div>
            </div>
            <div className='mt-3 '>
                <p className='ps-2 pb-2'>Tags</p>
                <ul class="ux-btn-group">

                    {tags.map((course, index) => {
                        return (
                            <>
                                <li key={index} >
                                    <input type="checkbox" value={course.tagsID} id={`${course.tagsName}`} class="ux-btn-group-item" name="option" onClick={handlecheckbox} />
                                    <label for={`${course.tagsName}`} className="me-2 mt-0 ms-0 mb-0"  >{course.tagsName}</label>
                                </li>
                            </>
                        );
                    })}
                </ul>
                <div className='mt-3 border-bottom-filter'>

                </div>
            </div>
            <div className='mt-3 '>
                <p className='ps-2 pb-2'>Collaterals</p>
                <div className='d-flex'>

                    <ul class="ux-btn-group" >

                        {
                            collateraltype?.map((item) => (
                                <li>
                                    <input type="radio" id={`${item?.collateralType}`} className="ux-btn-group-item " value={item.collateralTypeID} name="option2" onChange={handlSelecttype} />
                                    <label for={`${item?.collateralType}`} className='me-2 mt-0 ms-0 mb-0' >{item.collateralType}</label>
                                </li>

                            ))
                        }

                    </ul>

                </div>
                <div className='mt-3 border-bottom-filter'>

                </div>
            </div>
            <div className='mt-3 d-flex justify-content-between filterbtn'>

                <div className='custom-date d-flex justify-content-between'>
                    <input type="date" placeholder='from' className='me-5'
                        value={daterangeInitial}
                        onChange={e => setDateRangeInitial(e.target.value)}
                        max={new Date().toISOString().slice(0, 10)}

                    />
                    <input type="date"
                        placeholder='from'
                        max={new Date().toISOString().slice(0, 10)}
                        onChange={e => setDateFinal(e.target.value)}


                    />

                </div>
                <button className='save-btn-modal' style={{marginTop:'15px'}} onClick={handleSubmit}>Apply</button>
            </div>
        </div>
    )
}

export default Filter