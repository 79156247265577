import React, { useState } from "react";
import SIDEBAR_UL from "./sidebarList";
import downArrow from "../../Assets/image/Logos/downArrow.png";
import navicon from "../../Assets/image/Logos/navicon.svg"
import navicon2 from "../../Assets/image/Logos/home.png"
import subnavicon from "../../Assets/image/Logos/Polygon.svg"
import subnavicon2 from "../../Assets/image/Logos/Polygon2.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, reset } from '../../Redux/auth/authSlice'

const Sidebar = (props) => {
  const toggle = props.toggle;
  const handletoggle = props.handletoggle;
  const [activeLink, setActiveLink] = useState({ 0: true });
  const [listShow, setListShow] = useState({ 0: true });
  const [buttonid, setButtonId] = useState(0);


  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)


  const listHandler = (id, sublist, listRef) => {
    setActiveLink({ [id]: true });

    if (sublist !== undefined && !listShow[listRef]) {
      setListShow({ [listRef]: true });
    } else {
      setListShow({ [listRef]: false });
    }
    // console.log(listShow[listRef], "list ref");

    if (id === 7    ) {
      dispatch(logout())
      dispatch(reset())
      navigate('/')

    }

    // console.log(id,"check")
  };
  return (
    <>
      <div>
        {toggle ? (
          <div className="menuhead">
            <h3>Menu</h3>
          </div>
        ) : (
          <div className="menuhead1"></div>
        )}
        <ul className="side-menu">
          {SIDEBAR_UL.map((list, id) => {
            let sublist = list.sublist;
            let listRef = list.ref;
            let labelText = list.label;
            let path = list.path;
            let src = list.src;
            let src1 = list.src1;

            return (
              <React.Fragment>
                <li
                key={list.id}
                  id={id}
                  onClick={() => {
                    { sublist === undefined ? handletoggle() : listHandler(id, sublist, listRef) }
                  }}
                >
                  <Link
                    to={`${path}`}
                    onClick={() => {
                      listHandler(id, sublist, listRef);
                      setButtonId(parseInt(id));
                    }}
                    className={activeLink[id] && "active"}
                  >
                    <div className="navIcon d-flex">
                      {" "}

                      <div
                        className={id === buttonid ? "imagelogo" : "imagelogo2"}
                      >
                        <img src={id === buttonid ? src1 : src} alt="" />
                      </div>{" "}
                      {toggle ? <p> {labelText}</p> : ""}{" "}
                    </div>



                    {toggle ? (
                      <div>
                        {sublist && (
                          <img
                            src={downArrow}
                            className={`downArrow ${listShow[listRef] && "upArrow"
                              }`}
                            alt="logo"
                          />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>

                {sublist && (
                  <li className="p-0">
                    <ul className={listShow[listRef] && "submenu"}>
                      {list.sublist.map((list, id) => (
                        <li key={list.id}>
                          <NavLink
                            to={`${list.path}`}
                            activeclassname="active"
                            className="subnavlist"
                          >
                            <div className="subnavIcon d-flex ps-4">
                              {" "}
                              <div className="me-3">
                                {/* <img src={subnavicon} alt="" />{" "} */}
                                <img src={window.location.pathname === list.path ? subnavicon2 : subnavicon} alt="" />

                              </div>
                              {toggle ? (
                                <p
                                  onClick={() => {
                                    handletoggle();
                                  }}
                                >
                                  {list.label}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    </>
  );
};


export default Sidebar;


