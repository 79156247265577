import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import { useDropzone } from 'react-dropzone';
import dropzone from "../../../Assets/image/icons/dropzone.png"
import { addArticle, getArticleById, getBrochureById, getGetArtcleTags, updateArticle, updateBrochure } from '../../../Utils/services';
import { addBrochure, getGetBroucherCategory, getGetCompany } from '../../../Utils/services';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { json, useNavigate, useParams } from 'react-router-dom';
import editBrochure from "../../../Assets/image/insurercard/image.png"

const EditBrochureCard = () => {
    const [title, setTitle] = useState("")
    const [category, setCategory] = useState([])
    const [categaryvalue, setCategoryValue] = useState("");
    const [preview, setPreview] = useState();
    const [file, setFiles] = useState([])
    const [article, setArticle] = useState();
    const [loading, setLoading] = useState(false)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const { id } = useParams();

    console.log(id, "slug")


    const navigate = useNavigate();

    // react-draft-wysiwyg Start
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        let convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setArticle(convertedData)
    }
    const embedVideoCallBack = (link) => {
        link = link.replace("watch?v=", "embed/");
        link = link.replace("/watch/", "/embed/");
        link = link.replace("youtu.be/", "youtube.com/embed/");
        return link
    }


    // react-draft-wysiwyg End

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            setPreview(acceptedFiles[0])
            setFiles(

                <li key={acceptedFiles[0].path}>
                    {acceptedFiles[0].path}
                </li>
            );
        }
    });



    const getArticleApi = async (id) => {
        const result = await getBrochureById(
            {
                "brouchersID": id
            }
        )
        console.log(result)
        setTitle(result?.data?.titleName)
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(
            convertFromHTML(result?.data?.brouchersDescription)
        )))
        setArticle(result?.data?.brouchersDescription)
    }


    const handleFormSubmit = async () => {
        // const ID = parseInt(id)

        try {
            setLoading(true)
            console.log(title, article, categaryvalue, preview, "Check")
            const formData = new FormData();

            formData.append("brouchersID", id);
            if (title) {
                formData.append("rvTitle", title);
            }
            if (article) {
                formData.append("rvDescription", article);
            }
            if (preview) {
                formData.append("files", preview);
            }
            // formData.append("tagID", categaryvalue);

            const result = await updateBrochure(formData);
            setLoading(false)
            if (result.status) {
                navigate("/insurer-brochures")
            }
            setLoading(false)

        } catch (error) {
            setLoading(false)
        }
    }


    useEffect(() => {
        getArticleApi(id)
    }, [id])




    console.log(preview, "preview")
    return (
        <div>
            <div className='add-collateral-input'>
                <p className='py-2'>Title</p>
                <TextField id="standard-basic" value={title} placeholder='type something...' variant="standard" className='addcollate' onChange={(e) => setTitle(e.target.value)} />

            </div>




            <div className="row mt-4">
                <div className="col-4">
                    <div className='collateral-description mt-4'>
                        <p className='py-2'>Change Brochure </p>
                        {/* <div>
                            <img src={editBrochure} alt="" />getBrochureById
                        </div> */}

                        <div>
                            <div {...getRootProps()} className="inputDrops mt-2">
                                <input {...getInputProps()} />
                                {isDragActive ? <p>Drop Image Here</p> : <><img src={dropzone} alt="" /></>}
                            </div>
                            <ul className='text-center'>{file && file}</ul>
                            <ul className='text-center p-1'>Upload JPEG,PNG</ul>
                        </div>




                    </div>

                </div>



            </div>
            <div className='collateral-description mt-4'>
                <p className='py-2'>Description </p>
                <div className='editorSectionStart'>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                        toolbar={{
                            embedded: {
                                embedCallback: embedVideoCallBack
                            }
                        }}
                        value={article}
                    />
                </div>





            </div>




            <div className='mt-3 d-flex mb-3'>
                <button className='save-btn-modal me-4' onClick={handleFormSubmit}>
                    {loading ?
                        <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </>
                        : "Update"}
                </button>
                <button className='concel-btn-modal' onClick={() => navigate("/insurer-brochures")} >Cancel</button>

            </div>

        </div>
    )
}

export default EditBrochureCard