import React, { useEffect } from 'react'
import Button from '../../../Components/commoncomponent/Button'
import InputSearch from '../../../Components/commoncomponent/InputSearch'
import SwitchButton from '../../../Components/commoncomponent/SwitchButton'
import SwitchButtonTwo from '../../../Components/commoncomponent/SwitchButtonTwo'
import logo from "../../../Assets/image/Collateral/collateral.png"
import logotwo from "../../../Assets/image/Collateral/collertaltwo.png"
import logothree from "../../../Assets/image/Collateral/colleratalfour.png"
import CollateralDeclinedCard from '../../../Components/commoncomponent/CollateralDeclinedCard'
import { getListOfDecline, getListOfDeclineVideo } from '../../../Utils/services'
import Spinner from "../../../Components/commoncomponent/Spinner"
import { useState } from 'react'
import VideoDecline from '../../../Components/commoncomponent/VideoDecline'
import Sort from '../../../Components/commoncomponent/Sort'



const CollateralDecline = () => {
    const [declineList, setDeclineList] = useState([]);
    const [declineVideoList, setDeclineVideoList] = useState([])
    const [loading, setLoading] = useState(true)
    const [allImage, setAllImage] = useState(true);
    const [allVideo, setAllVideo] = useState(false);
    const [search, setSearchVal] = useState("")

    const handleDeclineList = async () => {
        const result = await getListOfDecline()
        setLoading(false)
        const _newData = result.data.filter((item) => item.collateralType !== "video")
        setDeclineList(_newData)
    }

    const handleDeclineVideoList = async () => {
        const result = await getListOfDeclineVideo()
        setDeclineVideoList(result.data)
    }

    useEffect(() => {
        handleDeclineList()
        handleDeclineVideoList()
    }, [])

    return (
        <>
            <div className='container-fuild'>
                <div className="row">
                    <div className='d-flex justify-content-between align-items-center declineflex'>
                        <InputSearch setSearchVal={setSearchVal} />
                        <div className='d-flex align-items-center declinedpad' style={{justifyContent:'center'}}>
                            <div className='me-4'>
                                <SwitchButtonTwo custom={allVideo} setCustom={setAllVideo} all={allImage} setAll={setAllImage} />
                            </div>

                            <Sort setSearchVal={setSearchVal} />

                        </div>



                    </div>

                </div>
                {
                    allImage &&

                    <>

                        {loading ? <Spinner /> : <React.Fragment>

                            <div className="row mt-5">

                                {declineList?.filter((val) => {
                                    if (search == "") {
                                        return val;
                                    }
                                    else if (val && val.titleName && val.titleName.toLowerCase().includes(search.toLowerCase())) {
                                        return val
                                    }
                                    else if (val && val.collateralType && val.collateralType.toLowerCase().includes(search.toLowerCase())) {
                                        return val
                                    }
                                    else if (val && val?.collatTags[0] && val.collatTags[0].toLowerCase().includes(search.toLowerCase())) {
                                        return val
                                    }

                                }).map((item) => (
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                        <CollateralDeclinedCard collertalimg={logo} item={item} />

                                    </div>

                                ))}


                            </div>

                        </React.Fragment>}

                    </>
                }


                {
                    allVideo &&
                    <>
                        <>

                            {loading ? <Spinner /> : <React.Fragment>

                                <div className="row mt-5">

                                    {declineVideoList?.filter((val) => {
                                        if (search == "") {
                                            return val;
                                        }
                                        else if (val && val.titleName && val.titleName.toLowerCase().includes(search.toLowerCase())) {
                                            return val
                                        }
                                        else if (val && val.collateralType && val.collateralType.toLowerCase().includes(search.toLowerCase())) {
                                            return val
                                        }
                                        else if (val && val?.tagName[0] && val.tagName[0].toLowerCase().includes(search.toLowerCase())) {
                                            return val
                                        }

                                    }).map((item) => (
                                        <div className="col-6 mb-4">
                                            <VideoDecline collertalimg={logo} item={item} />


                                        </div>

                                    ))}

                                </div>

                            </React.Fragment>}

                        </>

                    </>
                }

            </div>
        </>
    )
}

export default CollateralDecline;