import React, { useState } from 'react'
import crossicon from "../../Assets/image/Collateral/cross.png"
import yesicon from "../../Assets/image/Collateral/yes.png"
import { ApprovedColleteral, deActiveColetralModal, declineColetralModal } from '../../Utils/services';

const PendingButton = ({ item, getPendingCollateralist }) => {
    const [show, setShow] = useState(true);
    const [declineDetails, setDeclineDetails] = useState(false);
    const [approved, setApproved] = useState(false)

    const handleDecline = () => {
        setShow(false)
        setDeclineDetails(true)
        setApproved(false)
    }
    const handleCross = () => {
        setShow(true)
        setDeclineDetails(false)
        setApproved(false)
    }

    const handaleYes = async () => {
        try {
            const result = await declineColetralModal({
                "collateralID": item.collatID,
            });
            getPendingCollateralist()
            setShow(true)
            setDeclineDetails(false)

            setApproved(false)

        } catch (error) {

        }
    }


    const handAproved = () => {
        setShow(false)
        setApproved(true)
        setDeclineDetails(false)
    }

    const handleApprovedApi = async () => {
        try {
            const result = await ApprovedColleteral({
                "collateralID": item.collatID,
            })
            getPendingCollateralist()
            setShow(true)
            setDeclineDetails(false)
            setApproved(false)

        } catch (error) {

        }


    }

    return (
        <React.Fragment>
            {
                show && <div className='d-flex '>

                    <button className='deactivebtn me-3' onClick={handleDecline}  >Deacline</button>
                    <button className='approvedbtn ' onClick={handAproved}>Approved</button>
                </div>
            }

            {
                declineDetails &&
                <div className='d-flex align-items-center decline-details'>
                    <span className='me-3'>Decline Collateral?</span>
                    <img src={crossicon} alt="" className='cursor-pointer me-3' onClick={handleCross} />
                    <img src={yesicon} alt="" className='cursor-pointer' onClick={handaleYes} />

                </div>
            }

            {
                approved &&
                <div className='d-flex align-items-center approve-details'>
                    <span className='me-3'>Approve Collateral?</span>
                    <img src={crossicon} alt="" className='cursor-pointer me-3' onClick={handleCross} />
                    <img src={yesicon} alt="" className='cursor-pointer' onClick={handleApprovedApi} />

                </div>
            }

        </React.Fragment >
    )
}

export default PendingButton