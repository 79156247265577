import React from 'react'

const ViewModal = ({ data }) => {

    return (
        <div className='test-modal'>

            <div className='d-flex justify-content-between form-flex'>
                <div>
                    <p className='ps-2 pb-2'>Name</p>
                    <input type="text" placeholder='Samriti Sharma' value={data.name} disabled={true} />

                </div>
                <div className='mg-space'>
                    <p className='ps-2 pb-2 '>Last Name</p>
                    <input type="text" placeholder='Sharma'className='space-form ' />

                </div>
            </div>
            <div className='d-flex justify-content-between mt-4 form-flex'>
                <div>
                    <p className='ps-2 pb-2'>Contact Number</p>
                    <input type="text" placeholder='9876678954' value={data.contactNumber} disabled={true} />

                </div>
                <div className='mg-space'>
                    <p className='ps-2 pb-2'>Email ID</p>
                    <input type="text" placeholder='samritisharma@gmail.com' className='space-form ' value={data.userEmail} disabled={true} />

                </div>
            </div>
            <div className='mt-3 '>
                <p className='ps-2 pb-2'>Permissions</p>
                <div className='mt-1 add-people-btn'>
                    {data?.listOfPermission.length == 0 && <p className='text-center'>No Permission</p>}

                    {
                        data?.listOfPermission.map(item => <button className='m-2'>{item.permissionName}</button>)
                    }
                </div>
            </div>
            <div className='mt-3 '>
                <p className='ps-2 pb-2'>Authority</p>
                <div className='mt-1 add-people-btn'>
                    <button className='m-2'>{data.authority}</button>

                </div>
            </div>

        </div>
    )
}

export default ViewModal