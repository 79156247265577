import axios from 'axios'
import {
  CREATELANGUAGE,
  CREATEUSER,
  EDITLANG,
  EDITTAGS,
  LISTCOLLATERAL,
  LISTNONAPPROVCOLLETERAL,
  LISTCOLLATERALS,
  LISTOFLANGUAGE,
  LISTOFTAGS,
  LOGIN,
  PEOPLELIST,
  PROFILE,
  APPROVEDCOLLETERAL,
  LISTOFDEACTIVATED,
  LISTDECLINEDCOLLETERAL,
  APPROVECOLLETERAL,
  TESTCOLLETERAL,
  DEACTIVEMODAL,
  REACTIVATE,
  DECLINEREQ,
  EDITCOLLATEALTYPE,
  LISTOFTAGSADDCOLLETRAL,
  ADDCOLLATERALS,
  LISTOFCATEGORYTYPE,
  DELETECOLLATERALTYPE,
  DELETETAGS,
  DELETELANG,
  DELETEUSER,
  CREATEOFTAGS,
  CREATECOLLATERALTYPE,
  TAGOVERVIEW,
  ANALYTIC,
  GETARTICLE,
  ADDCOMPANY,
  ADDBROCHURECATEGORY,
  GETCOMPANY,
  GETBRACHURECATEGORY,
  RVADDBROUCHER,
  GETBROCHURE,
  RVARTICLETAG,
  ADDARTICLE,
  ADDARTICLECATEGORY,
  DELARTICLE,
  ADD_CONTENTHUB_VIDEO,
  VIDEO_CONTENT,
  GET_VIDEO_SIZE,
  TESTVIDEO,
  PASSWORDUPDATE,
  CHARTS_ANALYTICS,
  USER_ANALYTICS,
  USER_EXCEL,
  DELETE_BROCHURE,
  EDIT_RV_CATEGORY,
  DELETE_CONTENT_CATGROY,
  EDIT_RV_COMPANY,
  DELETE_COMPANY,
  EDIT_CONTENT_API,
  DELETE_ARTICLE_TAG,
  GET_ARTCLE_BY_ID,
  UPDATE_ARTCLE,
  GET_BROCHURE_BY_ID,
  UPDATE_BROCHURE,
  SET_PRIORITY,
  EDIT_COLLATERAL,
  COLLATERALBYID,
} from './routes'

export const BASE_URL = 'https://collaterals.api.renewbuy.com/api' //production//
// export const BASE_URL = 'https://renewbuy.techtonic.asia/api' //devlopment//

axios.defaults.baseURL = BASE_URL
const token = localStorage.getItem('token')

//  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
//   axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const loginService = async (data) => {
  try {
    const response = await axios.post(LOGIN, data, {
      headers: { 'Content-Type': 'application/json' },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getListOfTags = async (data) => {
  try {
    const response = await axios.get(LISTOFTAGS, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getListOfCollateraltype = async (data) => {
  try {
    const response = await axios.get(LISTCOLLATERAL, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getListOflanguage = async (data) => {
  try {
    const response = await axios.get(LISTOFLANGUAGE, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const editListoflanguage = async (data) => {
  try {
    const response = await axios.post(EDITLANG, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const addLanguage = async (data) => {
  try {
    const response = await axios.post(CREATELANGUAGE, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const editListOfTags = async (data) => {
  try {
    const response = await axios.post(EDITTAGS, data, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getListOfUser = async (data) => {
  try {
    const response = await axios.get(PEOPLELIST, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getPendingCollateral = async (data) => {
  try {
    const response = await axios.get(
      `${LISTNONAPPROVCOLLETERAL}?pageNo=${data}&pageSize=20`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    )
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getPendingCollateralVideo = async (data) => {
  try {
    const response = await axios.get(`${LISTNONAPPROVCOLLETERAL}?videoType=1`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getApprovedCollateral = async (data) => {
  try {
    const response = await axios.get(
      `${APPROVEDCOLLETERAL}?pageNo=${data}&pageSize=20`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    )
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getApprovedCollateralVideo = async (data) => {
  try {
    const response = await axios.get(`${APPROVEDCOLLETERAL}?videoType=1`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getDeactivatedCollateral = async (data) => {
  try {
    const response = await axios.get(LISTOFDEACTIVATED, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getDeactivatedVideoCollateral = async (data) => {
  try {
    const response = await axios.get(`${LISTOFDEACTIVATED}?videoType=1`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getListOfMaster = async (data) => {
  try {
    const response = await axios.get(
      `${LISTCOLLATERALS}?pageNo=${data}&pageSize=20`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    )
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getListOfMasterVideo = async (data) => {
  try {
    const response = await axios.get(`${LISTCOLLATERALS}?videoType=1`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getListOfDecline = async (data) => {
  try {
    const response = await axios.get(LISTDECLINEDCOLLETERAL, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getListOfDeclineVideo = async (data) => {
  try {
    const response = await axios.get(`${LISTDECLINEDCOLLETERAL}?videoType=1`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getProfile = async () => {
  try {
    const response = await axios.get(PROFILE, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const createUser = async (data) => {
  try {
    const response = await axios.post(CREATEUSER, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const ApprovedColleteral = async (data) => {
  try {
    const response = await axios.post(APPROVECOLLETERAL, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export async function TestColleteral(data) {
  try {
    const response = await axios.post(TESTCOLLETERAL, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      responseType: 'blob',
    })
    return response
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export async function TestVideoColleteral(data) {
  try {
    const response = await axios.post(TESTVIDEO, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      responseType: 'blob',
    })
    return response
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}
export const deActiveColetralModal = async (data) => {
  try {
    const response = await axios.post(DEACTIVEMODAL, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const Reactived = async (data) => {
  try {
    const response = await axios.post(REACTIVATE, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const CreateTagsType = async (data) => {
  try {
    const response = await axios.post(CREATEOFTAGS, data, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const declineColetralModal = async (data) => {
  try {
    const response = await axios.post(DECLINEREQ, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const createCollateralType = async (data) => {
  try {
    const response = await axios.post(CREATECOLLATERALTYPE, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const editCollateraltype = async (data) => {
  try {
    const response = await axios.post(EDITCOLLATEALTYPE, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const deleteCollateraltype = async (data) => {
  try {
    const response = await axios.post(DELETECOLLATERALTYPE, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const deleteTags = async (data) => {
  try {
    const response = await axios.post(DELETETAGS, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}
export const deleteLang = async (data) => {
  try {
    const response = await axios.post(DELETELANG, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const deleteUser = async (data) => {
  try {
    const response = await axios.post(DELETEUSER, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getListOFTagsApi = async () => {
  try {
    const response = await axios.get(LISTOFTAGSADDCOLLETRAL, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const editCollaterals = async (data) => {
  try {
    const response = await axios.post(EDIT_COLLATERAL, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100)
        console.log(
          event.loaded,
          'kb',
          'of',
          event.total,
          'kb',
          percent,
          'Check',
        )
      },
    })

    if (response.status === 200) {
      return { res: response }
    } else return response
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const addCollaterals = async (data) => {
  try {
    const response = await axios.post(ADDCOLLATERALS, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100)
        console.log(
          event.loaded,
          'kb',
          'of',
          event.total,
          'kb',
          percent,
          'Check',
        )
      },
    })

    if (response.status === 200) {
      return { res: response }
    } else return response
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getListCategoryType = async () => {
  try {
    const response = await axios.get(LISTOFCATEGORYTYPE, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

// home api //
export const getTagOverView = async (data) => {
  try {
    const response = await axios.post(TAGOVERVIEW, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

// rewverse

export const addCompany = async (data) => {
  try {
    const response = await axios.post(ADDCOMPANY, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const addBrochureCategory = async (data) => {
  try {
    const response = await axios.post(ADDBROCHURECATEGORY, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getGetCompany = async () => {
  try {
    const response = await axios.get(GETCOMPANY, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getGetBroucherCategory = async () => {
  try {
    const response = await axios.get(GETBRACHURECATEGORY, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const addBrochure = async (data) => {
  try {
    const response = await axios.post(RVADDBROUCHER, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getBrochure = async (data) => {
  try {
    const response = await axios.post(GETBROCHURE, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getGetArtcleTags = async () => {
  try {
    const response = await axios.get(RVARTICLETAG, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const addArticle = async (data) => {
  try {
    const response = await axios.post(ADDARTICLE, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const addArticleCategory = async (data) => {
  try {
    const response = await axios.post(ADDARTICLECATEGORY, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const delArticle = async (data) => {
  try {
    const response = await axios.post(DELARTICLE, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getGetArticle = async (data) => {
  try {
    const response = await axios.post(GETARTICLE, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const addRvVideo = async (data) => {
  try {
    const response = await axios.post(ADD_CONTENTHUB_VIDEO, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getVideoContent = async (data) => {
  try {
    const response = await axios.post(VIDEO_CONTENT, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getVideoSize = async () => {
  try {
    const response = await axios.get(GET_VIDEO_SIZE, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      return { res: response.data.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const passwordUpdate = async (data) => {
  try {
    const response = await axios.post(PASSWORDUPDATE, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const analyticsData = async (data) => {
  try {
    const response = await axios.post(CHARTS_ANALYTICS, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const userAnalyticsData = async (data) => {
  try {
    const response = await axios.post(USER_ANALYTICS, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export async function downloadExcel(data) {
  try {
    const response = await axios.get(USER_EXCEL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      responseType: 'blob',
    })
    return response
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const delBrochure = async (data) => {
  try {
    const response = await axios.post(DELETE_BROCHURE, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const delContentCategory = async (data) => {
  try {
    const response = await axios.post(DELETE_CONTENT_CATGROY, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}
export const delCompany = async (data) => {
  try {
    const response = await axios.post(DELETE_COMPANY, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const delcontenttag = async (data) => {
  try {
    const response = await axios.post(DELETE_ARTICLE_TAG, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const EditRvCategory = async (data) => {
  try {
    const response = await axios.post(EDIT_RV_CATEGORY, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const EditRvCampony = async (data) => {
  try {
    const response = await axios.post(EDIT_RV_COMPANY, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const EditContentapi = async (data) => {
  try {
    const response = await axios.post(EDIT_CONTENT_API, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getArticleById = async (data) => {
  try {
    const response = await axios.post(GET_ARTCLE_BY_ID, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const updateArticle = async (data) => {
  try {
    const response = await axios.post(UPDATE_ARTCLE, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getBrochureById = async (data) => {
  try {
    const response = await axios.post(GET_BROCHURE_BY_ID, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const updateBrochure = async (data) => {
  try {
    const response = await axios.post(UPDATE_BROCHURE, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const setPriority = async (data) => {
  try {
    const response = await axios.post(SET_PRIORITY, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getCollateralById = async (data) => {
  try {
    const response = await axios.post(COLLATERALBYID, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return { res: response.data }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}
