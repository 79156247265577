import React, { useContext, useEffect, useState } from 'react'
import { ModalContext } from '../../Context';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { getListOfTags } from '../../Utils/services';

const Sort = ({ name, handleAdd, setSearchVal }) => {
    const [activeSort, setActiveSort] = useState(false)
    const [listTag, setListOfTags] = useState([])

    const getTagData = async () => {
        try {

            const result = await getListOfTags(0)
            if (result.data) {

                setListOfTags(result.data)

            }

        } catch (error) {

        }
    }

    useEffect(() => {
        getTagData()
    }, [])



    const handleShort = (name) => {
        setSearchVal(name)
        setActiveSort(true)
    }

    const handleShortAll = (name) => {
        setSearchVal("")
        setActiveSort(false)
    }

    return (
        <>
            {/* <button className='' id="dropdown-basic-button" onClick={handleAdd} >{name}</button> */}
            <DropdownButton id="dropdown-basic-button" className={`${activeSort && "activeSort"}`} title="Sort">
                <Dropdown.Item href="#" onClick={() => handleShortAll()}>All</Dropdown.Item>
                {listTag?.map((items) => (
                    <Dropdown.Item href="#" onClick={() => handleShort(items?.tagsName)}>{items?.tagsName}</Dropdown.Item>
                ))}
            </DropdownButton>
        </>

    )
}

export default Sort;