import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import collimage from '../../Assets/image/insurercard/image.png'
import { ModalContext } from '../../Context';
import BrochureDelete from '../../ModalTemplate/BrochureDelete';

const InsurerCard = ({ item, getBrochureApi, category }) => {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const navigate = useNavigate()


    const handleDelete = () => {
        const addCompany = <BrochureDelete item={item} getBrochureApi={getBrochureApi} category={category} />
        handleModalData(addCompany, "md")

    }


    const handleEdit = (item) => {
        console.log(item)
        navigate(`/insurer-brochures/edit-brochure/${item.brouchersID}`)
    }


    return (
        <div className="card collateral p-4">
            <div className='d-flex align-items-center'>
                <div className='max-hight insurance-height me-3 '>
                    <img src={item?.broucherThumb || collimage} alt="" />
                </div>

                <div className='d-flex collateraldata flex-column h-100 justify-content-between'>

                    <div className='ps-3 pt-2'>
                        <p>{item?.titleName}</p>
                        <p className='collateral-details mt-2'>Description</p>
                        <p className='collateral-details responsive-details mt-2' >
                            <div className='description-browser' dangerouslySetInnerHTML={{
                                __html: item?.brouchersDescription
                            }} />

                        </p>

                    </div>
                    <div className='ps-3 Insurer-btn'>
                        <button className='deletebtn' onClick={handleDelete}>Delete</button>
                        <button className='edit' onClick={() => handleEdit(item)}>Edit</button>
                    </div>

                </div>



            </div>

        </div>
    )
}

export default InsurerCard