import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import avtar from "../../../Assets/image/Profile/avatar.png";
import view from "../../../Assets/image/Profile/view.png";
import Avatar from '../../../Components/commoncomponent/Avatar';
import SwitchProfiledetails from '../../../Components/commoncomponent/SwitchProfiledetails';

const ProfilePassword = () => {
    const [viewpassword, setPassword] = useState(true)
    const navigate = useNavigate()

    const handleUpadatePasswordpage = (e) => {
        navigate('/profile/updatepassword')
    }
    const handleChangePasword = () => {
        navigate('/profile')
    }



    return (
        <div className='profile'>

            {/* -----------------tab button -------------------------------- */}

            <SwitchProfiledetails Password="productactive" handleChangePassword={handleChangePasword} />

            {/* --------------- profile page ---------------------------------- */}

            <Avatar />

            {/* ---------------- avatar details---------------------------------- */}

            <div className='profile_form_details mt-4 '>
                <div className=''>
                    <div className="row ">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                            <div className=''  >
                                <p>Current Password</p>
                                <div className='passwordview'>
                                    <input type={viewpassword ? "password" : "text"} placeholder="Samriti" value={1234567886932323} disabled />
                                    <img src={view} alt="" className='viewpassword' onClick={() => setPassword(!viewpassword)} />
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <div className='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 profile_editbtn mt-4' onClick={handleUpadatePasswordpage}>
                    <button className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4">Change Password</button>
                </div>


            </div>

        </div >
    )
}

export default ProfilePassword