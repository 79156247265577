import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import { useDropzone } from 'react-dropzone';
import dropzone from "../../../Assets/image/icons/dropzone.png"
import { addBrochure, getGetBroucherCategory, getGetCompany } from '../../../Utils/services';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate } from 'react-router-dom';

const AddBrochure = () => {
    const [title, setTitle] = useState("")
    const [category, setCategory] = useState([])
    const [categaryvalue, setCategoryValue] = useState("");
    const [companyData, setCompanyData] = useState([]);
    const [companyValue, setCompanyValue] = useState("")
    const [preview, setPreview] = useState([]);
    const [file, setFiles] = useState([])
    const [article, setArticle] = useState();
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    // react-draft-wysiwyg Start
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        let convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setArticle(convertedData)
    }
    const embedVideoCallBack = (link) => {
        link = link.replace("watch?v=", "embed/");
        link = link.replace("/watch/", "/embed/");
        link = link.replace("youtu.be/", "youtube.com/embed/");
        return link
    }


    // react-draft-wysiwyg End


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            "application/pdf": ['.pdf']

        },
        onDrop: (acceptedFiles) => {
            setPreview(acceptedFiles[0])
            setFiles(

                <li key={acceptedFiles[0].path}>
                    {acceptedFiles[0].path}
                </li>
            );
        }
    });



    const GetCategoryApi = async () => {
        const result = await getGetBroucherCategory()
        console.log(result, "check")
        setCategory(result.data)

    }

    const GetCompanyApi = async () => {
        const result = await getGetCompany()
        console.log(result, "check data")
        setCompanyData(result.data)
    }


    const handleFormSubmit = async () => {
        setLoading(true)
        console.log(title, article, categaryvalue, preview, companyValue, "Check")
        const formData = new FormData();
        formData.append("rvTitle", title);
        formData.append("rvDescription", article);
        formData.append("tagID", categaryvalue);
        formData.append("files", preview);
        formData.append("companyID", companyValue);
        const result = await addBrochure(formData);
        setLoading(false)
        if (result.status) {
            navigate("/insurer-brochures")
        }
        setLoading(false)
    }


    useEffect(() => {
        GetCategoryApi()
    }, [])
    useEffect(() => {
        GetCompanyApi()
    }, [])



    return (
        <div>
            <div className='add-collateral-input'>
                <p className='py-2'>Title</p>
                <TextField id="standard-basic" placeholder='type something...' variant="standard" className='addcollate' onChange={(e) => setTitle(e.target.value)} />
            </div>

            <div className='collateral-category mt-4'>
                <p className='py-2'> Select Category </p>
                <div className='d-flex '>

                    <ul class="ux-btn-group" >

                        {
                            category?.map((items) => (
                                <li>
                                    <input type="radio" id={`${items?.categoryName}`} className="ux-btn-group-item " value={items.categoryID} name="option1" onClick={(e) => setCategoryValue(e.target.value)} />
                                    <label for={`${items?.categoryName}`} className='me-2 mt-0 ms-0 mb-0' >{items.categoryName}</label>
                                </li>

                            ))
                        }

                    </ul>
                </div>

            </div>
            <div className='collateral-category mt-4'>
                <p className='py-2'> Select Company</p>
                <div className='d-flex '>
                    <ul class="ux-btn-group " >

                        {
                            companyData?.map((item) => (
                                <li>
                                    <input type="radio" id={`${item?.companyName}`} className="ux-btn-group-item " value={item?.companyID} name="option" onClick={(e) => setCompanyValue(e.target.value)} />
                                    <label for={`${item?.companyName}`} className='me-2 mt-0 ms-0 mb-0' >{item?.companyName}</label>
                                </li>

                            ))
                        }

                    </ul>

                </div>

            </div>

            <div className="row mt-4">
                <div className="col-lg-4 col-md-6 col-sm-8 col-8 ">
                    <div className='collateral-description mt-4'>
                        <p className='py-2'>Upload Collateral </p>

                        <div>
                            <div {...getRootProps()} className="inputDrops mt-2">
                                <input {...getInputProps()} />
                                {isDragActive ? <p>Drop Image Here</p> : <><img src={dropzone} alt="" /></>}
                            </div>
                            <ul className='text-center p-1'>{file ? file : "PNG,JPEG,etc"}</ul>

                        </div>

                    </div>

                </div>


            </div>

            <div className='collateral-description mt-4'>
                <p className='py-2'>Description </p>
                <div className='editorSectionStart'>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                        toolbar={{
                            embedded: {
                                embedCallback: embedVideoCallBack
                            }
                        }}
                        value={article}
                    />
                </div>

            </div>




            <div className='d-flex mt-3 mb-3'>
                <button className='save-btn-modal me-4' onClick={handleFormSubmit}>
                    {loading ?
                        <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </>
                        : "Add"}
                </button>
                <button className='concelbtn'>Concel</button>
            </div>

        </div>
    )
}

export default AddBrochure;