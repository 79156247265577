import React, { useContext, useState } from 'react'
import doc from "../../Assets/image/Collateral/doc.svg"
import view from "../../Assets/image/Collateral/bi_view-list.svg"
import TestModal from '../../ModalTemplate/TestModal'
import ViewCollateral from '../../ModalTemplate/ViewCollateral'
import { ModalContext } from '../../Context'
import { deActiveColetralModal } from '../../Utils/services'
import crossicon from "../../Assets/image/Collateral/cross.png"
import yesicon from "../../Assets/image/Collateral/yes.png"
import { useNavigate } from 'react-router-dom'

const MasterCard = ({ collertalimg, item, HandleMaterList }) => {
  const [show, setShow] = useState(true);
  const [showDeactiveDetails, setShowDeactiveDetails] = useState(false);
  const navigate = useNavigate();
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;



  const handleModal = (type) => {
    const tabsValue = type;
    const Test = <TestModal item={item} />
    const View = <ViewCollateral item={item} />
    switch (tabsValue) {
      case "TEST":
        handleModalData(Test, "cs");
        break;
      case "VIEW":
        handleModalData(View, "sm");
        break;

      default:
        handleModalData(Test, "lg");
        break;
    }
  }

  const handleDeactiveCollateral = async () => {
    const result = await deActiveColetralModal({
      "collateralID": item.collatID,
    })
    HandleMaterList()
    setShow(true)
    setShowDeactiveDetails(false)
  }
  const handleCross = () => {
    setShow(true)
    setShowDeactiveDetails(false)
  }

  const handleDeactive = () => {
    setShow(false)
    setShowDeactiveDetails(true)

  }

  const handleEdit = (id) => {
    navigate(`/edit-collateral/${item.collatID}`, { state: { item: item } });

  }


  return (
    <div className="card collateral">
      <div className="card-body p-5 collateralbody">
        <div className='d-flex collateraldata'>
          <div className="abcd">
            <div className='max-hight '>

              <img src={item.collatData[0]?.thumbImage || collertalimg} alt="" />
            </div>
          </div>
          <div className='ps-5 collertalimgtyype'>
            <p>{item.titleName}</p>
            <p className='collateral-details mt-2'>Type : <span>{item.collateralType}</span></p>
            <p className='collateral-details mt-2'>Category : <span>{item.categoryName}</span></p>
            <p className='collateral-details mt-2'>Status : <span>{item.status}</span></p>
            <p className='collateral-details mt-2'>Uploaded On : <span>{item.addedOn}</span></p>
            <p className='collateral-details mt-2'>Uploaded By : <span>{item.addedBy}</span></p>
            <p className='collateral-details mt-2'>Scheduled For : <span>{item.scheduledTime}</span></p>
          </div>

        </div>

        <div className='mt-4 d-flex justify-content-between align-items-center'>

          <div className='d-flex align-items-center appdecline'>
            <span className='testbtn me-3 cursor-pointer' onClick={() => handleModal("TEST")}> <img src={doc} alt="" /> Test</span>
            <span className='viewbtn cursor-pointer' onClick={() => handleEdit()} > <img src={view} alt="" />  Edit</span>
          </div>
          {
            show &&
            <div className='d-flex'>
              <button className='deactivebtn' onClick={handleDeactive}>Deactivate</button>
            </div>
          }
          {
            showDeactiveDetails &&
            <div className='d-flex align-items-center decline-details'>
              <span className='me-3'>Deactivate Collateral?</span>
              <img src={crossicon} alt="" className='cursor-pointer me-3' onClick={handleCross} />
              <img src={yesicon} alt="" className='cursor-pointer' onClick={handleDeactiveCollateral} />

            </div>
          }

        </div>


      </div>
    </div>
  )
}

export default MasterCard