import React from 'react'

const AnalityicsTabs = () => {

    return (
        <div className='analityics-tabs  d-flex justify-content-between align-items-center'>
            <div className='pb-3 active-tab'>
                <p>All Orders</p>
            </div>
            <div className='pb-3'>
                <p>Pending Orders</p>
            </div>
            <div className='pb-3'>
                <p>Delivered Orders</p>
            </div>
            <div className='pb-3'>
                <p>Cancelled Orders</p>
            </div>
            <div className='pb-1'>
                <button className='excle-export px-4 py-2'>Export to Excel</button>
            </div>


        </div>
    )
}

export default AnalityicsTabs