import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../../../Components/commoncomponent/Button'
import MarchantCard from '../../../../Components/commoncomponent/MarchantCard'
import SwitchProduct from '../../../../Components/commoncomponent/SwitchProduct'
import storeicon from "../../../../Assets/image/Rbstore/storeimg.png"
import cupicon from "../../../../Assets/image/Rbstore/cup.png"
import persh from "../../../../Assets/image/Rbstore/persh.png"
import { ModalContext } from '../../../../Context'
import AddProduct from '../../../../ModalTemplate/AddProduct'

const Merchant = () => {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const navigate = useNavigate()

    const handleCollateralpage = (e) => {

        navigate("/product/collateral")


    }
console.log(closeModal)

    const handleAdd = () => {
        const addCollateral = <AddProduct />
        handleModalData(addCollateral, "lg")
    }

    return (
        <>
            <div className='container-fuild'>
                <div className="row">
                    <div className="productswitch">
                        <SwitchProduct merchant="productactive" handleCollateral={handleCollateralpage} />
                        <div className='addbtnmv'>
                        <Button name="Add +" handleAdd={handleAdd} />
                        </div>
                    </div>
                </div>

                <div className="row mt-5 ">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-5">
                        <MarchantCard collertalimg={storeicon} />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-5">
                        <MarchantCard collertalimg={cupicon} />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-5">
                        <MarchantCard collertalimg={persh} />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-5">
                        <MarchantCard collertalimg={storeicon} />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-5">
                        <MarchantCard collertalimg={storeicon} />
                    </div>


                </div>
            </div>

        </>
    )
}

export default Merchant