import React, { useContext, useMemo } from "react";
import BarCharts from "../../Components/commoncomponent/BarCharts";
import ColletralTable from "../../Components/commoncomponent/ColletralTable";
import OverView from "../../Components/commoncomponent/OverView";
import OverViewSlider from "../../Components/commoncomponent/OverViewSlider";
import SwitchCharts from "../../Components/commoncomponent/SwitchCharts";
import filter from "../../Assets/image/icons/filter.png";
import acitvefilter from "../../Assets/image/icons/activefilter.svg";
import cross from "../../Assets/image/icons/cross.png";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { ModalContext } from "../../Context";
import Filter from "../../ModalTemplate/Filter";
import {
  analyticsData,
  downloadExcel,
  getTagOverView,
  userAnalyticsData,
} from "../../Utils/services";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOverViewdata, reset } from "../../Redux/home/homeSlice";
import FileSaver from "file-saver";

const Home = () => {
  const [activeSwitchImage, SetActiveSwitchImage] = useState(true);
  const [activeSwitchVideo, SetActiveSwitchVideo] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [videoChart, setVideoChart] = useState([]);
  const [category, setCategory] = useState(null);
  const [userAnalytics, setUserAnalytics] = useState();
  const [intialDate, setInitialDate] = useState({
    startdate: null,
    finaldate: null,
  });
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const { homes } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const date = localStorage.getItem("manthData");
  const [activeFilter, setActiveFiter] = useState(false);
  const [sortvalue, setsortvalue] = useState(null);
  const [sortname, setsortname] = useState("");

  const FeedsColumns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: (data, i) => {
          return (
            <>
              <span>{i + 1}</span>
            </>
          );
        },
      },

      {
        Header: "Most Active User",
        accessor: (data) => {
          return (
            <>
              <span>{data.activeUser}</span>
            </>
          );
        },
      },

      {
        Header: "Club Status",
        accessor: (data) => {
          return (
            <>
              <span>null</span>
            </>
          );
        },
      },
      {
        Header: "Date",
        accessor: (data) => {
          return (
            <>
              <span>{data.date}</span>
            </>
          );
        },
      },

      {
        Header: "Downloads",
        accessor: (data) => {
          return (
            <>
              <span>{data.count}</span>
              {/* <span className="MuiButton-label underline ms-3"><img src={delIcon} alt="delete" /></span> */}
            </>
          );
        },
      },
      {
        Header: "EI Code",
        accessor: (data) => {
          return (
            <>
              <span>{data.eiCode}</span>
            </>
          );
        },
      },
    ],
    []
  );

  const handleAnalyticsApi = async () => {
    const result = await analyticsData({
      startDate: date?.startdate,
      endDate: date?.finaldate,
      type: 1,
      category: category,
    });
    setChartData(result.res.data);
    console.log(result.res, "check");
  };

  const handleFilter = () => {
    const addCollateral = (
      <Filter
        handleUserAnalytic={handleUserAnalytic}
        setActiveFiter={setActiveFiter}
      />
    );
    handleModalData(addCollateral, "lg");
  };

  const handleVideoApi = async () => {
    const result = await analyticsData({
      startDate: date?.startdate,
      endDate: date?.finaldate,
      type: 2,
      category: category,
    });
    setVideoChart(result.res.data);
  };

  useMemo(() => {
    handleAnalyticsApi();
    handleVideoApi();
  }, [category, date]);

  const HandleClick = async (tagId) => {
    const results = await analyticsData({
      startDate: intialDate?.startdate,
      endDate: intialDate?.finaldate,
      type: 1,
      category: category,
      tagId: tagId,
    });
    // const result = results.res.data.filter(item => item?.tagID == title)
    // console.log(result)
    setChartData(results.res.data);
  };

  const handleUserAnalytic = async (data) => {
    if (data) {
      const result = await userAnalyticsData(data);
      const reversedData = result.res.data.reverse();
      setUserAnalytics(reversedData);

      if (sortvalue !== null) {
        if (sortvalue === "0") {
          const sortedRolls = reversedData.sort(
            (r1, r2) => r1.count - r2.count
          );
          console.log(reversedData, "reversedData");
          setUserAnalytics(sortedRolls);
        } else if (sortvalue === "1") {
          const sortedRolls = reversedData.sort(
            (r1, r2) => r2.count - r1.count
          );
          setUserAnalytics(sortedRolls);
        } else {
          const sortedRolls = reversedData.sort(
            (r1, r2) => r2.count - r1.count
          );
          const limitedData = sortedRolls.slice(0, sortvalue);
          setUserAnalytics(limitedData, "res");
        }
      } else {
        setUserAnalytics(reversedData);
      }
    } else {
      const result = await userAnalyticsData();
      const reversedData = result.res.data.reverse();

      if (sortvalue !== null) {
        if (sortvalue === "0") {
          const sortedRolls = reversedData.sort(
            (r1, r2) => r1.count - r2.count
          );
          console.log(reversedData, "reversedData");
          setUserAnalytics(sortedRolls);
        } else if (sortvalue === "1") {
          const sortedRolls = reversedData.sort(
            (r1, r2) => r2.count - r1.count
          );
          setUserAnalytics(sortedRolls);
        } else {
          const sortedRolls = reversedData.sort(
            (r1, r2) => r2.count - r1.count
          );
          const limitedData = sortedRolls.slice(0, sortvalue);
          setUserAnalytics(limitedData, "res");
        }
      } else {
        setUserAnalytics(reversedData);
      }
    }
  };

  useEffect(() => {
    handleUserAnalytic();
  }, []);

  useEffect(() => {
    handleUserAnalytic();
  }, [sortvalue]);

  useEffect(() => {
    dispatch(getOverViewdata());

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const handleFilterRemove = () => {
    setActiveFiter(false);
    handleUserAnalytic();
  };

  const handleDowloadExcelData = async () => {
    const result = await downloadExcel();
    await FileSaver.saveAs(result.data, `useranalyticsdata.xls`);

    console.log(result, "Check");
  };

  return (
    <>
      <div className="container-fluid pb-5">
        <div className="row">
          <OverView setInitialDate={setInitialDate} />
          <div className="mt-4">
            <OverViewSlider
              HandleClick={HandleClick}
              greetingData={homes?.res?.data}
            />
          </div>
        </div>

        <div className="row mt-5">
          <h3 onClick={handleAnalyticsApi}>Downloads</h3>
          <SwitchCharts
            activeSwitchImage={activeSwitchImage}
            SetActiveSwitchImage={SetActiveSwitchImage}
            activeSwitchVideo={activeSwitchVideo}
            SetActiveSwitchVideo={SetActiveSwitchVideo}
            setCategory={setCategory}
          />

          {activeSwitchImage && (
            <BarCharts
              colorOne="#FF943D"
              colorTwo="#FFB171"
              chartData={chartData}
            />
          )}

          {activeSwitchVideo && (
            <BarCharts
              colorOne="#4070C5"
              colorTwo="#5989DE"
              chartData={videoChart}
            />
          )}
        </div>

        <div className="row mt-5">
          <div className="row d-flex justify-content-between align-items-center ">
            <div className="col-md-6 d-flex align-items-center exportexcel">
              <h3 className="me-5 activitymonitor">Activity Monitor</h3>
              <button className="excle-export" onClick={handleDowloadExcelData}>
                Export to Excel
              </button>
            </div>
            <div className="col-md-6 d-flex justify-content-end align-items-center activemonter">
              <DropdownButton
                id="dropdown-basic-button"
                className="me-4 px-2"
                size="sm"
                title={sortname === "" ? "Sort" : sortname}
                onSelect={(eventKey, event) => {
                  setsortvalue(eventKey);
                  setsortname(event.target.name);
                }}
              >
                <Dropdown.Item eventKey={null} name={"Sort"}>
                  Sort
                </Dropdown.Item>
                <Dropdown.Item eventKey={20} name={"Top 20"}>
                  Top 20
                </Dropdown.Item>
                <Dropdown.Item eventKey={30} name={"Top 30"}>
                  Top 30
                </Dropdown.Item>
                <Dropdown.Item eventKey={50} name={"Top 50"}>
                  Top 50
                </Dropdown.Item>
                <Dropdown.Item eventKey={0} name={"Least Active"}>
                  Least Active
                </Dropdown.Item>
                <Dropdown.Item eventKey={1} name={"Most Download"}>
                  Most Download
                </Dropdown.Item>
              </DropdownButton>
              <div className="filter_details">
                <button
                  className={`excle-export px-3 ${
                    activeFilter && "activefilter"
                  }`}
                  onClick={handleFilter}
                >
                  Filter{" "}
                  <img src={activeFilter ? acitvefilter : filter} alt="" />
                </button>
                {activeFilter && (
                  <img
                    src={cross}
                    alt=""
                    className="filter_cross cursor-pointer"
                    onClick={handleFilterRemove}
                  />
                )}
              </div>
            </div>
          </div>

          {userAnalytics && (
            <div className="col-12 mt-4 table-container">
              <ColletralTable data={userAnalytics} columns={FeedsColumns} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
