import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SwitchProduct from '../../../../Components/commoncomponent/SwitchProduct';

import newcard from "../../../../Assets/image/Analitycs/neworder.png"
import pending from "../../../../Assets/image/Analitycs/pending.png"
import delivered from "../../../../Assets/image/Analitycs/delivered.png"
import concel from "../../../../Assets/image/Analitycs/concel.png"
import concelBg from "../../../../Assets/image/Analitycs/concelBg.png"
import pendingBg from "../../../../Assets/image/Analitycs/pendingBg.png"
import newBg from "../../../../Assets/image/Analitycs/newBg.png"
import deliverdBg from "../../../../Assets/image/Analitycs/deliverdBg.png"
import AnalitycsCard from '../../../../Components/commoncomponent/AnalitycsCard';
import BarCharts from '../../../../Components/commoncomponent/BarCharts';
import AnalityicsTabs from '../../../../Components/commoncomponent/AnalityicsTabs';
import AnalitycFilter from '../../../../Components/commoncomponent/AnalitycFilter';
import ColletralTable from '../../../../Components/commoncomponent/ColletralTable';
import data from '../../../../Components/commoncomponent/data.json'


const dataAnalytic = [{
    new: "250",
    type: "New Orders",
    logo: newcard,
    cardBg: newBg,
    anltytic: "+13% from yesterday"
},
{
    new: "21",
    type: "Pending Orders",
    logo: pending,
    cardBg: pendingBg,
    anltytic: "+3% from yesterday"
},
{
    new: "76",
    type: "Delivered Orders",
    logo: delivered,
    cardBg: deliverdBg,
    anltytic: "+24% from yesterday"

},
{
    new: "8",
    type: "New Cancelled Orders",
    logo: concel,
    cardBg: concelBg,
    anltytic: "+6% from yesterday"

}

]

const AnalitycsCollateral = () => {
    const [activAll, setActiveAll] = useState(true)
    const navigate = useNavigate()

    const handleCollateralpage = (e) => {
        navigate('/rbstore')
    }





    const FeedsColumns = useMemo(
        () => [
            {
                Header: "EI Code",
                accessor: data => {
                    return (
                        <>
                            <span>{data.Standee}</span>
                        </>
                    )
                }
            },

            {
                Header: "Order ID",
                accessor: data => {
                    return (
                        <>
                            <span>{data.demension}</span>
                        </>
                    )
                }
            },

            {
                Header: "Collaterals",
                accessor: data => {
                    return (
                        <>
                            <span>{data.add_on}</span>
                        </>
                    )
                }
            },
            {
                Header: "Date",
                accessor: data => {
                    return (
                        <>
                            <span>{data.addby}</span>
                        </>
                    )
                }
            },



            {
                Header: 'Quantity',
                accessor: (data) => {
                    return (
                        <>

                            <span>{data.addby}</span>
                            {/* <span className="MuiButton-label underline ms-3"><img src={delIcon} alt="delete" /></span> */}

                        </>
                    )
                },
            },
            {
                Header: "Price",
                accessor: data => {
                    return (
                        <>
                            <span>{data.addby}</span>
                        </>
                    )
                }
            },
            {
                Header: "Status",
                accessor: data => {
                    return (
                        <>
                            <span>{data.addby}</span>
                        </>
                    )
                }
            },
        ], [])

    return (
        <div className='container-fuild'>
            <div className="row">
                <div className="productswitch">
                    <SwitchProduct
                        collaterals="productactive"
                        handleMarchant={handleCollateralpage}
                    />
                    <div className='switch-chart-btn d-flex mb-1'>
                        <div >
                            <p className={`px-4 me-1 pt-3 pb-3 ${activAll && "switch-active-btn"} switch-image-btn`} > All</p>
                        </div>

                        <div>
                            <p className={`px-4 mx-1 pt-3 pb-3  switch-video-btn`} >  Custom</p>

                        </div>

                    </div>

                </div>
            </div>
            <div className="row pt-5">
                {dataAnalytic && dataAnalytic.map((item, i) => (
                    <div className="col-3">
                        <AnalitycsCard item={item} />
                    </div>
                ))}

            </div>

            <div className="row mt-5">

                <BarCharts color="#4070C5" />

            </div>



            <div className="row ">

                <div className="col-12 mt-5">
                    <AnalityicsTabs />
                    <AnalitycFilter />
                    <ColletralTable data={data} columns={FeedsColumns} />
                </div>

            </div>


        </div>
    )
}

export default AnalitycsCollateral