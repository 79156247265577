import React from 'react'

const SwitchProduct = ({ merchant, collaterals, handleCollateral, handleMarchant }) => {


    return (
        <div className="product-service d-flex">
            <div className={`${merchant} merchandise-libary `} onClick={() => handleMarchant()}>
                <h4 className="pt-4 pb-4 px-2">Merchandise Library</h4>
            </div>
            <div className={`collaterals-lab  ${collaterals}`} onClick={() => handleCollateral()}>
                <h4 className="pt-4 pb-4 ps-2 pe-2">Collaterals</h4>
            </div>
        </div>
    )
}

export default SwitchProduct
