import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { ModalContext } from '../Context';
import { toast } from 'react-toastify';
import { deletePeople } from '../Redux/master/masterSlice'
import { deleteCollateraltype, deleteLang, deleteTags } from '../Utils/services';

const DeleteLang = ({ data, getLanguagedata }) => {
    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;

    const handleDeleteApiData = async () => {
        const result = await deleteLang(
            {
                "languageID": data.languageID,
            }
        )
        toast.success(result.apiMessage);
        getLanguagedata()
        closeModal()

    }
    console.log(data, "data")

    return (
        <div className='add-collateral-type'>
            <h3 className='mb-4 delete-heading'>Are you sure you want to delete this Language?</h3>
            <div className='mt-3 d-flex justify-content-between'>
                <button className='concel-btn-modal' onClick={closeModal}>Cancel</button>
                <button className='save-btn-modal' onClick={() => handleDeleteApiData()} >Confirm</button>
            </div>
        </div>
    )
}

export default DeleteLang;