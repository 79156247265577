import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('Fullname is required'),
    lastname: Yup.string().required('lastname is required'),
    contact: Yup.string().required('contact is required'),
    email: Yup.string().required('email is required'),
    authority: Yup.string().required('authority is required')

});

export const validationTestSchema = Yup.object().shape({
    firstname: Yup.string().required('name is required'),
    contact: Yup.string().min(10).max(10).required('contact is required'),
    lang: Yup.string().required('langauge is required')
})


export const validationPasswordSchema = Yup.object().shape({
    oldpassword: Yup.string()
        .required('old Password is required'),
    password: Yup.string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters'),
    confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password')], 'Passwords must match')

});
