
import React, { useContext, useEffect, useMemo, useState } from 'react'
import Button from '../../../Components/commoncomponent/Button'
import ColletralTable from '../../../Components/commoncomponent/ColletralTable'
import InputSearch from '../../../Components/commoncomponent/InputSearch'
import SwitchButtonTwo from '../../../Components/commoncomponent/SwitchButtonTwo'
import data from '../../../Components/commoncomponent/data.json'
import delIcon from "../../../Assets/image/icons/delete.png"
import { ModalContext } from '../../../Context'
import AddCollateralType from '../../../ModalTemplate/AddCollateralType'
import EditCollateralType from '../../../ModalTemplate/EditCollateralType'
import Delete from '../../../ModalTemplate/Delete'
import { getListOfCollateraltype } from '../../../Utils/services'
import Spinner from '../../../Components/commoncomponent/Spinner'
import Pagination from '../../../Components/commoncomponent/Pagination'




const CollateralType = () => {
    const [collateralList, setCollateralList] = useState([])
    const [loading, setLoading] = useState(true)
    const [search, setSearchVal] = useState("")




    const modalContext = useContext(ModalContext);
    const { handleModalData } = modalContext;





    const handleEdit = (data) => {
        const addCollateral = <EditCollateralType data={data} collateralList={collateralList} getCollateraldata={getCollateraldata} />
        handleModalData(addCollateral, "md")
    }

    const handleDelete = (data) => {
        const addCollateral = <Delete data={data} title="Are you sure you want to delete this Collateral type?" getCollateraldata={getCollateraldata} />
        handleModalData(addCollateral, "md")
    }

    const handleAdd = () => {
        const addCollateral = <AddCollateralType getCollateraldata={getCollateraldata} />
        handleModalData(addCollateral, "md")
    }


    const getCollateraldata = async () => {

        try {
            setLoading(true)
            const result = await getListOfCollateraltype();
            if (result.status) {

                setLoading(false)
                setCollateralList(result.data)

            }

        } catch (error) {

        }

    }


    useEffect(() => {
        getCollateraldata()

    }, [])





    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Collateral Name",
                accessor: data => {
                    return (
                        <>
                            <span>{data.collateralType}</span>
                        </>
                    )
                }
            },

            {
                Header: "Size",
                accessor: data => {
                    return (
                        <>
                            <span>{data.collateralDimesion && data.collateralDimesion.map(u => u).join(' , ')}</span>
                        </>
                    )
                }
            },

            {
                Header: "Add on",
                accessor: data => {
                    return (
                        <>
                            <span>{data.addedOn}</span>
                        </>
                    )
                }
            },
            {
                Header: "Add by",
                accessor: data => {
                    return (
                        <>
                            <span>{data.addedBy}</span>
                        </>
                    )
                }
            },


            {
                Header: 'Action',
                accessor: (data) => {
                    return (
                        <>
                                <div className='d-flex align-items-center justify-content-center'>
                            <span className="MuiButton-label editbtn" onClick={() => handleEdit(data)}>Edit</span>
                            <span className="MuiButton-label underline ms-3" onClick={() => handleDelete(data)}><img src={delIcon} alt="delete" className='cursor-pointer' /></span>
                            </div>
                        </>
                    )
                },
            }

        ], [collateralList])







    return (
        <div className='container-fuild'>
            <div className="row">
                <div className='d-flex justify-content-between align-items-center'>
                    <InputSearch setSearchVal={setSearchVal} />
                    <div className='d-flex align-items-center '>

                        <Button name="Add +" handleAdd={handleAdd} />

                    </div>

                </div>

            </div>

            {
                loading ? <Spinner /> :
                    <div className="row mt-2" style={{overflowY:'scroll'}}>
                        <div className="col-12 mb-4">
                            <ColletralTable data={collateralList.filter((val) => {
                                if (search == "") {
                                    return val;
                                }
                                else if (val && val.collateralType && val.collateralType.toLowerCase().includes(search.toLowerCase())) {
                                    return val
                                }

                            })} columns={FeedsColumns} />
                        </div>
                    </div>
            }




        </div>
    )
}

export default CollateralType