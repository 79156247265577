import React, { useContext } from 'react'
import { ModalContext } from '../Context';
import { toast } from 'react-toastify';
import { delArticle } from '../Utils/services';

const DeleteArticle = ({ item, getArticleApi, categoryValue }) => {
    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;

    const handleDeleteApiData = async () => {
        const result = await delArticle(
            {
                "articlesID": item.articlesID,
            }
        )
        toast.success(result.apiMessage);
        getArticleApi(categoryValue)
        closeModal();

    }
    // console.log(data, "data")

    return (
        <div className='add-collateral-type'>
            <h3 className='mb-4 delete-heading'>Are you sure you want to delete this Article?</h3>
            <div className='mt-3 d-flex justify-content-between'>
                <button className='concel-btn-modal' onClick={closeModal}>Cancel</button>
                <button className='save-btn-modal' onClick={() => handleDeleteApiData()} >Confirm</button>
            </div>
        </div>
    )
}

export default DeleteArticle