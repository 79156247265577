import axios from 'axios'

const API_URL = 'http://localhost:4000/api/users/'
// export const BASE_URL = "https://renewbuy.techtonic.asia/api"; //devlopment//
export const BASE_URL = "https://collaterals.api.renewbuy.com/api"; //production//

axios.defaults.baseURL = BASE_URL;

// Register user
const register = async (userData) => {
    const response = await axios.post(API_URL, userData)

    if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}

// Login user
const login = async (userData) => {
    const response = await axios.post('auth/login', userData)

    if (response.data) {

        localStorage.setItem("token", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}

// Logout user
const logout = () => {

    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('user')
}

const authService = {
    register,
    logout,
    login,
}

export default authService
