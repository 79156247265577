import React, { useEffect } from 'react'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'
import Layout from './layout'
import Login from '../Pages/Auth/Login'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth)

  useEffect(() => {

    if (!user) {
      navigate('/login')
    }
   
  }, [user,navigate])



  return (
    <>
   
      <ToastContainer position="top-right" autoClose={3000} />
      <Routes>
        <Route exact path="/login" element={<Login />} />
        {/* <Route exact path="/password/forgot" element={<ForgotPassword />} />
        <Route exact path="/password/reset/:id" element={<ResetPassword />} /> */}
        {user ? (
          <Route path="/*" element={<Layout />} />
        ) : (
          <Route path="/login" element={<Login />} />
        )}
        <Route
          path="*"
          element={<Navigate to={user ? '/' : '/login'} />}
        />
      </Routes>
    </>
  )
}

export default App
