import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import bgHeart from "../../Assets/image/RenewVerse/heart.png"
import { ModalContext } from '../../Context';
import DeleteArticle from '../../ModalTemplate/DeleteArticle'

const ContentCard = ({ item, getArticleApi, categoryValue }) => {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const navigate = useNavigate()


    const HandleActionModal = (type) => {
        const tabsValue = type;
        const DELARTICAL = <DeleteArticle getArticleApi={getArticleApi} item={item} categoryValue={categoryValue} />
        switch (tabsValue) {
            case "DELETE":
                handleModalData(DELARTICAL, "cs");
                break;
            case "VIEW":
                handleModalData(DELARTICAL, "sm");
                break;

            default:
                handleModalData(DELARTICAL, "lg");
                break;
        }
    }


    const handleEdit = (item) => {
        // console.log(, "id")
        navigate(`/content-hub/edit-article/${item?.articlesID}`)
    }
    console.log(item?.articlesDesc, "data")
    return (
        <>
            <div className="card content-card ">
                <div className='card-image'>
                    <div className='card-height'>
                        <img src={item?.imgPath || item?.videoThumb || bgHeart} alt="" />
                    </div>

                    <div className='content-details'>
                        <div >
                            <p className='text-center overflowhide' >
                                <div dangerouslySetInnerHTML={{ __html: item?.articlesDesc || item?.videoDescription }} />
                            </p>
                        </div>


                    </div>
                </div>

            </div>
            <div className='d-flex justify-content-around py-3'>
                <button className='articledelbtn cursur-pointer' onClick={() => HandleActionModal("DELETE")}>Delete</button>
                <button className='article-edit' onClick={() => handleEdit(item)} >Edit</button>
            </div>



        </>
    )
}

export default ContentCard