import { Link } from '@material-ui/core'
import React from 'react'
import storeicon from "../../Assets/image/Rbstore/collertal.png"
import ProductCollateralsCards from './ProductCollateralsCards'

const ProductCollaterals = ({ title, }) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className='ps-2 d-flex justify-content-between mt-4 collateral-product-type'>
                        <span>{title}</span>
                        <Link to="#">See More</Link>
                    </div>
                </div>


            </div>
            <div className="row  mt-5 ">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-5">
                    <ProductCollateralsCards collertalimg={storeicon} />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-5">
                    <ProductCollateralsCards collertalimg={storeicon} />
                </div>

            </div>
        </>
    )
}

export default ProductCollaterals