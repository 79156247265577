import React, { useContext } from 'react'
import { TestColleteral, TestVideoColleteral } from '../Utils/services'
import FileSaver from 'file-saver'
import { validationTestSchema } from '../Components/commoncomponent/validation'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ModalContext } from '../Context'
import { useState } from 'react'



const TestVideoModal = ({ item }) => {
    const [loading, setLoading] = useState(false)
    const [loadingtwo, setLoadingtwo] = useState(false)
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(validationTestSchema) });
    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;

    const handlePdfCollateral = async (data) => {
        setLoading(true)
        const result = await TestVideoColleteral({
            collateralID: item.collatID,
            languageID: data.lang,
            name: data.firstname,
            phoneNumber: data.contact,
        })
        const type = result.headers["content-type"].split("/")
        const typeofcoll = type[type.length - 1]
        setLoading(false)
        await FileSaver.saveAs(result.data, `${item.titleName}` + "." + `${typeofcoll}`);
        closeModal()
    }


    // const handleJpegCollateral = async (data) => {
    //     setLoadingtwo(true)
    //     const result = await TestColleteral({
    //         collateralID: item.collatID,
    //         languageID: data.lang,
    //         name: data.firstname,
    //         contactNumber: data.contact,
    //         type: 2,
    //     })

    //     const type = result.headers["content-type"].split("/")
    //     const typeofcoll = type[type.length - 1]
    //     setLoadingtwo(false)
    //     await FileSaver.saveAs(result.data, `${item.titleName}` + "." + `${typeofcoll}`);
    //     closeModal()
    // }


    // console.log(item.collatData[0], "check")


    return (
        <div className='test-modal'>
            <h3 className='text-center'> Test Collaterals</h3>
            <div className='d-flex justify-content-between mt-4'>
                <div>
                    <p className='ps-2 pb-2'>Name</p>
                    <input type="text" placeholder='Name' {...register('firstname')} className={`form-control ${errors.firstname ? 'is-invalid' : ''}`} />
                    <p className='text-danger ps-2 pt-1'>{errors.firstname?.message}</p>
                </div>
                <div>
                    <p className='ps-2 pb-2' >Contact Number</p>
                    <input type="number" placeholder='Contact Number' {...register('contact')} className={`form-control ${errors.contact ? 'is-invalid' : ''}`} />
                    {errors.contact?.message === "contact must be at least 6 characters" &&
                        <p className='ps-2 pt-1 text-danger '>contact must be at least 6 number </p>}
                    {errors.contact?.message === "contact must be at most 12 characters" &&
                        <p className='ps-2 pt-1 text-danger'>contact must be at most 12 number </p>}
                </div>
            </div>
            <div className='mt-3 '>
                <p className='ps-2 pb-2'>Select Language</p>
                <div className='mt-1 add-people-btn'>
                    <ul class="ux-btn-group" >
                        {item && item?.languages?.map((items) => (

                            <li>
                                <input type="radio" id={`${items.langName}`} class="ux-btn-group-item " value={items.languageID} name="option" {...register("lang")} />
                                <label for={`${items.langName}`}>{items.langName}</label>
                            </li>
                        ))}

                    </ul>
                    {errors?.lang?.type === "typeError" && <p className='ps-2 text-danger'>language is required</p>}


                </div>
            </div>
            <div className='mt-3 d-flex justify-content-center'>

                <button className='save-btn-modal' onClick={handleSubmit(handlePdfCollateral)} >
                    {
                        loading ?
                            <>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </>
                            : "Download Video "
                    }
                </button>


            </div>
        </div >
    )
}

export default TestVideoModal