import React, { useState } from 'react'



const SwitchButtonTwo = ({ setAll, setCustom, all, custom }) => {

  const handleAll = () => {
    setAll(true)
    setCustom(false)

  }

  const handleCustom = () => {
    setAll(false)
    setCustom(true)

  }

  return (
    <>
      <div className="switchbutton">
        <button className={`${all && 'switchactive'}`} onClick={handleAll}  >Image</button>
        <button className={`${custom && 'switchactive'}`} onClick={handleCustom}>Video</button>
      </div>
    </>
  )
}

export default SwitchButtonTwo