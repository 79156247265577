import React, { useEffect } from 'react'
import Button from '../../../Components/commoncomponent/Button'

import InputSearch from '../../../Components/commoncomponent/InputSearch'
import SwitchButtonTwo from '../../../Components/commoncomponent/SwitchButtonTwo'
import logo from "../../../Assets/image/Collateral/collateral.png"
import MasterCard from '../../../Components/commoncomponent/MasterCard'
import { getListOfMaster, getListOfMasterVideo } from '../../../Utils/services'
import { useState } from 'react'
import Spinner from '../../../Components/commoncomponent/Spinner'
import VideoMasterCard from '../../../Components/commoncomponent/VideoMasterCard'
import Pagination from '../../../Components/commoncomponent/Pagination'
import Sort from '../../../Components/commoncomponent/Sort'



const Master = () => {
    const [allImage, setAllImage] = useState(true);
    const [allVideo, setAllVideo] = useState(false)
    const [masterList, setMasterList] = useState([]);
    const [masterVideoList, serMasterVideoList] = useState([])
    const [loading, setLoading] = useState(true)
    const [search, setSearchVal] = useState("")
    const [count, setCount] = useState(0);
    const [totalpage, setTotalpage] = useState(0)

    const HandleMaterList = async () => {
        const result = await getListOfMaster(count)
        setLoading(false)
        const _newData = result.data.filter((item) => item.collateralType !== "video")
        setMasterList(_newData)
        setTotalpage(result.data[0].totalData)
        // console.log(result.data[0].totalData, "check")
    }

    const HandleMasterVideo = async () => {
        const result = await getListOfMasterVideo()
        serMasterVideoList(result.data)
    }

    useEffect(() => {
        HandleMaterList()
        HandleMasterVideo()
    }, [])

    useEffect(() => {
        HandleMaterList()
        HandleMasterVideo()
    }, [count])


    return (
        <div className='container-fuild'>
            <div className="row">
                <div className='d-flex justify-content-between align-items-center masterflex'>
                    <InputSearch setSearchVal={setSearchVal} style={{ display: 'flex', justifyContent: 'center' }} />
                    <div className='d-flex align-items-center justify-content-between masterpagination'>
                        <Pagination totalpage={totalpage} setCount={setCount} count={count} collateralList={masterList} />
                        <div className='me-4 switchtwouton'>

                            <SwitchButtonTwo custom={allVideo} setCustom={setAllVideo} all={allImage} setAll={setAllImage} />
                        </div>

                        <Sort setSearchVal={setSearchVal} />

                    </div>

                </div>

            </div>
            {
                allImage &&
                <>
                    {loading ? <Spinner /> : <React.Fragment>

                        <div className="row mt-5">
                            {
                                masterList?.filter((val) => {
                                    if (search == "") {
                                        return val;
                                    }
                                    else if (val && val.titleName && val.titleName.toLowerCase().includes(search.toLowerCase())) {
                                        return val
                                    }
                                    else if (val && val.collateralType && val.collateralType.toLowerCase().includes(search.toLowerCase())) {
                                        return val
                                    }

                                }).map((item, index) => (


                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  mb-4" key={index}>
                                        <MasterCard collertalimg={logo} item={item} HandleMaterList={HandleMaterList} />

                                    </div>


                                ))
                            }
                        </div>



                    </React.Fragment>}

                </>
            }


            {
                allVideo &&

                <>
                    {loading ? <Spinner /> : <React.Fragment>

                        <div className="row mt-5">
                            {
                                masterVideoList?.filter((val) => {
                                    if (search == "") {
                                        return val;
                                    }
                                    else if (val && val.titleName && val.titleName.toLowerCase().includes(search.toLowerCase())) {
                                        return val
                                    }
                                    else if (val && val.collateralType && val.collateralType.toLowerCase().includes(search.toLowerCase())) {
                                        return val
                                    }

                                }).map((item, index) => (

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4" key={index}>
                                        <VideoMasterCard collertalimg={logo} item={item} HandleMaterList={HandleMasterVideo} />

                                    </div>

                                ))
                            }
                        </div>


                    </React.Fragment>}

                </>
            }





        </div>
    )
}

export default Master