import React, { useContext, useState } from 'react'
import { useForm } from "react-hook-form";
import { ModalContext } from '../Context';
import { addLanguage } from '../Utils/services';

const AddLanguage = ({ getLanguagedata }) => {
    const [addLanguages, setAddLanguage] = useState("")
    const [loading, setLoading] = useState(false)

    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;




    const handleAddLanguage = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const result = await addLanguage({
                "name": addLanguages,
            });
            if (result.status) {
                setLoading(false)
                getLanguagedata()
                closeModal()

            }

        } catch (error) {
            setLoading(false)
        }
        console.log("form")
    }

    return (
        <form action="" onSubmit={handleAddLanguage}>
            <div className='add-collateral-type'>
                <h3 className='mb-4'>Add Language</h3>
                <input type="text" placeholder='English' value={addLanguages} onChange={(e) => setAddLanguage(e.target.value)} required={true} />

                <div className='mt-3 d-flex justify-content-between'>
                    <button className='concel-btn-modal'>Cancel</button>
                    <button type='submit' className='save-btn-modal' disabled={loading}>
                        {
                            loading ?
                                <>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </>
                                : "Save"
                        }
                    </button>
                </div>
            </div>
        </form>
    )
}

export default AddLanguage