import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ModalContext } from '../../Context'
import { getProfile, reset } from '../../Redux/Profile/profileSlice'
import CommonModal from '../CommonModal'
import Header from './Header'
import MainWrapper from './MainWrapper'
import Sidenav from './Sidenav'


const Layout = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("");
  const [data, setData] = useState({});
  const [toggle,setToggle]=useState(true)
  const [toggle2,setToggle2]=useState(true)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth)
  const { profile, isLoading, isError, message } = useSelector(
    (state) => state.profile
  )

  useEffect(() => {
    if (isError) {
      console.log(message)
    }

    if (!user) {
      navigate('/login')
    }

    dispatch(getProfile())


  }, [dispatch])

  const handletoggle=()=>{
    if(toggle2){
      setToggle(!toggle)
    }
    
  }
  
// <-----------code to get the current screen size and chnage the toggle of sidebar------------>
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

  // const width = window.innerWidth;
  useEffect(()=>{
      if(windowSize.innerWidth<963){
        setToggle(false)
        setToggle2(true)
      }
      else if(windowSize.innerWidth>963){
        setToggle(true)
        setToggle2(false)
      }
  },[windowSize.innerWidth])

// <--------End------->

  const handleModalData = (data, size = "xl") => {
    setModalData(data);
    setModalSize(size);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <ModalContext.Provider
        value={{
          handleModalData,
          closeModal,
          setData,
          data,
          isModalOpen,
          modalData,
        }}
      >
      <Header profile={profile} handletoggle={handletoggle}/>
      <div className="Layout d-flex ">
        <div className={toggle?'side-nav':'side-nav1'}>
          <Sidenav toggle={toggle} handletoggle={handletoggle}/>
        </div>
        <div className={toggle?"page-container":"page-container1"}>
          <MainWrapper />
        </div>
      </div>

        <CommonModal
          handleModalData={handleModalData}
          isModalOpen={isModalOpen}
          modalData={modalData}
          modalSize={modalSize}
          closeModal={closeModal}
        />
      </ModalContext.Provider>

    </>
  )
}

export default Layout