import navicon from "../../Assets/image/Logos/navicon.svg"
import navicon2 from "../../Assets/image/Logos/home.png"
import collateral2 from "../../Assets/image/Logos/collateral.png"
import collateral from "../../Assets/image/Logos/collateralactive.png"
import master from "../../Assets/image/Logos/masteractive.png"
import master2 from "../../Assets/image/Logos/master.png"
import people from "../../Assets/image/Logos/peopleactive.png"
import people2 from "../../Assets/image/Logos/people.png"
import store from "../../Assets/image/Logos/storeactive.png"
import store2 from "../../Assets/image/Logos/store.png"
import renwverse from "../../Assets/image/Logos/renwverseactive.png"
import renwverse2 from "../../Assets/image/Logos/rewverse.png"
import profile from "../../Assets/image/Logos/profileactive.png"
import profile2 from "../../Assets/image/Logos/profile.png"
import logout2 from "../../Assets/image/Logos/logout.png";

const SIDEBAR_UL = [

    {
        id: 1,
        label: 'Home',
        ref: 'dash',
        src1: navicon,
        src: navicon2,
        path: '/'
    },

    {
        id: 2,
        label: 'Collaterals',
        ref: 'collaterals',
        src1: collateral,
        src: collateral2,
        path: '#',
        sublist: [


            {
                id: 1,
                label: 'Approval Pending',
                path: '/approval-pending',
                ref: 'user',

            },

            {
                id: 2,
                label: 'Approved',
                path: 'approved',
                ref: 'user',
            },


            {
                id: 3,
                label: 'Declined',
                path: 'declined',
                ref: 'user',
            },

            {
                id: 4,
                label: 'Deactivated',
                path: 'deactivated',
                ref: 'user',
            }



        ]
    },

    {
        id: 3,
        label: 'Master',
        ref: 'master',
        src1: master,
        src: master2,
        path: '/master',
        sublist: [


            {
                id: 1,
                label: 'Add New',
                path: '/add-new',
                ref: 'master',

            },

            {
                id: 2,
                label: 'Collateral Type',
                path: 'collateral-type',
                ref: 'user',
            },


            {
                id: 3,
                label: 'Tags',
                path: 'tag',
                ref: 'user',
            },

            {
                id: 4,
                label: 'languages',
                path: 'languages',
                ref: 'user',
            }



        ]
    },

    {
        id: 4,
        label: 'People',
        ref: 'people',
        src1: people,
        src: people2,
        path: '/people',
    },
    {
        id: 5,
        label: 'RB Store',
        ref: 'rbstore',
        src1: store,
        src: store2,
        path: '/rbstore',
        sublist: [

            {
                id: 1,
                label: 'product',
                path: '/product',
                ref: 'rbstore',

            }

        ]
    },
    {
        id: 7,
        label: 'RenewVerse',
        ref: 'renewverse',
        src1: renwverse,
        src: renwverse2,
        path: '#',
        sublist: [

            {
                id: 1,
                label: 'Content Hub',
                path: '/content-hub',
                ref: 'content-hub',

            },
            {
                id: 2,
                label: 'Insurer Brochures',
                path: '/insurer-brochures',
                ref: 'insurer-brochures',

            }

        ]
    },

    {
        id: 6,
        label: 'Profile',
        ref: 'billing',
        src1: profile,
        src: profile2,
        path: '/profile',
    },

    {
        label: 'Logout',
        src1: logout2,
        src: logout2,
        ref: 'support',
        path: '#'
    }
]

export default SIDEBAR_UL;