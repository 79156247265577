
import React, { useContext, useEffect, useMemo, useState } from 'react'
import Button from '../../../Components/commoncomponent/Button'
import ColletralTable from '../../../Components/commoncomponent/ColletralTable'
import InputSearch from '../../../Components/commoncomponent/InputSearch'
import SwitchButtonTwo from '../../../Components/commoncomponent/SwitchButtonTwo'
import data from '../../../Components/commoncomponent/data.json'
import delIcon from "../../../Assets/image/icons/delete.png"
import { ModalContext } from '../../../Context'
import AddLanguage from '../../../ModalTemplate/AddLanguage'
import EditLanguage from '../../../ModalTemplate/EditLanguage'
import Delete from '../../../ModalTemplate/Delete'
import { getListOflanguage } from '../../../Utils/services'
import Spinner from '../../../Components/commoncomponent/Spinner'
import DeleteLang from '../../../ModalTemplate/DeleteLang'



const Language = () => {
    const [languageList, setLanguageList] = useState([])
    const [loading, setLoading] = useState(true)
    const [noData, setNodata] = useState("")
    const [search, setSearchVal] = useState("")


    const [language, setLanguage] = useState(null)


    const modalContext = useContext(ModalContext);
    const { handleModalData } = modalContext;


    const handleEdit = (data) => {
        console.log(data)

        const addCollateral = <EditLanguage getLanguagedata={getLanguagedata} data={data} />
        handleModalData(addCollateral, "md")
    }
    const handleDelete = (data) => {
        const addCollateral = <DeleteLang getLanguagedata={getLanguagedata} data={data} />
        handleModalData(addCollateral, "md")
    }





    const getLanguagedata = async () => {

        try {
            setLoading(true)
            const result = await getListOflanguage();
            if (result.status) {
                setLoading(false)
                setLanguageList(result.data)
            }
            else {
                setLoading(false)
                setNodata("No data")
            }

        } catch (error) {
            console.log(error.message)
        }


    }


    useEffect(() => {
        getLanguagedata()

    }, [])



    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Language",
                accessor: data => {
                    return (
                        <>
                            <span>{data.langName}</span>
                        </>
                    )
                }
            },

            {
                Header: "Usage",
                accessor: data => {
                    return (
                        <>
                            <span>{data.usageCount}</span>
                        </>
                    )
                }
            },

            {
                Header: "Add on",
                accessor: data => {
                    return (
                        <>
                            <span>{data.addedOn}</span>
                        </>
                    )
                }
            },
            {
                Header: "Add by",
                accessor: data => {
                    return (
                        <>
                            <span>{data.addedBy}</span>
                        </>
                    )
                }
            },


            {
                Header: 'Action',
                accessor: (data) => {
                    return (
                        <>
                            <div className='d-flex  align-items-center justify-content-center'>
                                <span className="MuiButton-label editbtn" onClick={() => handleEdit(data)}>Edit</span>
                                <span className="MuiButton-label underline ms-3" onClick={() => handleDelete(data)}><img src={delIcon} alt="delete" className='cursor-pointer' /></span>
                            </div>
                        </>
                    )
                },
            }

        ], [languageList])

    const handleAdd = () => {
        const addCollateral = <AddLanguage getLanguagedata={getLanguagedata} />
        handleModalData(addCollateral, "md")
    }


    return (
        <div className='container-fuild'>
            <div className="row">
                <div className='d-flex justify-content-between align-items-center'>
                    <InputSearch setSearchVal={setSearchVal} />
                    <div className='d-flex align-items-center '>
                        <Button name="Add +" handleAdd={handleAdd} />

                    </div>

                </div>

            </div>

            {loading ? <Spinner />
                : <div className="row mt-2" style={{ overflowY: 'scroll' }}>
                    <div className="col-12 mb-4">
                        <ColletralTable data={languageList.filter((val) => {
                            if (search == "") {
                                return val;
                            }
                            else if (val && val.langName && val.langName.toLowerCase().includes(search.toLowerCase())) {
                                return val
                            }

                        })} columns={FeedsColumns} />
                        <h4 className='text-center m-4'>{noData}</h4>
                    </div>
                </div>

            }

        </div>
    )
}

export default Language;