import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import profileService from './profileService'




const initialState = {
    master: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

// Get user Profile
export const getProfile = createAsyncThunk(
    'profile/getprofile',
    async (_, thunkAPI) => {
        try {

            const token = thunkAPI.getState().auth.user.accessToken
            console.log(token, "check token")
            return await profileService.getProfile(token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// edit tag goals
export const editProfile = createAsyncThunk(
    'profile/editProfile',
    async (data, thunkAPI) => {
        try {

            const token = thunkAPI.getState().auth.user.accessToken
            return await profileService.editProfile(data, token)


        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)







export const profleSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder

            .addCase(getProfile.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.profile = action.payload.data
                // console.log(action.payload.data, "data check")
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload

            })

            .addCase(editProfile.pending, (state) => {
                state.isLoading = true
            })
            .addCase(editProfile.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.editprofile = action.payload.data
            })
            .addCase(editProfile.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload

            })




    },
})

// console.log(goalSlice, "check")

export const { reset } = profleSlice.actions;
export default profleSlice.reducer;