import * as React from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Box from '@mui/material/Box';








const InnerHeader = ({ handleChange, value, TabBar, handleClick }) => {


    return (
        <Box sx={{ width: '100%' }}>

            <Tabs indicatorColor="secondary" value={value} onChange={handleChange} aria-label="scrollable force tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile TabIndicatorProps={{ style: { background: "#FF943D", height: "4px" } }}>

                {TabBar?.map((item, index) => (
                    <Tab

                        label={item.label} {...a11yProps(index)} onClick={() => handleClick(item)} />
                ))}
            </Tabs>

   
        </Box>
    );
}

export default InnerHeader;


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}