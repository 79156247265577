import React from 'react'
import Button from '../../../Components/commoncomponent/Button'

import InputSearch from '../../../Components/commoncomponent/InputSearch'
import SwitchButtonTwo from '../../../Components/commoncomponent/SwitchButtonTwo'
import logo from "../../../Assets/image/Collateral/collateral.png"
import CollateralApprovedCard from '../../../Components/commoncomponent/CollateralApprovedCard'
import { getApprovedCollateral, getApprovedCollateralVideo } from '../../../Utils/services'
import { useEffect } from 'react'
import { useState } from 'react'
import Spinner from '../../../Components/commoncomponent/Spinner'
import VideoApproved from '../../../Components/commoncomponent/VideoApproved'
import Sort from '../../../Components/commoncomponent/Sort'
import Pagination from '../../../Components/commoncomponent/Pagination'


const CollateralApproved = () => {
    const [allImage, setAllImage] = useState(true);
    const [allVideo, setAllVideo] = useState(false)
    const [search, setSearchVal] = useState("")
    const [approveList, setApproveList] = useState([]);
    const [approveVideoList, setApproveVideoList] = useState([])
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0);
    const [totalpage, setTotalpage] = useState(0)

    const handleAprovedcolletarl = async () => {
        try {

            const result = await getApprovedCollateral(count)

            const _newData = result.data.filter((item) => item.collateralType !== "video")
            setTotalpage(result.data[0].totalData)
            setLoading(false)
            setApproveList(_newData)

        } catch (error) {

        }

    }

    const handleAprovedcolleteralVideo = async () => {
        const resultVideo = await getApprovedCollateralVideo()
        console.log(resultVideo, "videoData")

        setApproveVideoList(resultVideo.data)

    }

    useEffect(() => {
        handleAprovedcolletarl()
        handleAprovedcolleteralVideo()
    }, [count])

    return (
        <>
            <div className='container-fuild'>
                <div className="row">
                    <div className='d-flex justify-content-between align-items-center masterflex'>
                        <InputSearch setSearchVal={setSearchVal} style={{ display: 'flex', justifyContent: 'center' }} />
                        <div className='d-flex align-items-center masterpagination'>
                            <Pagination totalpage={totalpage} setCount={setCount} count={count} collateralList={approveList} />
                            <div className='me-4'>
                                <SwitchButtonTwo custom={allVideo} setCustom={setAllVideo} all={allImage} setAll={setAllImage} />
                            </div>
                            <Sort setSearchVal={setSearchVal} />
                        </div>
                    </div>


                </div>
                {
                    allImage &&
                    <div className="row mt-5"  >
                        {
                            loading ? <Spinner /> : <React.Fragment>

                                {
                                    approveList?.filter((val) => {
                                        if (search == "") {
                                            return val;
                                        }
                                        else if (val && val.titleName && val.titleName.toLowerCase().includes(search.toLowerCase())) {
                                            return val
                                        }
                                        else if (val && val.collateralType && val.collateralType.toLowerCase().includes(search.toLowerCase())) {
                                            return val
                                        }
                                        else if (val && val?.tagName[0] && val.tagName[0].toLowerCase().includes(search.toLowerCase())) {
                                            return val
                                        }

                                    }).map((item, index) => {
                                        return (
                                            <>
                                                {item?.collatData[0]?.thumbImage &&
                                                    <div className="col-lg-6 col-md-12 mb-4" key={index}>
                                                        <CollateralApprovedCard collertalimg={logo} item={item} handleAprovedcolletarl={handleAprovedcolletarl} />

                                                    </div>

                                                }

                                            </>
                                        )
                                    })
                                }

                            </React.Fragment>
                        }

                    </div>
                }

                {
                    allVideo &&
                    <div className="row mt-5"  >
                        {
                            loading ? <Spinner /> : <React.Fragment>

                                {
                                    approveVideoList?.filter((val) => {
                                        if (search == "") {
                                            return val;
                                        }
                                        else if (val && val.titleName && val.titleName.toLowerCase().includes(search.toLowerCase())) {
                                            return val
                                        }
                                        else if (val && val.collateralType && val.collateralType.toLowerCase().includes(search.toLowerCase())) {
                                            return val
                                        }
                                        else if (val && val?.tagName[0] && val.tagName[0].toLowerCase().includes(search.toLowerCase())) {
                                            return val
                                        }

                                    }).map((item, index) => (

                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4" key={index}>

                                            <VideoApproved collertalimg={logo} item={item} handleAprovedcolletarl={handleAprovedcolleteralVideo} />
                                        </div>
                                    ))
                                }

                            </React.Fragment>
                        }
                    </div>
                }
            </div>
        </>
    )
}

export default CollateralApproved 