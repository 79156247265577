import React, { useEffect, useState } from 'react';
import ArticleCard from '../../../Components/commoncomponent/ArticleCard';
import ContentCard from '../../../Components/commoncomponent/ContentCard';
import InnerCuntantHeader from '../../../Components/commoncomponent/InnerCuntantHeader';
import InnerHeader from '../../../Components/commoncomponent/InnerHeader';
import SwitchContent from '../../../Components/commoncomponent/SwitchContent';
import TabPanel from '../../../Components/commoncomponent/TabPanel';
import { getGetArtcleTags, getGetArticle, getGetBroucherCategory, getVideoContent } from '../../../Utils/services';

const ContentHub = () => {
    const [value, setValue] = React.useState(0);
    const [categoryValue, setCategoryValue] = useState(0)
    const [activeArticles, setActiveArticle] = useState(true)
    const [activeVideo, setActiveVideo] = useState(false)
    const [articleList, setArticleList] = useState([])
    const [videoList, setVideoList] = useState([])
    const [category, setCategory] = useState([]);

    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getArticleApi = async (category) => {
        if (category == 0) {
            console.log(category, "check else")
            const result = await getGetArticle()
            setArticleList(result.data)
        }
        else {
            console.log(category, "check value")
            const formData = new FormData();
            formData.append("categoryID", category);
            const result = await getGetArticle(formData)
            setArticleList(result.data)
        }
    }

    const getVideoApi = async (category) => {
        if (category == 0) {
            console.log(category, "check else")
            const result = await getVideoContent()
            setVideoList(result.data)
        }
        else {
            console.log(category, "check value")
            const formData = new FormData();
            formData.append("categoryID", category);
            const result = await getVideoContent(formData)
            setVideoList(result.data)
        }
    }

    useEffect(() => {
        if (category)
            getArticleApi(0)
        getVideoApi(0)

    }, [category])

    const handleClick = (e) => {
        getArticleApi(e?.value)
        getVideoApi(e?.value)
        setCategoryValue(e?.value)
    }

    const GetCategoryApi = async () => {
        const result = await getGetArtcleTags()
        let data = result.data.map(function (item) {
            return { label: item.categoryName, value: item.categoryID };
        });
        let newData = [{ label: "All", value: "0" }, ...data]
        setCategory(newData)
    }

    useEffect(() => {
        GetCategoryApi()
    }, [])

    return (
        <div className='container-fuild'>
            <div className="row">
                <SwitchContent
                    activeArticles={activeArticles}
                    activeVideo={activeVideo}
                    setActiveArticle={setActiveArticle}
                    setActiveVideo={setActiveVideo} />
            </div>

            <div className='mt-2 pt-3 px-3'>
                <InnerCuntantHeader handleChange={handleChange} value={value} TabBar={category} handleClick={handleClick} />
            </div>
            <div className="row mt-5">
                {
                    activeArticles && <>
                        {
                            articleList?.map((item) => (
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 my-3">
                                    <ContentCard item={item} getArticleApi={getArticleApi} categoryValue={categoryValue} />
                                </div>
                            ))
                        }
                    </>
                }

                {
                    activeVideo
                    &&
                    <>
                        {
                            videoList?.map((item) => (
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 my-3">
                                    <ContentCard item={item} />
                                </div>
                            ))
                        }
                    </>
                }
            </div>
        </div>
    )
}
export default ContentHub

