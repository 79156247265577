import React from 'react'

const InputSearch = ({ setSearchVal }) => {
  return (
    <div className='searchInput'>
      <input type="text" placeholder='Search Collaterals' onChange={e => setSearchVal(e.target.value)} />
    </div>
  )
}

export default InputSearch