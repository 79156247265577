import React, { useContext } from 'react'
import doc from "../../Assets/image/Collateral/doc.svg"
import view from "../../Assets/image/Collateral/bi_view-list.svg"
import { ModalContext } from '../../Context';
import TestModal from '../../ModalTemplate/TestModal';
import moment from 'moment';
import ViewCollateral from '../../ModalTemplate/ViewCollateral';
import PendingButton from './PendingButton';




const CollateralPandingCard = ({ collertalimg, item, getPendingCollateralist }) => {

  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;

  const handleModal = (type) => {
    const tabsValue = type;
    const Test = <TestModal item={item} getPendingCollateralist={getPendingCollateralist} />
    const View = <ViewCollateral item={item} />

    switch (tabsValue) {
      case "TEST":
        handleModalData(Test, "cs");
        break;
      case "VIEW":
        handleModalData(View, "sm");
        break;

      default:
        handleModalData(Test, "lg");
        break;
    }
  }
  // console.log(item?.collatData, "check")


  return (
    <>
      <div className="card collateral">
        <div className="card-body p-5 collateralbody">
          <div className='d-flex collateraldata '>
            <div className='abcd'>
            <div className='max-hight'>
              <img src={item?.collatData[0]?.thumbImage || collertalimg} alt="" />
            </div>
            </div>
            <div className='ps-5 pe-5 collateral-details-pending-responisve'>
              <p>{item.titleName}</p>
              <p className='collateral-details mt-2'>Type : <span>{item.collateralType}</span></p>
              <p className='collateral-details mt-2'>Category : <span>{item.categoryName}</span></p>
              <p className='collateral-details mt-2'>Created By : <span>{item.addedBy}</span></p>
              <p className='collateral-details mt-2'>Scheduled For : <span>{moment(item.scheduledTime).format('L')} , {moment(item.scheduledTime).format('LT')}</span></p>
            </div>

          </div>
          <div className='mt-4 d-flex justify-content-between align-items-center '>
            <div className='d-flex  appdecline '>
              {
                item?.collatData[0] !== null &&
                <span className='testbtn me-4 cursor-pointer' onClick={() => handleModal("TEST")}> <img src={doc} alt="" /> test</span>
              }
              <span className='viewbtn cursor-pointer' onClick={() => handleModal("VIEW")}> <img src={view} alt="" />  view</span>
            </div>
            <PendingButton item={item} getPendingCollateralist={getPendingCollateralist} />
          </div>


        </div>
      </div>
    </>
  )
}

export default CollateralPandingCard;