import React from 'react'

const CollateralDeclinedCard = ({ collertalimg, item }) => {


    console.log(item, "da")
    return (
        <div className="card collateral">
            <div className="card-body p-5 collateralbody">
                <div className='d-flex collateraldata'>
                    <div className='abcd'>
                    <div className='max-hight'>
                        <img src={item?.titleImage || collertalimg} alt="" />
                    </div>
                    </div>
                    <div className='ps-5 collateral-details-responisve'>
                        <p>{item.titleName}</p>
                        <p className='collateral-details mt-2'>Type : <span>{item.collateralType}</span></p>
                        <p className='collateral-details mt-2'>Category : <span>{item.categoryName}</span></p>
                        <p className='collateral-details mt-2'>Created By : <span>{item.declinedBy}</span></p>
                        <p className='collateral-details mt-2'>Declined By : <span>{item.declinedBy}</span></p>
                        <p className='collateral-details mt-2'>Scheduled For : <span>Lorem Ipsum</span></p>
                    </div>

                </div>
                <div className='mt-4 d-flex justify-content-between align-items-center'>

                    <span className='carsize'>{item.sizes}</span>
                    <button className='decline me-2' disabled={true}>Decline</button>

                </div>




            </div>
        </div>
    )
}

export default CollateralDeclinedCard;