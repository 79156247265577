import React from 'react'
import Button from '../../../Components/commoncomponent/Button'
import InputSearch from '../../../Components/commoncomponent/InputSearch'
import SwitchButtonTwo from '../../../Components/commoncomponent/SwitchButtonTwo'
import logo from "../../../Assets/image/Collateral/collateral.png"
import logo2 from "../../../Assets/image/Collateral/colleratalfour.png"
import logo3 from "../../../Assets/image/Collateral/collertaltwo.png"
import CollateralPandingCard from '../../../Components/commoncomponent/CollateralPandingCard'
import { getcheck, getPendingCollateral, getPendingCollateralVideo } from '../../../Utils/services'
import { useEffect } from 'react'
import { useState } from 'react'
import Spinner from '../../../Components/commoncomponent/Spinner'
import VideoPendingCard from '../../../Components/commoncomponent/VideoPendingCard'
import Sort from '../../../Components/commoncomponent/Sort'
import Pagination from '../../../Components/commoncomponent/Pagination'






const CollateralPanding = () => {
    const [allImage, setAllImage] = useState(true);
    const [allVideo, setAllVideo] = useState(false)
    const [search, setSearchVal] = useState("")
    const [pendingList, setPendinglist] = useState([]);
    const [pendingVideo, setPendingVideoList] = useState([])
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0);
    const [totalpage, setTotalpage] = useState(0)

    const getPendingCollateralist = async () => {
        let result = await getPendingCollateral(count)
        const _newData = result.data.filter((item) => item.collateralType !== "video")
        setTotalpage(result.data[0].totalData)
        setLoading(false)
        setPendinglist(_newData)

    }

    const getPendingCollateralVideoList = async () => {
        let result = await getPendingCollateralVideo();
        setPendingVideoList(result.data)
        console.log(result, "video")

    }



    useEffect(() => {

        getPendingCollateralist()
        getPendingCollateralVideoList()

    }, [count])

    console.log(search, "Check")

    return (
        <>
            <div className='container-fuild'>
                <div className="row">
                    <div className='d-flex justify-content-between align-items-center masterflex'>
                        <InputSearch setSearchVal={setSearchVal} style={{ display: 'flex', justifyContent: 'center' }} />
                        <div className='d-flex align-items-center masterpagination'>
                            <Pagination totalpage={totalpage} setCount={setCount} count={count} collateralList={pendingList} />
                            <div className='me-4'>
                                <SwitchButtonTwo custom={allVideo} setCustom={setAllVideo} all={allImage} setAll={setAllImage} />
                            </div>

                            <Sort setSearchVal={setSearchVal} />

                        </div>

                    </div>
                </div>
                {
                    allImage &&
                    <>
                        {loading ? <Spinner /> :
                            <div className="row mt-5">
                                {
                                    pendingList && pendingList?.filter((val) => {
                                        if (search == "") {
                                            return val;
                                        }
                                        else if (val && val.titleName && val.titleName.toLowerCase().includes(search.toLowerCase())) {
                                            return val
                                        }
                                        else if (val && val.collateralType && val.collateralType.toLowerCase().includes(search.toLowerCase())) {
                                            return val
                                        }
                                        else if (val && val?.tagName[0] && val.tagName[0].toLowerCase().includes(search.toLowerCase())) {
                                            return val
                                        }

                                    }).map((item) => (
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <CollateralPandingCard getPendingCollateralist={getPendingCollateralist} collertalimg={logo3} item={item} />

                                        </div>

                                    ))
                                }


                            </div>


                        }

                    </>
                }


                {
                    allVideo &&
                    <>

                        <>
                            {loading ? <Spinner /> :
                                <div className="row mt-5">
                                    {
                                        pendingVideo && pendingVideo?.filter((val) => {
                                            if (search == "") {
                                                return val;
                                            }
                                            else if (val && val.titleName && val.titleName.toLowerCase().includes(search.toLowerCase())) {
                                                return val
                                            }
                                            else if (val && val.collateralType && val.collateralType.toLowerCase().includes(search.toLowerCase())) {
                                                return val
                                            }
                                            else if (val && val?.tagName[0] && val.tagName[0].toLowerCase().includes(search.toLowerCase())) {
                                                return val
                                            }

                                        }).map((item) => (
                                            <div className="col-lg-6 col-md-12 mb-4">
                                                <VideoPendingCard getPendingCollateralist={getPendingCollateralVideoList} collertalimg={logo3} item={item} />
                                            </div>

                                        ))
                                    }

                                </div>


                            }

                        </>

                    </>
                }





            </div>
        </>
    )
}

export default CollateralPanding;