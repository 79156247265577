import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux';
import { ModalContext } from '../Context';
import { editTag } from '../Redux/master/masterSlice';
import { EditContentapi, editListOfTags, EditRvCategory } from '../Utils/services';

const EditContent = ({ data, GetCategoryApi, items }) => {
    const [tagName, setEditVal] = useState(items?.label)
    const [tagID, settagNameVal] = useState(items?.value)
    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;



    console.log(data)


    const handleEdit = async () => {
        const result = await EditContentapi(
            {
                "categoryID": tagID,
                "categoryName": tagName
            }

        )

        GetCategoryApi()
        closeModal()

    }



    return (
        <div className='add-collateral-type'>
            <h3 className='mb-4'>Edit Tag</h3>
            <input type="text" placeholder='Tags' value={tagName} onChange={(e) => setEditVal(e.target.value)} />
            <div className='mt-3 d-flex justify-content-between'>
                <button className='concel-btn-modal'>Cancel</button>
                <button className='save-btn-modal' onClick={() => handleEdit()}>Save</button>
            </div>
        </div>
    )
}

export default EditContent