import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


// import required modules
import { Pagination, Navigation } from "swiper";
import Card from "./Card";


const AnalitycsCard = ({ item }) => {


    return (
        <div className="card card-radius">
            <div className='pt-4 card-img'>
                <img src={item.cardBg} alt="" />
            </div>
            <div className="card-body card-details px-5 py-5">
                <div className='d-flex align-items-center overviewcard' >
                    <img src={item.logo} alt="" />
                </div>
                <div className='mt-2 analyticCardetails'>
                    <h3>{item.new}</h3>
                    <p className='mt-1'>{item.type}</p>
                    <p className='mt-1'>{item.anltytic}</p>
                </div>
            </div>
        </div>
        
    )
}

export default AnalitycsCard