import { yupResolver } from '@hookform/resolvers/yup';

import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form';
import { permissionData } from '../Components/commoncomponent/dummydata';
import { validationSchema } from '../Components/commoncomponent/validation';
import { ModalContext } from '../Context';
import { createUser } from '../Utils/services';




const AddPeople = ({ listOfPeople }) => {
    const [isLoading, setIsloading] = useState(false)
    const [selectedcourse, setselectedCourse] = useState([]);
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });
    const [error, setErrors] = useState({})
    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;

    const handlecheckbox = (e) => {
        var temp = selectedcourse;
        // console.log(e.target.value, "string check")
        const Int_number = parseInt(e.target.value)
        if (e.target.checked === false) {
            temp = temp.filter((a) => {
                return a != Int_number;
            });
        }
        e.target.checked
            ? setselectedCourse([...selectedcourse, Int_number])
            : setselectedCourse([...temp]);

        setErrors({})


    };

    const authority = (data) => {
        if (data.authority === "checker") {
            return true
        }
        return false
    }

    const handleAddprofile = async (data) => {


        try {

            if (handleValidation()) {
                setIsloading(true)
                let result = await createUser({
                    "firstName": data.firstname,
                    "lastName": data.lastname,
                    "contactNumber": data.contact,
                    "email": data.email,
                    "modules": selectedcourse,
                    "designation": data.authority,
                    "roleType": authority(data),
                })

                if (result.status) {
                    setIsloading(false)
                    listOfPeople()
                    closeModal()
                }
                else (
                    setIsloading(false)
                )
            }

        } catch (error) {
            setIsloading(false)
        }

    }


    const handleValidation = () => {
        let _errors = {};
        let formIsValid = true;

        if (selectedcourse.length == 0) {
            formIsValid = false;
            _errors["selectedcourse"] = "permission is required";

        }


        setErrors(_errors)
        return formIsValid;


    }

    console.log(selectedcourse)

    console.log(permissionData)

    return (
        <form action="" onSubmit={handleAddprofile}>


            <div className='test-modal'>

                <div className='d-flex justify-content-between form-flex'>
                    <div>
                        <p className='ps-2 pb-2'>Name</p>
                        <input type="text" name='title' placeholder='First Name'  {...register('firstname')}
                            className={`form-control ${errors.firstname ? 'is-invalid' : ''}`} />
                    </div>


                    <div className='mg-space'>
                        <p className='ps-2 pb-2 '>Last Name</p>
                        <input type="text" name='lastName' placeholder='Last Name' {...register('lastname')} className={`form-control space-form ${errors.lastname ? 'is-invalid' : ''}`} />

                    </div>
                </div>
                <div className='d-flex justify-content-between mt-4 form-flex' >
                    <div>
                        <p className='ps-2 pb-2'>Contact Number</p>
                        <input type="text" name='contact' placeholder='Contact Number'  {...register('contact')} className={`form-control ${errors.contact ? 'is-invalid' : ''}`} />

                    </div>
                    <div className="mg-space">
                        <p className='ps-2 pb-2'>Email ID</p>
                        <input type="text" name='email' placeholder='Email ID' {...register('email')} className={`form-control space-form ${errors.email ? 'is-invalid' : ''}`} />

                    </div>
                </div>
                <div className='mt-3 '>
                    <p className='ps-2 pb-2'>Permissions</p>
                    <ul class="ux-btn-group">

                        {permissionData.map((course, index) => {
                            return (
                                <>
                                    <li key={index} >
                                        <input type="checkbox" value={course.key} id={`${course.value}`} class="ux-btn-group-item" name="option" onClick={handlecheckbox} />
                                        <label for={`${course.value}`}   >{course.value}</label>
                                    </li>
                                </>
                            );
                        })}
                    </ul>
                    {
                        error.selectedcourse && <p className="text-sm text-left text-danger pt-1">{error.selectedcourse}</p>
                    }

                </div>
                <div className='mt-3 '>
                    <p className='ps-2 pb-2'>Authority</p>
                    <div className='mt-1 add-people-btn'>
                        <ul class="ux-btn-group" >
                            <li>
                                <input type="radio" id="Checker" class="ux-btn-group-item " value="checker" name="option" {...register("authority")} />
                                <label for="Checker">Checker</label>
                            </li>
                            <li>
                                <input type="radio" id="Maker" class="ux-btn-group-item" value="maker" name="option"  {...register("authority")} />
                                <label for="Maker">Maker</label>
                            </li>

                        </ul>
                        {errors?.authority?.type == "typeError" && <p className='ps-2 text-danger'>Authority is required</p>}


                    </div>
                </div>
                <div className='mt-3 d-flex justify-content-between'>
                    <button className='concel-btn-modal' onClick={closeModal}>Cancel</button>
                    <button className='save-btn-modal' onClick={handleSubmit(handleAddprofile)} >
                        {isLoading ?
                            <>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </>
                            : "Add"}
                    </button>
                </div>
            </div>
        </form>
    )
}

export default AddPeople