import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import rblogo from '../../Assets/image/Logos/Group 1.svg'
import { getProfile } from '../../Redux/Profile/profileSlice'
import logo from '../../Assets/image/Logos/toggle.svg'


const Header = ({ profile ,handletoggle}) => {



    return (
        <header className='header'>
            <div className='d-flex align-items-center renewbuylogo'>
        <img src={logo} alt="" onClick={() => { handletoggle() }} className="logoimage" />

                <img src={rblogo} alt="" />
            </div>
            <div className='profile'>
                <h3>{profile?.firstName ? `${profile?.firstName} ${profile?.lastName} ` : "User"}</h3>
                <h5>Welcome back, nice to see you again!</h5>
            </div>

        </header >
    )
}

export default Header