export const permissionData = [
    {
        key: 1,
        value: "Dashboard",
    },
    {
        key: 2,
        value: "Collaterals",
    },
    {
        key: 3,
        value: "Master",
    },
    {
        key: 4,
        value: "Profile",
    },
    {
        key: 5,
        value: "People",
    }]
