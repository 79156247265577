import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ModalContext } from '../Context'
import { getOverViewdata } from '../Redux/home/homeSlice'

const CustomHomeModal = ({ setAll, setCustom, setInitialDate }) => {

    const [activeId, setActiveId] = useState()
    const [daterangeInitial, setDateRangeInitial] = useState()
    const [daterangeFinal, setDateFinal] = useState()
    const [manthData, setMonthData] = useState()
    const [selecttype, setSelectType] = useState()
    const [errors, setErrors] = useState({});
    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;

    const { homes } = useSelector((state) => state.home);
    const dispatch = useDispatch()
    // console.log(homes, "type")

    const date = [{

        name: "Jan",
        initalDate: "2023-01-01",
        finalDate: "2023-01-30",
    },
    {
        name: "Feb",
        initalDate: "2023-02-01",
        finalDate: "2023-02-28",
    },
    {
        name: "Mar",
        initalDate: "2023-03-01",
        finalDate: "2023-03-30",
    },
    {
        name: "Apr",
        initalDate: "2023-03-01",
        finalDate: "2023-03-30",
    },
    {
        name: "May",
        initalDate: "2023-03-01",
        finalDate: "2023-03-30",
    },
    {
        name: "Jun",
        initalDate: "2023-03-01",
        finalDate: "2023-03-30",
    },
    {
        name: "Jul",
        initalDate: "2023-03-01",
        finalDate: "2023-03-30",
    },
    {
        name: "Aug",
        initalDate: "2023-03-01",
        finalDate: "2023-03-30",
    },
    {
        name: "Sep",
        initalDate: "2023-03-01",
        finalDate: "2023-03-30",
    },
    {
        name: "Oct",
        initalDate: "2023-03-01",
        finalDate: "2023-03-30",
    },
    {
        name: "Nov",
        initalDate: "2023-03-01",
        finalDate: "2023-03-30",
    },
    {
        name: "Dec",
        initalDate: "2023-03-01",
        finalDate: "2023-03-30",
    },

    ]

    const handleManthData = (e) => {
        setActiveId(e.name)
        setMonthData(e)


    }



    const handleSubmit = () => {

        if (selecttype === "month") {
            if (handleValidation()) {
                const data = {
                    "startDate": manthData.initalDate,
                    "endDate": manthData.finalDate
                }

                dispatch(getOverViewdata(data))
                closeModal()

            }


        }

        else if (selecttype === "daterange") {
            if (handleValidation()) {
                const data = {
                    "startDate": daterangeInitial,
                    "endDate": daterangeFinal
                }
                dispatch(getOverViewdata(data))

                closeModal()

            }

        }


    }


    const handleValidation = () => {
        let _errors = {};
        let formIsValid = true;
        if (selecttype === "month") {
            if (!manthData) {
                formIsValid = false;
                _errors["monthdate"] = "Please select month";
            }

        }
        else if (selecttype === "daterange") {
            if (!daterangeInitial) {
                formIsValid = false;
                _errors["startdate"] = "Please select start date";
            }
            else if (!daterangeFinal) {
                formIsValid = false;
                _errors["enddate"] = "Please select end date";
            }

        }

        setErrors(_errors)
        return formIsValid;

    }

    // console.log(selecttype, "data")

    return (
        <div className='custom-data-modals'>

            <div className='custom-radio-btn d-flex align-items-center mb-3'>
                <input type="radio" className="radio-btn me-3" name="option1" value="month" onChange={(e) => setSelectType(e.target.value)} />
                <span>Month</span>

            </div>
            <div className='d-flex flex-wrap custom-month'>
                {date?.map((item) => (
                    <button className={`me-3 mt-2 ${item.name === activeId && "activeManth"}`} onClick={() => handleManthData(item)}>{item.name}</button>
                ))}
            </div>
            <div>
                {
                    errors.monthdate && <p className="text-sm text-left text-danger pt-3">{errors.monthdate}</p>
                }
            </div>

            <div className='modal-row mt-4'></div>
            <div className='custom-radio-btn d-flex align-items-center mb-3 mt-3'>
                <input type="radio" className="radio-btn me-3" name="option1" value="daterange" onChange={(e) => setSelectType(e.target.value)} />
                <span>Date Range</span>

            </div>
            <div className='custom-date d-flex justify-content-between'>
                <input type="date" placeholder='from' className='me-5'
                    value={daterangeInitial}
                    onChange={e => setDateRangeInitial(e.target.value)}
                    max={new Date().toISOString().slice(0, 10)}

                />
                <input type="date"
                    placeholder='from'
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={e => setDateFinal(e.target.value)}


                />

            </div>
            {
                errors.startdate && <p className="text-sm text-left text-danger pt-3">{errors.startdate}</p>
            }
            {
                errors.enddate && <p className="text-sm text-left text-danger pt-3">{errors.enddate}</p>
            }
            <div className='mt-4 d-flex justify-content-between'>
                <button className='concel-btn-modal'>Cancel</button>
                <button className='save-btn-modal' onClick={handleSubmit} >Apply</button>
            </div>




        </div>
    )
}

export default CustomHomeModal;