import React, { useContext } from 'react'
import { useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ModalContext } from '../../Context'
import AddArticleCategary from '../../ModalTemplate/AddArticleCategary'
import Button from './Button'

const SwitchContent = ({ activeArticles, setActiveArticle, activeVideo, setActiveVideo }) => {
    // const [activeArticles, setActiveArticle] = useState(true)
    // const [activeVideo, setActiveVideo] = useState(false)
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const navigate = useNavigate()

    const handleArticle = () => {
        setActiveArticle(true)
        setActiveVideo(false)
    }
    const handleVideo = () => {
        setActiveArticle(false)
        setActiveVideo(true)
    }

    const handleAdd = () => {
        navigate('/content-hub/artical-form')

    }

    const handleTagModal = () => {

        const addCompany = <AddArticleCategary />
        handleModalData(addCompany, "md")

    }
    const handleAddVideo = () => {
        navigate('/content-hub/video-form')

    }

    const handleEdit = () => {
        navigate('/content-hub/edit')
    }

    return (
        <div className='switch-content d-flex justify-content-between align-items-center'>
            <div className='d-flex flexbasic'>
                <div className={`${activeArticles && 'active-content'} me-4`} onClick={handleArticle} >
                    <h3 className='px-4 py-3'>Articles</h3>
                </div>
                <div className={`${activeVideo && 'active-content'}`} onClick={handleVideo} >
                    <h3 className='px-4 py-3'>Videos</h3>
                </div>
            </div>
            {
                activeArticles &&
                < div className='me-3 AddEditButton' >
                    <DropdownButton id="dropdown-basic-button" className='me-4 px-2 addbuttondropdown' size="sm" title="Add ">
                        <Dropdown.Item onClick={handleAdd} >Article</Dropdown.Item>
                        <Dropdown.Item onClick={handleTagModal} >Tags</Dropdown.Item>
                    </DropdownButton>


                    <button className='edit_btn_content_hub' onClick={handleEdit} >Edit</button>
                </div >

            }

            {
                activeVideo &&
                < div className='me-3 AddEditButton' >
                    <DropdownButton id="dropdown-basic-button" className='me-4 px-2 addbuttondropdown' size="sm" title="Add ">
                        <Dropdown.Item onClick={handleAddVideo} >Video</Dropdown.Item>
                        <Dropdown.Item onClick={handleTagModal} >Tags</Dropdown.Item>
                    </DropdownButton>

                    <button className='edit_btn_content_hub' onClick={handleEdit}>Edit</button>
                </div >

            }


        </div >

    )
}

export default SwitchContent