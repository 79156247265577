import axios from 'axios'
import { ALLPEOPLE, EDITTAGS, LISTOFTAGS } from '../route';

// export const BASE_URL = "https://renewbuy.techtonic.asia/api"; //devlopment//
export const BASE_URL = "https://collaterals.api.renewbuy.com/api"; //production//


axios.defaults.baseURL = BASE_URL;
// get people //

const getPeople = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.get(ALLPEOPLE, config)

    return response.data;
}

const deletePeople = async (goalId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.post("", config)

    return response.data
}

const getTag = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.get(LISTOFTAGS, config)

    return response.data;
}

const editTag = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.post(EDITTAGS, data, config)

    return response.data
}

const masterService = {
    getPeople,
    deletePeople,
    getTag,
    editTag
}

export default masterService