import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify';
import { ModalContext } from '../Context';
import { editCollateraltype } from '../Utils/services'

const EditCollateralType = ({ getCollateraldata, collateralList, data }) => {
    const [collateralName, setCollaterName] = useState(data.collateralType)
    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;

    const handleEditApiData = async () => {
        const result = await editCollateraltype(
            {
                "collateralTypeID": data.collateralTypeID,
                "collateralType": collateralName
            }
        )
        toast.success(result.apiMessage);
        getCollateraldata()
        closeModal()

    }


    return (
        <div className='add-collateral-type'>
            <h3 className='mb-4'>Edit Collaterals Type </h3>
            <input type="text" placeholder='Collateral Type...' value={collateralName} onChange={(e) => setCollaterName(e.target.value)} />
            <div className='mt-3 d-flex justify-content-between'>
                <button className='concel-btn-modal'>Cancel</button>
                <button className='save-btn-modal' onClick={handleEditApiData}>Save</button>
            </div>
        </div>
    )
}

export default EditCollateralType