import React from 'react'

const ViewVideoModal = ({ item }) => {
    return (
        <div>
            <h3 className='mb-3 p-1 text-center'>{item?.titleName}</h3>

            {
                !item?.collatData[0]?.imagePath ?
                    <>
                        <p className='text-center p-2'>No image</p>
                        <div className='text-center'>
                            <span class="spinner-border spinner-border-sm  " role="status" aria-hidden="true"></span>
                            Loading...
                        </div>
                    </>
                    :
                    <div className='video_Response'>
                        <video width="100%" controls autoPlay="true">
                            <source src={item?.collatData[0]?.imagePath} type="video/mp4" />
                        </video>

                    </div>




            }

        </div>
    )
}

export default ViewVideoModal