import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import masterService from "./masterService";



const initialState = {
    master: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

// Get user goals
export const getPeople = createAsyncThunk(
    'people/getpeopleAll',
    async (_, thunkAPI) => {
        try {

            const token = thunkAPI.getState().auth.user.accessToken
            console.log(token, "check token")
            return await masterService.getPeople(token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delete user People
export const deletePeople = createAsyncThunk(
    'people/delete',
    async (id, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.accessToken
            return await masterService.deletePeople(id, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)


// Get user goals
export const getTag = createAsyncThunk(
    'Tag/getTag',
    async (_, thunkAPI) => {
        try {

            const token = thunkAPI.getState().auth.user.accessToken
            console.log(token, "check token")
            return await masterService.getTag(token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// edit tag goals
export const editTag = createAsyncThunk(
    'Tag/editTag',
    async (data, thunkAPI) => {
        try {

            const token = thunkAPI.getState().auth.user.accessToken
            return await masterService.editTag(data, token)


        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)





export const materSlice = createSlice({
    name: 'master',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder

            .addCase(getPeople.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPeople.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.master = action.payload
                // console.log(action.payload.data, "data check")
            })
            .addCase(getPeople.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload

            })
            .addCase(deletePeople.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deletePeople.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.master.data = state.master.data.filter(

                    (item) => item.userID !== action.payload.id
                )

            })

            .addCase(deletePeople.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload

            })


            .addCase(getTag.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getTag.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.master = action.payload.data
                console.log(action.payload, "check")
            })

            .addCase(getTag.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload

            })



    },
})

// console.log(goalSlice, "check")

export const { reset } = materSlice.actions;
export default materSlice.reducer;