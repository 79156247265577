import React, { useContext } from 'react'
import doc from "../../Assets/image/Collateral/doc.svg"
import view from "../../Assets/image/Collateral/bi_view-list.svg"
import { ModalContext } from '../../Context';
import TestModal from '../../ModalTemplate/TestModal';
import ViewCollateral from '../../ModalTemplate/ViewCollateral';
import ReactiveButton from './ReactiveButton';
import playIcon from "../../Assets/image/icons/playicon.png"
import ViewVideoModal from '../../ModalTemplate/ViewVideoModal';
import TestVideoModal from '../../ModalTemplate/TestVideoModal';

const VideoDecative = ({ collertalimg, item, handleDeactiveList }) => {

    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;


    const handleModal = (type) => {
        const tabsValue = type;
        const Test = <TestVideoModal item={item} />
        const View = <ViewVideoModal item={item} />
        switch (tabsValue) {
            case "TEST":
                handleModalData(Test, "cs");
                break;
            case "VIEW":
                handleModalData(View, "lg");
                break;

            default:
                handleModalData(Test, "lg");
                break;
        }
    }

    return (
        <div className="card collateral">
            <div className="card-body p-5 collateralbody">
                <div className='d-flex collateraldata'>
                    <div className='max-hight overlayer'>
                        <img src={item?.collatData[0]?.thumbImage || collertalimg} alt="" />
                        <div className='playicon'>
                            <img src={playIcon} alt="" />
                        </div>

                    </div>

                    <div className='ps-5'>
                        <p>{item?.titleName}</p>
                        <p className='collateral-details mt-2'>Type : <span>{item?.titleName}</span></p>
                        <p className='collateral-details mt-2'>Category : <span>{item?.categoryName}</span></p>
                        <p className='collateral-details mt-2'>Created By : <span>{item?.addedBy}</span></p>
                        <p className='collateral-details mt-2'>Scheduled For : <span>{item?.addedOn}</span></p>
                    </div>

                </div>
                <div className='mt-4 d-flex justify-content-between align-items-center'>
                    <div className='d-flex'>
                        <span className='testbtn me-3 cursor-pointer' onClick={() => handleModal("TEST")}> <img src={doc} alt="" /> test</span>
                        <span className='viewbtn cursor-pointer' onClick={() => handleModal("VIEW")}> <img src={view} alt="" />  view</span>
                    </div>
                    <ReactiveButton item={item} handleDeactiveList={handleDeactiveList} />

                </div>




            </div>
        </div>
    )
}

export default VideoDecative;