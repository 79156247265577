
import React, { useContext, useEffect, useMemo, useState } from 'react'
import Button from '../../Components/commoncomponent/Button'
import ColletralTable from '../../Components/commoncomponent/ColletralTable'
import InputSearch from '../../Components/commoncomponent/InputSearch'
import SwitchButtonTwo from '../../Components/commoncomponent/SwitchButtonTwo'
import data from '../../Components/commoncomponent/data.json'
import delIcon from "../../Assets/image/icons/delete.png"
import { ModalContext } from '../../Context'
import AddPeople from '../../ModalTemplate/AddPeople'
import ViewModal from '../../ModalTemplate/ViewModal'
import Delete from '../../ModalTemplate/Delete'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getPeople, reset } from '../../Redux/master/masterSlice'
import Spinner from '../../Components/commoncomponent/Spinner'
import { getListOfUser } from '../../Utils/services'
import DeleteUser from '../../ModalTemplate/DeleteUser'

const dumydata = [
    {
        "id": 1,
        "Standee": "Samriti Sharma",
        "demension": "9876543210",
        "add_on": "loremipsum@gmail.com",
        "addby": "All"

    },
    {
        "id": 2,
        "Standee": "Chandan Singh",
        "demension": "9876543210",
        "add_on": "loremipsum@gmail.com",
        "addby": "Dashboard, Master, Collateral"

    },
    {
        "id": 3,
        "Standee": "Chandan Singh",
        "demension": "9876543210",
        "add_on": "loremipsum@gmail.com",
        "addby": "Dashboard, Master, Collateral"

    },
    {
        "id": 4,
        "Standee": "Chandan Singh",
        "demension": "9876543210",
        "add_on": "loremipsum@gmail.com",
        "addby": "Dashboard"

    },
    {
        "id": 5,
        "Standee": "Chandan Singh",
        "demension": "9876543210",
        "add_on": "loremipsum@gmail.com",
        "addby": "Dashboard, Master"

    },

]



const People = () => {
    const [isLoading, setLoading] = useState(true)
    const [peoplList, setPeoplList] = useState([])
    const [search, setSearchVal] = useState("")



    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;


    const listOfPeople = async () => {
        try {

            const result = await getListOfUser()
            if (!result.status) {
                setPeoplList(result.data)
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        } catch (error) {

        }

    }

    useEffect(() => {
        listOfPeople()
    }, [])










    const FeedsColumns = useMemo(
        () => [
            {
                Header: "User Name",
                accessor: data => {

                    return (
                        <>
                            <span>{data.name}</span>
                        </>
                    )
                }
            },

            {
                Header: "Contact Number",
                accessor: data => {
                    return (
                        <>
                            <span>{data.contactNumber}</span>
                        </>
                    )
                }
            },

            {
                Header: "Email",
                accessor: data => {
                    return (
                        <>
                            <span>{data.userEmail}</span>
                        </>
                    )
                }
            },
            {
                Header: "Permissions",
                accessor: data => {
                    return (
                        <>

                            {data.listOfPermission && data.listOfPermission.map(u => u.permissionName).join(' , ')}


                        </>
                    )
                }
            },


            {
                Header: 'Action',
                accessor: (data) => {
                    return (
                        <>
                            <div className='d-flex'>
                                <span className="MuiButton-label editbtn cursor-pointer" onClick={() => handleView(data)}>View</span>
                                <span className="MuiButton-label underline ms-3 cursor-pointer" onClick={() => handleDelete(data)}><img src={delIcon} alt="delete" /></span>
                            </div>
                        </>
                    )
                },
            }

        ], [])


    const handleAdd = () => {
        const Addpeople = <AddPeople listOfPeople={listOfPeople} />
        handleModalData(Addpeople, "cs")
    }

    const handleView = (data) => {
        const Addpeople = <ViewModal data={data} />
        handleModalData(Addpeople, "cs")

    }

    const handleDelete = (data) => {
        const addCollateral = <DeleteUser title="Are you sure you want to delete this people?" data={data} listOfPeople={listOfPeople} />
        handleModalData(addCollateral, "md")
    }


    return (
        <div className='container-fuild'>
            <div className="row">
                <div className='d-flex justify-content-between align-items-center'>
                    <InputSearch setSearchVal={setSearchVal} />
                    <div className='d-flex align-items-center '>

                        <Button name="Add +" handleAdd={handleAdd} />

                    </div>

                </div>

            </div>


            {
                isLoading ? <Spinner /> :
                    <>

                        {peoplList &&
                            <div className="row mt-2">
                                <div className="col-12 mb-4" style={{ overflowY: "scroll" }}>
                                    <ColletralTable data={peoplList.filter((val) => {
                                        if (search == "") {
                                            return val;
                                        }
                                        else if (val && val.name && val.name.toLowerCase().includes(search.toLowerCase())) {
                                            return val
                                        }

                                    })} columns={FeedsColumns} />
                                </div>
                            </div>
                        }
                    </>
            }

        </div>
    )
}

export default People;