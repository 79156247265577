import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import homeService from './homeService'



const initialState = {
    homes: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}



// 
export const getOverViewdata = createAsyncThunk(
    'home/overview',
    async (id, thunkAPI) => {
        console.log(id)
        try {
            const token = thunkAPI.getState().auth.user.accessToken
            return await homeService.getTagOverView(id, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)


export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder

            .addCase(getOverViewdata.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getOverViewdata.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.homes = action.payload
                // console.log(action.payload.data, "data check")
            })
            .addCase(getOverViewdata.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload

            })



    },

})


export const { reset } = homeSlice.actions;
export default homeSlice.reducer;