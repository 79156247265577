import React from 'react'
import heart from "../../Assets/image/Logos/Frame.svg"
import newBg from "../../Assets/image/Analitycs/newBg.png"

const Card = ({ item, logo, title, download, share, items, HandleClick }) => {

  // console.log(items, "check id")


  return (
    <div className="card card-radius cursor-pointer" onClick={() => HandleClick(items?.tagId)}>
      <div className='pt-4 card-img'>
        <img src={items?.tagBanner || item} alt="" />
      </div>
      <div className="card-body card-details p-5">
        <div className='d-flex align-items-center overviewcard' >
          {/* <div className='heart'>

          </div> */}
          <img src={items?.tagIcon || logo} alt="" />
          <p className='ms-3 pb-2'>{title}</p>
        </div>
        <div className='d-flex justify-content-between mb-2 mt-5 ' >
          <span className=''>Total Downloads</span>
          <span>{download}</span>
        </div>

        <div className='totaldownload'></div>

        {/* <div className='d-flex justify-content-between mb-2 mt-3'>
          <span className=''>Total Shares</span>
          <span>{share}</span>
        </div>
        <div className='totalshare'></div> */}


      </div>
    </div>
  )
}

export default Card