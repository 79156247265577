
import React, { useContext, useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useNavigate } from 'react-router-dom';
import Button from '../../../Components/commoncomponent/Button';
import InnerHeader from '../../../Components/commoncomponent/InnerHeader';

import InputSearch from '../../../Components/commoncomponent/InputSearch';
import InsurerCard from '../../../Components/commoncomponent/InsurerCard';
import { ModalContext } from '../../../Context';
import AddCategary from '../../../ModalTemplate/AddCategary';
import AddCompany from '../../../ModalTemplate/AddCompany.jsx';
import { getBrochure, getGetBroucherCategory } from '../../../Utils/services';

const Insurer = () => {
    const [value, setValue] = React.useState(0);
    const [search, setSearchVal] = useState("")

    const [category, setCategory] = useState([]);
    const [brochure, setBrochure] = useState([]);
    const [categaryId, setCategaryId] = useState(1)
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const navigate = useNavigate()



    const handleAdd = () => {
        console.log(closeModal)
        const addCompany = <AddCompany />
        handleModalData(addCompany, "md")

    }
    const handlecategary = () => {
        const addCompany = <AddCategary />
        handleModalData(addCompany, "md")

    }

    const handleBrchures = () => {
        navigate('/insurer-add-brochures')

    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const GetCategoryApi = async () => {
        const result = await getGetBroucherCategory()
        let data = result.data.map(function (item) {
            return { label: item.categoryName, value: item.categoryID };
        });
        setCategory(data)

    }

    const getBrochureApi = async (value) => {
        console.log(value, "check value")
        const formData = new FormData();
        formData.append("categoryID", value);
        const result = await getBrochure(formData)
        setBrochure(result.data)
        console.log(result, "check Value")

    }




    useEffect(() => {
        GetCategoryApi()
    }, [])

    useEffect(() => {
        if (category[0]?.value) {
            console.log(category[0]?.value, "check")
            getBrochureApi(category[0]?.value)
        }


    }, [category])

    const handleClick = (e) => {
        console.log(e, "hit")
        getBrochureApi(e?.value)
        setCategaryId(e?.value)
    }

    const handleEdit = () => {
        navigate('/insurer-add-brochures/edit')
    }

    return (
        <div className='container-fuild'>
            <div className="row">
                <div className='d-flex justify-content-between align-items-center'>
                    <InputSearch setSearchVal={setSearchVal} />
                    <div className='d-flex align-items-center '>

                        <div className='me-3 dropbrocom'>
                            <DropdownButton id="dropdown-basic-button" className='me-4 px-2 Brochurescompany' size="sm" title="Add ">
                                <Dropdown.Item onClick={handleBrchures} >Brochures</Dropdown.Item>
                                <Dropdown.Item onClick={handleAdd} >Company</Dropdown.Item>
                                <Dropdown.Item onClick={handlecategary}>Category</Dropdown.Item>
                            </DropdownButton>
                        </div>

                        <Button name="Edit " handleAdd={handleEdit} />

                    </div>

                </div>

            </div>

            <div className='mt-2 pt-3 '>
                <InnerHeader handleChange={handleChange} value={value} TabBar={category} handleClick={handleClick} />
            </div>

            <div className="row justify-content-around ">
                {
                    brochure?.filter((val) => {
                        
                        if (search === "") {
                            return val;
                        }
                        else if (val && val.titleName && val.titleName.toLowerCase().includes(search.toLowerCase())) {
                            return val
                        }
                        else if (val && val.brouchersDescription
                            && val.brouchersDescription
                                .toLowerCase().includes(search.toLowerCase())) {
                            return val
                        }
                            return val
                    })?.map((item) => (
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mt-5">
                            <InsurerCard item={item} getBrochureApi={getBrochureApi} category={categaryId} />

                        </div>

                    ))
                }
            </div>

        </div>
    )
}

export default Insurer;