import React from 'react'

const ProductCollateralsCards = ({ collertalimg }) => {
    return (
        <div className="card collateral">
            <div className="card-body p-5 collateralbody">
                <div className='d-flex collateraldata'>
                <div className='abcd'>
                    <img src={collertalimg} alt="" />
                    </div>
                    <div className='ps-5 renewpading'>
                        <p>RenewBuy </p>
                        <p> T-Shirt</p>
                        <p className='collateral-details mt-2'>Type : <span>Standee</span></p>
                        <p className='collateral-details mt-2'>Size : <span>6ft x 3ft</span></p>
                        <p className='collateral-detailsprice mt-3'>&#8377; 599</p>

                        <div className='d-flex mt-4'>

                            <button className='deactivebtn me-4'>Delete</button>
                            <button className='editbtncol '>Edit</button>
                        </div>
                    </div>

                </div>



            </div>
        </div >
    )
}

export default ProductCollateralsCards