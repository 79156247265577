import React from 'react'
import { useState } from 'react';
import prev from "../../Assets/image/icons/prev.png"
import next from "../../Assets/image/icons/next.png"

const Pagination = ({ collateralList, setCount, count, totalpage }) => {
    // const [count, setCount] = useState(0)
    console.log(collateralList)
    const totalPages = Math.ceil(totalpage / 20);

    return (
        <div className='d-flex align-items-center justity-content-start mx-4 pagination'>
            {
                count >= 1
                &&
                <button onClick={() => setCount(prev => prev - 1)} ><img src={prev} alt="" /></button>
            }

            <div className='px-3 pt-1'> {count}-{1 + count} of {totalPages + 1}</div>

            {
                count < totalPages
                &&
                <button onClick={() => setCount(prev => prev + 1)}  ><img src={next} alt="" /></button>
            }




        </div>
    )
}

export default Pagination;