import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import newBg from "../../Assets/image/Analitycs/newBg.png"
import pendingBg from "../../Assets/image/Analitycs/pendingBg.png"
import deliverdBg from "../../Assets/image/Analitycs/deliverdBg.png"
import concelBg from "../../Assets/image/Analitycs/concelBg.png"

import health from "../../Assets/image/home/health.png"
import life from "../../Assets/image/home/life.png"
import car from "../../Assets/image/home/car.png"



// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


// import required modules
import { Pagination, Navigation } from "swiper";
import Card from "./Card";

export default function OverViewSlider({ greetingData, HandleClick }) {


    return (
        <>
            <Swiper
                slidesPerView={4}
                spaceBetween={30}
                slidesPerGroup={1}
                loop={false}
                loopFillGroupWithBlank={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"

                breakpoints={{
                    320: {
                        width: 275,
                        slidesPerView:1,
                    },
                    // when window width is >= 640px
                    640: {
                        width: 640,
                        slidesPerView:2,
                    },
                    // when window width is >= 768px
                    768: {
                        width: 768,
                        slidesPerView: 2,
                    },
                }} 


            >
                {
                    greetingData?.map((item) => (
                        <SwiperSlide><Card HandleClick={HandleClick} item={newBg} logo={health} title={item.tagName} download={item.totalDownload} share={item.totalShare} items={item} /></SwiperSlide>
                    ))
                }
                {/* 
                <SwiperSlide><Card item={pendingBg} logo={life} title="Life" /></SwiperSlide>
                <SwiperSlide><Card item={deliverdBg} logo={car} title="Motor" /></SwiperSlide>
                <SwiperSlide><Card item={concelBg} logo={car} title="Greetings" /></SwiperSlide>
                <SwiperSlide><Card item={newBg} logo={health} title="Health" /></SwiperSlide>
                <SwiperSlide><Card item={newBg} logo={health} title="Health" /></SwiperSlide>
                <SwiperSlide><Card item={newBg} logo={health} title="Health" /></SwiperSlide> */}
            </Swiper>
        </>
    );
}
