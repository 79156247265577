import React, { useEffect, useState } from 'react';
import avtar from "../../../Assets/image/Profile/avatar.png"
import { useNavigate } from 'react-router-dom'
import Avatar from '../../../Components/commoncomponent/Avatar';
import SwitchProfiledetails from '../../../Components/commoncomponent/SwitchProfiledetails';
// import { getProfile } from '../../../Utils/services';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, reset } from '../../../Redux/Profile/profileSlice';


const Profile = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { user } = useSelector((state) => state.auth)
    const { profile, isLoading, isError, message } = useSelector(
        (state) => state.profile
    )


    const handleUpdateDetailpage = (e) => {
        navigate('/profile/update')
    }

    const handleChangePasword = () => {
        navigate('/profile/updatepassword')
    }

    // useEffect(() => {
    //     if (isError) {
    //         console.log(message)
    //     }

    //     if (!user) {
    //         navigate('/login')
    //     }

    //     dispatch(getProfile())


    // }, [user, message, dispatch])



    useEffect(() => {
        dispatch(getProfile())
    }, [])


    return (
        <div className='profile'>

            {/* -----------------tab button -------------------------------- */}

            <SwitchProfiledetails Information="productactive" handleInformation={handleChangePasword} />


            {/* --------------- profile page ---------------------------------- */}

            <Avatar />

            {/* ---------------- avatar details---------------------------------- */}

            <div className='profile_form_details mt-4 '>
                <div className=''>
                    <div className="row ">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                            <div className=''  >
                                <p>First Name</p>
                                <input type="text" value={profile?.firstName} placeholder="Samriti" disabled={true} />
                            </div>


                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                            <div>
                                <p>Last Name</p>
                                <input type="text" value={profile?.lastName} placeholder='Sharma' disabled={true} />
                            </div>

                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                            <div>
                                <p>Designation</p>
                                <input type="text" value={profile?.userDesignation} placeholder='Manager, Digital Marketing' disabled={true} />
                            </div>

                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                            <div className=''  >
                                <p>Contact Number</p>
                                <input type="text" value={profile?.phoneNum || "Contact Number"} placeholder='+91-8506073332' disabled={true} />
                            </div>

                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                            <div>
                                <p>Email</p>
                                <input type="text" value={profile?.userEmail} placeholder='samritisharma@gmail.com' disabled={true} />
                            </div>

                        </div>
                    </div>
                </div>
                <div className='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 profile_editbtn  mt-4' onClick={handleUpdateDetailpage}>
                    <button className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4">Edit</button>
                </div>
            </div>
        </div >
    )
}

export default Profile;