import React from 'react'

const SwitchProfiledetails = ({ Information, Password, handleChangePassword, handleInformation }) => {


    return (
        <div className="product-servicex d-flex">
            <div className={`${Information} merchandise-libarye `} onClick={() => handleChangePassword()}>
                <h4 className="pt-4 pb-4 px-2">Personal Information</h4>
            </div>
            <div className={`collaterals-labe  ${Password}`} onClick={() => handleInformation()}>
                <h4 className="pt-4 pb-4 ">Change Password</h4>
            </div>
        </div>
    )
}

export default SwitchProfiledetails