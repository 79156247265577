import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { ModalContext } from '../Context';
import { toast } from 'react-toastify';
import { deletePeople } from '../Redux/master/masterSlice'
import { deleteCollateraltype } from '../Utils/services';

const Delete = ({ title, data, getCollateraldata }) => {
    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;

    const handleDeleteApiData = async () => {
        const result = await deleteCollateraltype(
            {
                "collateralTypeID": data.collateralTypeID,
            }
        )
        toast.success(result.apiMessage);
        getCollateraldata()
        closeModal()

    }
    console.log(data, "data")

    return (
        <div className='add-collateral-type'>
            <h3 className='mb-4 delete-heading'>{title}</h3>
            <div className='mt-3 d-flex justify-content-between'>
                <button className='concel-btn-modal' onClick={closeModal}>Cancel</button>
                <button className='save-btn-modal' onClick={() => handleDeleteApiData()} >Confirm</button>
            </div>
        </div>
    )
}

export default Delete