import React from 'react'

const MarchantCard = ({ collertalimg }) => {


    return (
        <>
            <div className="card collateral">
                <div className="card-body p-5 collateralbody">
                    <div className='d-flex collateraldata'>
                        <div className='abcd' style={{height:'170px'}}>
                        <img src={collertalimg} alt="" />
                        </div>
                        <div className='ps-5 mt-2 renewpading'>
                            <p>RenewBuy </p>
                            <p> T-Shirt</p>
                            <p className='collateral-details mt-2'>Type : <span>Wishes</span></p>
                            <p className='collateral-details mt-2'>Category : <span>RenewBuy</span></p>
                            <p className='collateral-details mt-2'>Created By : <span>Samriti Sharma</span></p>
                            <p className='collateral-details mt-2'>Scheduled For : <span>Lorem Ipsum</span></p>
                            <div className='d-flex mt-4'>
                                <button className='approvedbtn me-4'>Approved</button>
                                <button className='deactivebtn'>Deactivate</button>
                            </div>
                        </div>

                    </div>  
                </div>
            </div>
        </>
    )
}

export default MarchantCard;