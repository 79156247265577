import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../../../Components/commoncomponent/Button'
import ProductCollaterals from '../../../../Components/commoncomponent/ProductCollaterals'
import SwitchProduct from '../../../../Components/commoncomponent/SwitchProduct'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const ProductCollateral = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate()

    const handleCollateralpage = (e) => {
        navigate('/product')
    }



    return (
        <>
            <div className='container-fuild'>

                <div className="row">
                    <div className="productswitch">
                        <SwitchProduct
                            collaterals="productactive"
                            handleMarchant={handleCollateralpage}
                        />
                        <div className='addbtnmv'>

                            <Button name="Add +" />
                        </div>
                    </div>


                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="mt-4 ">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                            >
                                <Tab label="Health" />
                                <Tab label="Life" />
                                <Tab label="Motor" />
                                <Tab label="Finsure" />
                                <Tab label="Greetings" />
                                <Tab label="Renewals" />
                            </Tabs>


                        </div>

                    </div>

                </div>


                <div className="row justify-content-around">
                    <ProductCollaterals title="Standee" />
                </div>
                <div className="row justify-content-around">
                    <ProductCollaterals title="Banner" />
                </div>
                <div className="row justify-content-around">
                    <ProductCollaterals title="Poster" />
                </div>

            </div>
        </>
    )
}

export default ProductCollateral
