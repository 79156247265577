import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify';
import crossicon from "../Assets/image/icons/cross.png"
import { ModalContext } from '../Context';
import { createCollateralType } from '../Utils/services';

const AddCollateralType = ({ getCollateraldata }) => {
    const [tileName, setTileName] = useState("")
    const [width, setWidth] = useState("");
    const [height, setHeight] = useState("");
    const [size, setSize] = useState("")
    const [addRow, setAddRow] = useState([]);
    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;
    // add row state//
    const [widthTwo, setWidthTwo] = useState("")
    const [heightTwo, setHeightTwo] = useState("");
    const [sizeTwo, setSizeTwo] = useState("")
    const [loading, setLaoding] = useState(false)
    const [errors, setErrors] = useState({});


    const handleAddMore = () => {

        if (addRow.length < 3) {
            let newArray = [...addRow];
            newArray = [...newArray, ""];
            setAddRow(newArray);
        }

    }

    const handleCross = (id) => {
        const _newList =
            addRow &&
            addRow.filter((data, index) => {
                return index !== id;
            });
        setAddRow(_newList);
        console.log(id)
        delete widthTwo[`widthValue${id + 1}`];
        delete heightTwo[`heightValue${id + 1}`];
        delete sizeTwo[`sizeValue${id + 1}`];

    }


    const handleWidthTwo = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setWidthTwo({ ...widthTwo, [name]: value })

    }
    const handleHeightTwo = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setHeightTwo({ ...heightTwo, [name]: value })
    }

    const handleSizeTwo = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setSizeTwo({ ...sizeTwo, [name]: value })
    }



    const handleSubmit = async () => {

        setLaoding(true)
        const _newWidth = [width];
        const _newHeight = [height];
        const _newSize = [size]

        for (const x in widthTwo) {
            _newWidth.push(widthTwo[x]);

        }
        for (const x in heightTwo) {
            _newHeight.push(heightTwo[x]);

        }
        for (const x in sizeTwo) {
            _newSize.push(sizeTwo[x])
        }
        if (handleValidation()) {
            try {
                const result = await createCollateralType({
                    "name": tileName,
                    "height": _newHeight,
                    "width": _newWidth,
                    "units": _newSize
                })
                getCollateraldata()
                setLaoding(false)
                toast.success(result.apiMessage);
                closeModal()

            } catch (error) {
                toast.success(error.apiMessage);
            }

        }
        else {
            setLaoding(false)
        }




    }


    const handleValidation = () => {
        let _errors = {};
        let formIsValid = true;
        if (!tileName) {
            formIsValid = false;
            _errors["titelName"] = "Please enter the collateral name";
        }
        else if (!width) {
            formIsValid = false;
            _errors["width"] = "Please enter the width";
        }
        else if (!height) {
            formIsValid = false;
            _errors["height"] = "Please enter the height";
        }
        else if (!size) {
            formIsValid = false;
            _errors["size"] = "Please enter the size";
        }


        setErrors(_errors)
        return formIsValid;

    }


    console.log(widthTwo, heightTwo, sizeTwo, "check")



    return (
        <div className='add-collateral-type'>
            <h3 className='mb-4'>Add Collaterals Type </h3>
            <input type="text" placeholder='Collateral Type...' onChange={(e) => setTileName(e.target.value)} />
            {
                errors.titelName && <p className="text-sm text-left text-danger pt-1">{errors.titelName}</p>
            }
            <div className='d-flex mt-3 align-items-center justify-content-between wraprre' >
                <input type="text" maxlength={3} size={1} name="" id="" className='w-25' placeholder='Width' onChange={(e) => setWidth(e.target.value)} />
                <div>
                    <h1> x </h1>
                </div>

                <input type="text" maxlength={3} max={3} size={3} name="" id="" className='w-25' placeholder='Height' onChange={(e) => setHeight(e.target.value)} />
                <div className=''>
                    <h1> x </h1>
                </div>
                <select id="" className='selecttype' value={size} onChange={(e) => setSize(e.target.value)}>
                    <option value="">Select Size</option>
                    <option value="ft">ft</option>
                    <option value="inch">inch</option>
                </select>

            </div>
            {
                errors.width && <p className="text-sm text-left text-danger pt-1">{errors.width}</p>
            }
            {
                errors.height && <p className="text-sm text-left text-danger pt-1">{errors.height}</p>
            }
            {
                errors.size && <p className="text-sm text-left text-danger pt-1">{errors.size}</p>
            }


            {
                addRow?.map((item, index) => (
                    <div className='d-flex mt-3 align-items-center justify-content-between' >
                        <input type="text" maxlength={3} size={1} name={`widthValue${index + 1}`} id="" className='w-25' placeholder='Width' onChange={(e) => handleWidthTwo(e)} />
                        <div>
                            <h1> x </h1>
                        </div>

                        <input type="text" maxlength={3} max={3} size={3} name={`heightValue${index + 1}`} id="" className='w-25' placeholder='Height' onChange={(e) => handleHeightTwo(e)} />
                        <div className=''>
                            <h1> x </h1>
                        </div>
                        <div className='concelbutton'>
                            <select id="" className='selecttype' name={`sizeValue${index + 1}`} onChange={(e) => handleSizeTwo(e)} >
                                <option value="">Select Size</option>
                                <option value="ft">ft</option>
                                <option value="inch">in</option>
                            </select>
                            {
                                addRow.length - 1 === index &&
                                <div className='concelcross cursor-pointer'>
                                    <img src={crossicon} alt="" onClick={() => handleCross(index)} />
                                </div>
                            }


                        </div>

                    </div>
                ))
            }
            {
                addRow.length < 3 &&
                <div className='mt-3'>
                    <h3 className='px-2 cursor-pointer' onClick={handleAddMore} >Add +</h3>
                </div>
            }


            <div className='mt-3 d-flex justify-content-between '>
                <button className='concel-btn-modal' onClick={closeModal} >Cancel</button>
                <button className='save-btn-modal' onClick={handleSubmit}>{
                    loading ?
                        <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </>
                        : "Save"
                }</button>
            </div>
        </div>
    )
}

export default AddCollateralType