import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login, reset } from '../../../Redux/auth/authSlice'
import Spinner from '../../../Components/commoncomponent/Spinner'
import loginlogo from "../../../Assets/image/login/login-logo.png"
import rblogo from "../../../Assets/image/login/RB-Logo.png"
import eye from "../../../Assets/image/login/eye.png"
import noeye from "../../../Assets/image/login/noeye.png"
import phone from "../../../Assets/image/login/phone.png"

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    deviceInfo: {
      deviceId: '12345',
      deviceType: 'DEVICE_TYPE_DESKTOP',
      notificationToken: '57890',
    },
  })
  const [showPassword, setShowpassword] = useState(false)

  const { email, password, deviceInfo } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth,
  )

  useEffect(() => {
    if (isError) {
      //   toast.error(message)
      console.log(message, "hello")
    }

    if (isSuccess || user) {
      navigate('/')
    }

    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      email,
      password,
      deviceInfo,
    }
    dispatch(login(userData));


  }

  // if (isLoading) {
  //   return <Spinner />
  // }

  return (
    <>
      <div className="login-screen ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 p-0">
              <div className="login-banner">
                <div className='login-content'>
                  <img src={loginlogo} alt="" className='login-logo' />
                  <div className='rb-logo'>
                    <img src={rblogo} alt="" />
                  </div>
                </div>



              </div>
            </div>

            <div className="col-6">
              <div className='p-4'>
                <div className=' d-flex justify-content-end' >
                  <button className='phone-btn d-flex align-items-center'> <img src={phone} alt="" /> <span className='ps-2'>1800-419-7151</span></button>
                </div>

                <section className="form d-flex align-items-center justify-content-center ">
                  <div className='login-form '>
                    <div className='login-title mb-4'>
                      <h3>Immerse yourself in our </h3>
                      <h3><span>#FutureReady</span>   program</h3>
                    </div>


                    <form onSubmit={onSubmit}>
                      <div className="form-group  mb-4">
                        <p className='ps-3 input-label mb-1'>Email <sup>*</sup></p>
                        <input
                          type="email"
                          className="custom-form-control form-control "
                          id="email"
                          name="email"
                          value={email}
                          placeholder="Enter your email"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group passwordcustom  mb-1">
                        <p className='ps-3 input-label mb-1'>password <sup>*</sup></p>

                        <input
                          type={showPassword ? "text" : "password"}
                          className="custom-form-control form-control "
                          id="password"
                          name="password"
                          value={password}
                          placeholder="Enter password"
                          onChange={onChange}
                        />
                        <div className='password-see cursor-pointer' onClick={() => setShowpassword(!showPassword)}>
                          <img src={showPassword ? eye : noeye} alt="" />
                        </div>
                      </div>
                      <div className='d-flex justify-content-end mb-4 forget-password cursor-pointer'>
                        <p className='pe-3 pt-1'>Forget Password?</p>
                      </div>
                      <div className="form-group mb-4">
                        <button type="submit" className="submit-btn">
                          {
                            isLoading ? <>
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Loading...
                            </>
                              : "login"
                          }


                        </button>
                      </div>

                      <div className="form-group ">
                        <button type="button" className="login-with-otp">
                          Log In with OTP
                        </button>
                      </div>

                    </form>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <p className="pt-3 information">
                D2C Consulting Services Private Limited (CIN
                U74900DL2012PTC246820) Principal Place of Business: 2nd Floor,
                Plot 94, Sector 32 Gurugram 122001, Registered office: 2nd
                Floor, 5B/14 Tilak Nagar New Delhi 110088, Indi
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Login
