import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import { useDropzone } from 'react-dropzone';
import dropzone from "../../../Assets/image/icons/dropzone.png"
import { addCollaterals, editCollaterals, getCollateralById } from '../../../Utils/services';
import { useNavigate, useParams } from 'react-router-dom';
import uuid from 'react-uuid';

const EditCollateralForm = ({ tags, collateraltype, language, category, data }) => {

    const [preview, setPreview] = useState([]);
    const [collateraltypeValue, setCollateraltypeValue] = useState("")
    const [selectedcourse, setselectedCourse] = useState([]);
    const [preferSize, setPreferSize] = useState([]);
    const [file, setFiles] = useState([])
    const [loading, setLoading] = useState(false)

    //  form field //
    const [ttileName, setTitleName] = useState(data?.item?.titleName);
    const [textArea, setTextArea] = useState(data?.item?.description);
    const [collatsize, setCollatSize] = useState('');
    const [langtype, setLanguageType] = useState(parseInt(data?.item?.languages[0]?.languageID));
    const [selectedDate, setSelectedDate] = useState();
    const [timeValue, setTimeValue] = useState()
    const [categaryvalue, setCategoryValue] = useState("");
    const [infoId, setInfoId] = useState()
    const [infoIdapi, setInfoIdapi] = useState([])

    // add more data 
    const [addRow, setAddRow] = useState([]);
    const [selectedFileTwo, setSelectedFiletwo] = useState({});
    const [colletralSizeTwo, setColletralSizeTwo] = useState({});
    const [languagtwo, setLanguagetwo] = useState({});
    const [adderror, setAdderror] = useState({});
    const [errors, setErrors] = useState({});
    const params = useParams()


    const navigate = useNavigate();


    const handleAddMore = () => {
        let _errors = {};
        let formIsValid = true;
        if (selectedcourse.length == 0 && !collatsize && !langtype) {
            formIsValid = false;
            _errors["colletralSize"] = "Please enter the primary collateral size ";

        }
        else {
            let newArray = [...addRow];
            newArray = [...newArray, ""];
            setAddRow(newArray);
        }

        setAdderror(_errors)

        return formIsValid;

    }

    const handleDeleteRow = (id) => {
        const _newList =
            addRow &&
            addRow.filter((data, index) => {
                return index !== id;
            });
        setAddRow(_newList);
        delete selectedFileTwo[`choicesData${id + 1}`];
        delete colletralSizeTwo[`choicesData${id + 1}`];


    };


    const handleFileTwo = (e) => {
        const name = e.target.name;
        const value = e.target.files[0];

        setSelectedFiletwo({
            ...selectedFileTwo,
            [name]: value,
        });
    };



    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        accept: {
            "application/pdf": ['.pdf'],
            'image/jpeg': ['.jpeg', '.png', ".jpg"]

        },
        onDrop: (acceptedFiles) => {

            setPreview(acceptedFiles[0])
            setFiles(

                <li key={acceptedFiles[0].path}>
                    {acceptedFiles[0].path}
                </li>
            );

        }

    });

    const handlecheckbox = (e) => {
        var temp = selectedcourse;
        if (e.target.checked === false) {
            temp = temp.filter((a) => {
                return a !== parseInt(e.target.value);
            });
        }
        e.target.checked
            ? setselectedCourse([...selectedcourse, parseInt(e.target.value)])
            : setselectedCourse([...temp]);

    };




    const handlSelecttype = (event) => {
        setCollateraltypeValue(parseInt(event.target.value))
        let colleterSize = [];
        colleterSize = collateraltype.filter(
            (item) => item.collateralTypeID === parseInt(event.target.value)
        );
        setPreferSize(colleterSize[0].collateralDimesion);
    };


    const handleForm = async () => {

        if (handleValidation()) {
            // debugger
            setLoading(true)
            const formData = new FormData();

            let _newFileTwo = [preview];
            for (const x in selectedFileTwo) {
                _newFileTwo.push(selectedFileTwo[x]);
            }
            let _newlanguagtwo = [langtype];
            let _prefersize = [collatsize]
            for (const x in languagtwo) {
                _newlanguagtwo.push(languagtwo[x]);
                _prefersize.push(collatsize)
            }
            formData.append("collateralID", params.id);
            formData.append("deletedCollatInfo", infoIdapi);
            formData.append("titleName", ttileName);
            formData.append("description", textArea);
            formData.append("categoryID", categaryvalue)
            formData.append("tagID", selectedcourse);
            formData.append("collateralTypeID", collateraltypeValue);
            formData.append("collatSizes", _prefersize);
            formData.append("collatLang", _newlanguagtwo);
            formData.append("timeZone", "Asia/Kolkata");
            console.log(_newFileTwo.length !== 0);
            if (file.length !== 0)
                _newFileTwo &&
                    _newFileTwo.map((data) => {
                        formData.append("files", data);
                    });
            if (!selectedDate === "") {
                formData.append("scheduledDateandTime", `${selectedDate} ${timeValue ? timeValue : ""}`)
            }

            const result = await editCollaterals(formData);
            setLoading(false)
            if (result.status) {
                navigate("/master")
            }

        }


    }

    const handleValidation = () => {
        let _errors = {};
        let formIsValid = true;
        if (!ttileName) {
            formIsValid = false;
            _errors["titelName"] = "Please enter the title";
        }
        else if (!categaryvalue) {
            formIsValid = false;
            _errors["categaryvalue"] = "Please select category";
        }
        else if (selectedcourse.length === 0) {
            formIsValid = false;
            _errors["selectedcourse"] = "Please select tags";
        }

        else if (!textArea) {
            formIsValid = false;
            _errors["textArea"] = "Please select description";
        }
        else if (!collateraltypeValue) {
            formIsValid = false;
            _errors["collateraltypeValue"] = "Please select collateral";
        }
        else if (!preview) {
            formIsValid = false;
            _errors["preview"] = "Please select pdf";
        }

        else if (!collatsize) {
            formIsValid = false;
            _errors["collatsize"] = "Please select prefer size";
        }
        else if (!langtype) {
            formIsValid = false;
            _errors["langtype"] = "Please select language";
        }

        setErrors(_errors)
        return formIsValid;

    }

    const handleTimeChange = (e) => {
        setTimeValue(e.target.value)
    }


    const handleLangtwo = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setLanguagetwo({
            ...languagtwo,
            [name]: value,
        });

    };

    const CollateralByIDAPI = async (id) => {
        const result = await getCollateralById({
            "collateralID": id
        })
        const collatData = result?.data?.collatData.map(tag => tag.collatInfoId);
        // console.log(result?.data?.collatData,"check the collatInfoId");
        setInfoIdapi(collatData)

        setInfoId(result?.data)

        const tagsIDArr = result?.data?.collatTags.map(tag => tag.tagsID);
        setselectedCourse(tagsIDArr)
        setCategoryValue(result?.data?.collatCategory?.categoryID)
        setCollateraltypeValue(result?.data?.collateralType?.collateralTypeID)
        setCollatSize(result?.data?.collatData[0]?.collatSizes)
    }
    useEffect(() => {
        let colleterSize = [];
        colleterSize = collateraltype.filter(
            (item) => item.collateralTypeID === parseInt(collateraltypeValue)
        );

        setPreferSize(colleterSize[0]?.collateralDimesion);
    }, [collateraltype])

    useEffect(() => {
        if (params.id) {
            CollateralByIDAPI(params.id)
        }

    }, [params.id])




    console.log(infoIdapi, "check info id")




    return (
        <div>
            <div className='add-collateral-input'>
                <p className='py-2'>Title</p>
                <TextField value={ttileName} id="standard-basic" placeholder='type something...' variant="standard" className='addcollate' onChange={(e) => setTitleName(e.target.value)} />
                {
                    errors.titelName && <p className="text-sm text-left text-danger pt-1">{errors.titelName}</p>
                }
            </div>
            <div className='collateral-category mt-4'>
                <p className='py-2'>Category</p>
                <div className='d-flex'>
                    <ul class="ux-btn-group " >

                        {
                            category?.map((item) => (
                                <li className=''>
                                    <input type="radio" id={`${item?.categoryName}`} className="ux-btn-group-item" value={item.categoryID} name="option" onChange={(e) => setCategoryValue(parseInt(e.target.value))} checked={categaryvalue === item.categoryID} />
                                    <label for={`${item?.categoryName}`} className='me-2 mt-0 ms-0 ' >{item.categoryName}</label>
                                </li>

                            ))
                        }



                    </ul>
                </div>
                {
                    errors.categaryvalue && <p className="text-sm text-left text-danger pt-1">{errors.categaryvalue}</p>
                }

            </div>
            <div className='collateral-category mt-4'>
                <p className='py-2'> Select Tags </p>

                <ul class="ux-btn-group  " style={{ overflowY: 'scroll' }}>

                    {tags.map((course, index) => {
                        return (
                            <>
                                <li key={index} >
                                    <input type="checkbox" value={course.tagsID} id={`${course.tagsName}`} class="ux-btn-group-item" name="option" onClick={handlecheckbox} checked={selectedcourse.find(tag => tag === course.tagsID) ? true : false} />
                                    <label for={`${course.tagsName}`} className="me-2 mt-0 ms-0 mb-2"  >{course.tagsName}</label>
                                </li>
                            </>
                        );
                    })}
                </ul>


                {
                    errors.selectedcourse && <p className="text-sm text-left text-danger pt-1">{errors.selectedcourse}</p>
                }


            </div>
            <div className='collateral-category mt-4'>
                <p className='py-2'> Select Collateral Type </p>
                <div className='d-flex'>

                    <ul class="ux-btn-group" >

                        {
                            collateraltype?.map((item) => (
                                <li>
                                    <input type="radio" id={`${item?.collateralType}`} className="ux-btn-group-item " value={item.collateralTypeID} name="option2" onChange={handlSelecttype} checked={collateraltypeValue === item.collateralTypeID} />
                                    <label for={`${item?.collateralType}`} className='me-2 mt-0 ms-0 mb-0' >{item.collateralType}</label>
                                </li>

                            ))
                        }

                    </ul>

                </div>
                {
                    errors.collateraltypeValue && <p className="text-sm text-left text-danger pt-1">{errors.collateraltypeValue}</p>

                }

            </div>

            <div className="row mt-4">
                <div className="col-lg-4  col-md-12">
                    <div className='collateral-description mt-4'>
                        <p className='py-2'>Upload Collateral </p>

                        <div>
                            <div {...getRootProps()} className="inputDrops mt-2">
                                <input {...getInputProps()} />
                                {isDragActive ? <p>Drop Image Here</p> : <><img src={dropzone} alt="" /></>}
                                {isDragReject && "File type not accepted, sorry!"}

                            </div>
                            <ul className='text-center'>{file}</ul>


                        </div>
                        {
                            errors.preview && <p className="text-sm text-left text-danger pt-1">{errors.preview}</p>
                        }

                    </div>

                </div>
                <div className="col-lg-4 col-md-12">
                    <div className='collateral-category mt-4'>
                        <p className='py-2'> Select Preferred Size </p>
                        <div className='d-flex'>

                            <ul class="ux-btn-group" >

                                {
                                    preferSize?.map((item) => (
                                        <li>
                                            <input type="radio" id={`${item}`} className="ux-btn-group-item " value={item} name="option3" onChange={(e) => setCollatSize(e.target.value)} checked={item === collatsize} />
                                            <label for={`${item}`} className='me-2 mt-0 ms-0 mb-0' >{item}</label>
                                        </li>

                                    ))
                                }

                            </ul>

                        </div>
                        {
                            errors.collatsize && <p className="text-sm text-left text-danger pt-1">{errors.collatsize}</p>
                        }

                    </div>
                </div>
                <div className="col-lg-4 col-md-12">
                    <div className='collateral-category mt-4'>
                        <p className='py-2'> Select Language </p>
                        <div className='d-flex ' >

                            <ul class="ux-btn-group  " >

                                {
                                    language?.map((item, index) => (

                                        <li>
                                            <input type="radio" id={`${item.langName}`} className="ux-btn-group-item " value={item.languageID} name="option4" onChange={(e) => setLanguageType(parseInt(e.target.value))} checked={item.languageID === langtype} />
                                            <label for={`${item.langName}`} className='me-2 mt-0 ms-0 mb-2'  >{item.langName}</label>
                                        </li>

                                    ))
                                }

                            </ul>

                        </div>
                        {
                            errors.langtype && <p className="text-sm text-left text-danger pt-1">{errors.langtype}</p>
                        }

                    </div>
                </div>
            </div>

            <div className='collateral-description mt-4' >
                <p className='py-2'>Description </p>
                <textarea value={textArea} name="" id="" cols="30" rows="8" onChange={(e) => setTextArea(e.target.value)} />
                {
                    errors.textArea && <p className="text-sm text-left text-danger pt-1">{errors.textArea}</p>
                }

            </div>


            <div className='collateral-Sehedule mt-4'>
                <p className='py-2'>Schedule Date & Time </p>
                <div className='d-flex'>
                    <input
                        type="date"
                        min={new Date().toISOString().slice(0, 10)}
                        value={selectedDate}
                        onChange={e => setSelectedDate(e.target.value)}
                        className="me-3"
                    />
                    <input type="time" value={timeValue}
                        onChange={handleTimeChange} />
                </div>
            </div>





            <div className='collateral-language-adapts mt-4'>
                <p className='py-2'>Size or Language Adapts </p>

                {
                    addRow && addRow.map((item, index) => (
                        <>
                            <div className="row my-4">
                                <div className="col-3">
                                    <div
                                        className="upload-btn-wrapper"

                                    >
                                        <p className='py-2'> Select File</p>

                                        <button className="btn-upload">
                                            {selectedFileTwo[`choicesData${index + 1}`]
                                                ? selectedFileTwo[
                                                    `choicesData${index + 1}`
                                                ].name
                                                : "Browse "}
                                        </button>
                                        <input
                                            type="file"
                                            name={`choicesData${index + 1}`}
                                            accept="application/pdf"

                                            multiple
                                            onChange={(e) => handleFileTwo(e)}
                                        />


                                    </div>


                                </div>
                                <div className="col-2">
                                    <p className='py-2'> Select Size </p>
                                    <button className='prefersize'>{collatsize}</button>

                                </div>
                                <div className="col-6">
                                    <div className='collateral-category '>
                                        <p className='py-2'> Select Language </p>
                                        <div className='d-flex flex-wrap' >

                                            <ul class="ux-btn-group flex-wrap " >

                                                {
                                                    language?.map((item, ind) => (

                                                        <li>
                                                            <input type="radio" id={`text${ind}${index}`} className="ux-btn-group-item " value={item.languageID} name={`languagesValue${index + 1}`} onChange={(e) => handleLangtwo(e)} />
                                                            <label for={`text${ind}${index}`} className='me-2 mt-0 ms-0 mb-2'  >{item.langName}</label>
                                                        </li>

                                                    ))
                                                }

                                            </ul>

                                        </div>


                                    </div>
                                </div>
                                {
                                    addRow.length - 1 === index &&
                                    <div className="col-1 ">
                                        <span
                                            className="cross-btn cursor-pointer"
                                            onClick={() => handleDeleteRow(index)}
                                        >
                                            X
                                        </span>
                                    </div>
                                }

                            </div>
                        </>
                    ))
                }
                <div>
                    <button onClick={handleAddMore}> Add+ </button>
                </div>
                {
                    adderror.colletralSize && <p className="text-sm text-left text-danger pt-1">{adderror.colletralSize}</p>
                }

            </div>

            <div className='mt-3 mb-3'>
                <button className='save-btn-modal' onClick={handleForm} disabled={loading}>
                    {loading ?
                        <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </>
                        : " Save"}
                </button>
            </div>

        </div >
    )
}

export default EditCollateralForm