import React, { useContext, useEffect, useState } from 'react'
import delIcon from '../../../Assets/image/icons/delete.png'
import { ModalContext } from '../../../Context';
import DeleteContantCategary from '../../../ModalTemplate/DeleteContantCategary';
import DeleteContent from '../../../ModalTemplate/DeleteContent';
import EditContent from '../../../ModalTemplate/EditContent';
import EditTag from '../../../ModalTemplate/EditTag';
import { getGetArtcleTags } from '../../../Utils/services';

const TagEdit = () => {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [category, setCategory] = useState([]);


    const data = [{
        name: "Health"
    },
    {
        name: "Health"
    }, {
        name: "Health"
    }, {
        name: "Health"
    }
    ]

    const GetCategoryApi = async () => {
        const result = await getGetArtcleTags()
        let data = result.data.map(function (item) {
            return { label: item.categoryName, value: item.categoryID };
        });

        setCategory(data)

    }

    useEffect(() => {
        GetCategoryApi()
    }, [])

    const handleEdit = (items) => {
        const EditData = <EditContent items={items} GetCategoryApi={GetCategoryApi} />

        handleModalData(EditData, "cs");


    }

    const handleDel = (items) => {
        const EditData = <DeleteContent items={items} GetCategoryApi={GetCategoryApi} />
        handleModalData(EditData, "cs");


    }



    return (
        <>
            <div className='Tags_Edit'>


                <div className='d-flex  tags-edits p-2'>
                    <div>
                        <h4> Tags</h4>

                    </div>
                    <div>
                        <h4> Action</h4>

                    </div>
                </div>

                <div className='mt-2'>
                    {category?.map((items) => (
                        <div className='p-3 d-flex justify-content-between'>
                            <div className='content_tag'>
                                <p> {items.label}</p>

                            </div>
                            <div className='d-flex align-items-center'>
                                <span className='me-3 content-edit cursor-pointer ' onClick={() => handleEdit(items)}> Edit</span>
                                <img src={delIcon} alt="" className='cursor-pointer' onClick={() => handleDel(items)} />

                            </div>

                        </div>

                    ))}
                </div>


            </div>

        </>
    )
}

export default TagEdit