import React from 'react'

const ViewCollateral = ({ item }) => {

    return (
        <div>
            <h3 className='mb-3 p-1 text-center'>{item?.titleName}</h3>

            {
                !item?.collatData[0]?.thumbImage ?
                    <>
                        <p className='text-center p-2'>No image</p>
                        <div className='text-center'>
                            <span class="spinner-border spinner-border-sm  " role="status" aria-hidden="true"></span>
                            Loading...
                        </div>
                    </>
                    : <img src={item?.collatData[0]?.thumbImage} alt="" className="w-100 " />


            }

        </div>
    )
}

export default ViewCollateral