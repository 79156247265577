export const LOGIN = 'auth/login'
export const PEOPLELIST = 'checker/listofpeople'
export const CREATEUSER = '/checker/createuser'
export const MAKERLIST = 'checker/getuserbyid'
export const REFRESH_TOKEN = 'auth/refresh'
export const LISTOFCATEGORYTYPE = 'common/listofcategorytype'
export const CREATECATEGORYTYPE = 'common/createcategorytype'
export const PASSWORDUPDATE = 'common/password/update'
export const LISTOFTAGS = 'common/listoftags'
export const CREATEOFTAGS = 'common/createtags'
export const LISTOFLANGUAGE = 'common/listoflanguages'
export const CREATELANGUAGE = 'common/addlanguage'
export const CREATECOLLATERALTYPE = 'common/addcollateraltype'
export const LISTCOLLATERAL = 'common/listofcollattype'
export const LISTCOLLATERALS = 'common/listofcollateral'
export const ADDCOLLATERALS = 'common/addcollateral'
export const DECLINEREQ = 'checker/declinecollateral'
export const DEACTIVEMODAL = 'common/deactivatecollateral'
export const LISTOFTAGSADDCOLLETRAL = 'common/getlistoftags'
export const DELETECOLLETARAL = 'checker/deletecollateral'
export const EDITCOLLETERL = 'common/getcollateralbyid'
export const APPROVECOLLETERAL = 'checker/approvcollateral'
export const APPROVEDCOLLETERAL = 'common/listofapprovedcollat'
export const LISTNONAPPROVCOLLETERAL = 'common/getlistofcollatfiltered'
export const LISTDECLINEDCOLLETERAL = 'common/getlistofdeclined'
export const TESTCOLLETERAL = 'common/testcollaterl'
export const EDITTAGS = '/common/edittagname'
export const EDITLANG = 'common/editlangname'
export const EDITCATEGARY = 'common/editcollatCategory'
export const LISTOFDEACTIVATED = 'common/getlistofdeactivated'
export const REACTIVATE = 'common/reactivatecollateral'
export const PROFILE = 'common/getprofile'
export const EDITCOLLATEALTYPE = 'common/editcollattype'
export const ListOfAllPermissionModules = 'common/getlistofpermission'
export const LISTOFDOWNLOADCOLLATERAL = 'analytics/getmostdownloaded'
export const HOMEACITVECOLLETERAL = 'analytics/gettotalcollateral'
export const HOMECHART = 'analytics/getfiltertotalnumberofdownload'
export const UPDATEPROFILE = 'common/updateprofile'
export const RESETPASSWORD = 'auth/password/resetlink'
export const SETPASSWORD = 'auth/password/reset'
export const DELETECOLLATERALTYPE = 'common/deletecollatype'
export const DELETETAGS = 'common/deletetag'
export const DELETELANG = 'common/deletelanguage'
export const DELETEUSER = 'checker/deleteuser'
export const TAGOVERVIEW = 'analytics/gettagoverview'
export const TESTVIDEO = 'video/testvideo'

// renewverse//

export const GETARTICLE = 'common/getarticles'
export const ADDCOMPANY = 'common/addcompany'
export const ADDBROCHURECATEGORY = 'common/addbrochurescategory'
export const GETCOMPANY = 'common/listofcompany'
export const GETBRACHURECATEGORY = 'common/getrvcategory'
export const RVADDBROUCHER = 'common/addrvbrouchers'
export const GETBROCHURE = 'common/getrvbrouchers'
export const RVARTICLETAG = 'common/getrvarticlecategory'
export const DELARTICLE = 'common/deletearticles'
export const ADDARTICLE = 'common/addarticles'
export const ADDARTICLECATEGORY = 'common/addarticlecatgeory'
export const ADD_CONTENTHUB_VIDEO = 'video/addrvvideocontent'
export const VIDEO_CONTENT = 'video/getrvvideocontent'
export const GET_VIDEO_SIZE = 'video/getvideosizes'
export const CHARTS_ANALYTICS = 'analytics/getcollateraltypeanalytics'
export const USER_ANALYTICS = 'analytics/getusersanalytics'
export const USER_EXCEL = 'analytics/getexportuserdata'
export const DELETE_BROCHURE = 'common/deletervbrouchers'
export const DELETE_CONTENT_CATGROY = 'common/deletervcategory'
export const EDIT_RV_CATEGORY = 'common/editrvcategory'
export const EDIT_RV_COMPANY = 'common/editcompany'
export const DELETE_COMPANY = 'common/deletecompany'
export const EDIT_CONTENT_API = 'common/editarticlestags'
export const DELETE_ARTICLE_TAG = 'common/deletearticlestags'
export const GET_ARTCLE_BY_ID = 'common/getarticlebyid'
export const UPDATE_ARTCLE = 'common/editarticle'
export const GET_BROCHURE_BY_ID = 'common/getbrouchersbyid'
export const UPDATE_BROCHURE = 'common/editbrouchers'
export const SET_PRIORITY = 'common/updatepriority'
export const EDIT_COLLATERAL = 'common/editcollateral'
export const COLLATERALBYID = 'common/getcollateralbyid'
