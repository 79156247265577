import React, { useEffect } from 'react'
import {

    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    BarChart,
    Bar,

} from "recharts";

const BarCharts = ({ color, colorOne, colorTwo, chartData }) => {
    const data = [
        {
            "name": "Standee",
            "uv": 4000,

        },
        {
            "name": "Banner",
            "uv": 3000,

        },
        {
            "name": "Poster",
            "uv": 2000,

        },
        {
            "name": "Pamphlet",
            "uv": 2780,

        },
        {
            "name": "Wishes",
            "uv": 1890,

        },
        {
            "name": "Page F",
            "uv": 2390,

        },
        {
            "name": "Page G",
            "uv": 3490,

        },
        {
            "name": "Page A",
            "uv": 300,

        },
        {
            "name": "Page B",
            "uv": 3000,

        },
        {
            "name": "Page C",
            "uv": 2000,

        },
    ]







    const CustomLablel = (props) => {
        const { x, y, fill, value } = props;

        return (
            <>

                <text
                    x={x}
                    y={y}
                    dy={-8}
                    dx={12}

                    fontSize='12'
                    fontFamily='sans-serif'


                    fill={fill}
                    style={{ backgroundColor: '#22AA22', }}
                    scaleToFit={true}
                    strokeWidth={3}

                    textAnchor="middle" >


                    {value}

                </text >

            </>

        )
    }


    const renderLegend = (props) => {
        const { payload } = props;

        return (
            <ul>
                {
                    payload.map((entry, index) => (
                        <li key={`item-${index}`} className="text-center"></li>
                    ))
                }
            </ul>
        );
    }



    return (
        <div className='barchart mt-5' style={{ width: '100%', maxWidth: '1380px', height: '300px', margin: '0 auto' }}>
            <ResponsiveContainer>
                <BarChart
                    data={chartData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    barSize={25}
                    barCategoryGap="1%"
                    barGap="2"
                    className="chartdetails"
                >
                    <XAxis dataKey="tagName" tickLine={false} strokeOpacity={0} />
                    <YAxis />
                    <Tooltip cursor={{ fill: 'transparent' }} />
                    <Legend content={renderLegend} />
                    <defs>
                        <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="100%"
                            spreadMethod="reflect"
                        >
                            <stop offset="0" stopColor={`${colorTwo}`} />
                            <stop offset="1" stopColor={`${colorOne}`} />
                        </linearGradient>
                    </defs>
                    <Bar
                        dataKey="count"
                        fill="url(#colorUv)"
                        radius={[6, 6, 6, 6]}
                        isA6imationActive={false}
                        fontFamily="sans-serif"
                        label={<CustomLablel />}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>





    )
}

export default BarCharts