import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ModalContext } from '../../../Context';
import DeleteContantCategary from '../../../ModalTemplate/DeleteContantCategary';
import EditTag from '../../../ModalTemplate/EditTag';
import delIcon from '../../../Assets/image/icons/delete.png'
import { getGetBroucherCategory, getGetCompany } from '../../../Utils/services';
import EditContentTag from '../../../ModalTemplate/EditContentTag';
import DeleteInsurarCatgory from '../../../ModalTemplate/DeleteInsurarCatgory';
import EditCompany from '../../../ModalTemplate/EditCompany';
import DeleteCompany from '../../../ModalTemplate/DeleteCompany';


const EditBrochure = () => {
    const [activeArticles, setActiveArticle] = useState(true)
    const [activeVideo, setActiveVideo] = useState(false)
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [category, setCategory] = useState([]);
    const [companyData, setCompanyData] = useState([]);


    const navigate = useNavigate()

    const handleArticle = () => {
        setActiveArticle(true)
        setActiveVideo(false)
    }
    const handleVideo = () => {
        setActiveArticle(false)
        setActiveVideo(true)
    }








    const GetCategoryApi = async () => {
        const result = await getGetBroucherCategory()
        let data = result.data.map(function (item) {
            return { label: item.categoryName, value: item.categoryID };
        });
        setCategory(data)

    }

    const GetCompanyApi = async () => {
        const result = await getGetCompany()
        console.log(result, "check data")
        setCompanyData(result.data)
    }




    const handleEdit = (items) => {
        console.log(items, "log")
        const EditData = <EditContentTag items={items} GetCategoryApi={GetCategoryApi} />

        handleModalData(EditData, "cs");


    }

    const handleDel = (items) => {
        const EditData = <DeleteInsurarCatgory items={items} GetCategoryApi={GetCategoryApi} />
        handleModalData(EditData, "cs");
    }

    const handleEditComapany = (items) => {
        const EditData = <EditCompany items={items} GetCompanyApi={GetCompanyApi} />

        handleModalData(EditData, "cs");
    }

    const handleCompanydel = (items) => {
        const EditData = <DeleteCompany items={items} GetCompanyApi={GetCompanyApi} />
        handleModalData(EditData, "cs");
    }



    useEffect(() => {
        GetCategoryApi()
        GetCompanyApi()
    }, [])


    return (
        <>
            <div className='switch-content d-flex justify-content-between align-items-center'>
                <div className='d-flex'>
                    <div className={`${activeArticles && 'active-content'} me-4`} onClick={handleArticle} >
                        <h3 className='px-4 py-3'>Company</h3>
                    </div>
                    <div className={`${activeVideo && 'active-content'}`} onClick={handleVideo} >
                        <h3 className='px-4 py-3'>Category</h3>
                    </div>
                </div>





            </div >

            {
                activeArticles &&
                <div className='Tags_Edit mt-4'>


                    <div className='d-flex  tags-edits px-4 py-3'>
                        <div>
                            <h4> Company</h4>

                        </div>
                        <div>
                            <h4> Action</h4>

                        </div>
                    </div>

                    <div className='mt-4 p-2'>
                        {companyData?.map((items) => (
                            <div className='p-3 d-flex justify-content-between'>
                                <div className='content_tag'>
                                    <p> {items.companyName}</p>

                                </div>
                                <div className='d-flex align-items-center'>
                                    <span className='me-3 content-edit cursor-pointer ' onClick={() => handleEditComapany(items)}> Edit</span>
                                    <img src={delIcon} alt="" className='cursor-pointer' onClick={() => handleCompanydel(items)} />

                                </div>

                            </div>

                        ))}
                    </div>


                </div>

            }


            {
                activeVideo &&

                <div className='Tags_Edit mt-4'>


                    <div className='d-flex  tags-edits px-4 py-3'>
                        <div>
                            <h4> Category</h4>

                        </div>
                        <div>
                            <h4> Action</h4>

                        </div>
                    </div>

                    <div className='mt-4 p-2'>
                        {category?.map((items) => (
                            <div className='p-3 d-flex justify-content-between'>
                                <div className='content_tag'>
                                    <p> {items.label}</p>

                                </div>
                                <div className='d-flex align-items-center'>
                                    <span className='me-3 content-edit cursor-pointer ' onClick={() => handleEdit(items)}> Edit</span>
                                    <img src={delIcon} alt="" className='cursor-pointer' onClick={() => handleDel(items)} />

                                </div>

                            </div>

                        ))}
                    </div>


                </div>

            }

        </>
    )
}

export default EditBrochure