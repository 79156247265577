import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Avatar from '../../../Components/commoncomponent/Avatar';
import SwitchProfiledetails from '../../../Components/commoncomponent/SwitchProfiledetails';
import { editProfile, getProfile, reset } from '../../../Redux/Profile/profileSlice';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

const UpdateProfile = ({ merchant, collaterals, handleMarchant, handleCollateral }) => {
    const { profile, isError, message } = useSelector(
        (state) => state.profile
    )

    const [firstName, setFirstName] = useState(profile?.firstName)
    const [lastName, setLastName] = useState(profile?.lastName)
    const [desigantion, setDesigantion] = useState(profile?.userDesignation)
    const [phone, setPhone] = useState(profile?.phoneNum)
    const [email, setEmail] = useState(profile?.userEmail)
    const { user } = useSelector((state) => state.auth)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleChangePasword = () => {
        navigate('/profile/changepassword')
    }

    const handleGoProfile = (e) => {
        if (firstName && lastName && desigantion && phone) {
            let profileData = {
                "firstName": firstName,
                "lastName": lastName,
                "designation": desigantion,
                "contactNumber": phone,
            }

            dispatch(editProfile(profileData))
            Swal.fire({
                position: 'center-center',
                icon: 'success',
                title: 'successfully edited',
                showConfirmButton: false,
                timer: 1000
            })
            navigate('/profile')
        }
    }
    useEffect(() => {
        if (isError) {
            console.log(message)
        }

        if (!user) {
            navigate('/login')
        }
        dispatch(getProfile())
        return () => {
            dispatch(reset())
        }
    }, [user, navigate, message, dispatch])

    return (
        <div className='profile'>

            {/* -----------------tab button -------------------------------- */}

            <SwitchProfiledetails Information="productactive" handleInformation={handleChangePasword} />

            {/* --------------- profile page ---------------------------------- */}

            <Avatar />

            {/* ---------------- avatar details---------------------------------- */}

            <div className='profile_form_details mt-4 '>
                <div className=''>
                    <div className="row ">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                            <div className=''  >
                                <p>First Name</p>
                                <input type="text" placeholder="Samriti" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                            <div>
                                <p>Last Name</p>
                                <input type="text" placeholder='Sharma' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                            <div>
                                <p>Designation</p>
                                <input type="text" placeholder='Manager, Digital Marketing' value={desigantion} onChange={(e) => setDesigantion(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                            <div className=''  >
                                <p>Contact Number</p>
                                <input type="text" placeholder='+91-8506073332' value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                            <div>
                                <p>Email</p>
                                <input type="text" placeholder='samritisharma@gmail.com' value={email} disabled={true} />

                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12  profile_editbtn mt-4' onClick={handleGoProfile}>
                    <button className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4">Update</button>
                </div>

            </div>
        </div >
    )
}
export default UpdateProfile